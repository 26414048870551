import { LDAPConfiguratorState } from "types/enums/ldapConfiguratorEnum";

const ldapConfiguratorInitialState: any = {
  ldapConfigurator: {
    ldapConfiguratorState: LDAPConfiguratorState.tableView,
    ldapServiceList: [],
    ldapServiceLoader: false,
    ldapUsersListLoader: false,
    ldapUsersList: [],
    ldapTypeOfIdentityList: [],
    ldapTestSuccess: {},
    ldapServiceCreated: false,
    getLdapServiceConfiguratorDetailsById: {},
    ldapCountList: [],
    activateDeactivateStatus: false,
  },
};

export default ldapConfiguratorInitialState;

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { EMPTY } from 'types/enums';
import {
    ConnectionsState,
    MappingState,
    MonitoringState,
    OperationState,
    TabName,
} from 'types/enums/businessIntegratorEnum';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import TabsComponent from 'components/common/TabsComponent';
import Connections from './Connection';
import { setConnectionsState } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import AddConnections from './Connection/AddConnections';
import CountAnalytics from 'components/common/CountAnalytics';
import { ReactComponent as HealthyIcon } from 'assets/icons/healthy.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/connectionWarning.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as OperationScheduled } from 'assets/icons/operationError.svg';
import { ReactComponent as OperationExcecuted } from 'assets/icons/operationHealthy.svg';
import { ReactComponent as OperationError } from 'assets/icons/operationWarning.svg';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Monitoring from './Monitoring';
import Mapping from './Mapping';
import AddMapping from './Mapping/AddMapping';
import { setMappingState } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import { hasTabPermission } from 'utils/commonFunction';
import Operations from './Operation';
import AddOperation from './Operation/AddOperation';
import ViewMonitoring from './Monitoring/ViewMonitoring';
import { setMonitoringState } from 'redux/actions/BusinessIntegratorActions/monitoringActions';
import { setOperationState } from 'redux/actions/BusinessIntegratorActions/operationActions';

const BusinessIntegrator: React.FC<{ activate: any }> = ({ activate }): any => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const { currentTab, paramState, paramAdapterId } = useParams();

    const [activeTabKey, setActiveTabKey] = useState<any>(EMPTY.string);

    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    const connectionsState = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsState
    );
    const monitoringState = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringState
    );
    const mappingState = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingState
    );
    const operationState = useSelector(
        (state: any) => state.businessIntegrator.operation.operationState
    );
    const connectionsTableList = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsList
    );
    const monitoringTableList = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringListData
    );
    const mappingTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingTableList
    );

    useEffect(() => {
        if (currentTab) {
            setActiveTabKey(currentTab);
        }
        if (
            (
                paramState === ConnectionsState.addConnections ||
                paramState === ConnectionsState.viewConnections || 
                paramState === ConnectionsState.editConnection || 
                paramState === ConnectionsState.copyConnections
            ) &&
            currentTab === TabName.connections
        ) {
            dispatch(setConnectionsState(paramState));
        } else if (
            (
                paramState === MappingState.addConnections ||
                paramState === MappingState.viewConnections || 
                paramState === MappingState.editConnection || 
                paramState === MappingState.copyConnections
            ) &&
            currentTab === TabName.mapping
        ) {
            dispatch(setMappingState(paramState));
        } else if (
            (
                paramState === OperationState.addOperation ||
                paramState === OperationState.viewOperation || 
                paramState === OperationState.editOperation || 
                paramState === OperationState.copyOperation
            ) &&
            currentTab === TabName.operations
        ) {
            dispatch(setOperationState(paramState));
        } else if (
            paramState === MonitoringState.viewMonitoring &&
            currentTab === TabName.monitoring
        ) {
            dispatch(setMonitoringState(paramState));
        }
    }, [currentTab, paramState]);

    useEffect(() => {
        if (activeTabKey) {
            const baseRoute = `/connect/business-integrator/${activeTabKey}`;
            let route: any = '';

            if (activeTabKey === 'connections') {
                const connectionsRouteMap: any = {
                    [ConnectionsState.addConnections]: `/add${
                        paramAdapterId ? '/' : paramAdapterId ?? ''
                    }`,
                    [ConnectionsState.viewConnections]: `/view/${paramAdapterId}`,
                    [ConnectionsState.editConnection]: `/edit/${paramAdapterId}`,
                    [ConnectionsState.copyConnections]: `/copy/${paramAdapterId}`,
                };

                route = connectionsRouteMap[connectionsState] || '';
            } else if (activeTabKey === 'mapping') {
                const mappingRouteMap: any = {
                    [MappingState.addConnections]: `/add${
                        paramAdapterId ? '/' : paramAdapterId ?? ''
                    }`,
                    [MappingState.viewConnections]: `/view/${paramAdapterId}`,
                    [MappingState.editConnection]: `/edit/${paramAdapterId}`,
                    [MappingState.copyConnections]: `/copy/${paramAdapterId}`,
                };

                route = mappingRouteMap[mappingState] || '';
            } else if (activeTabKey === 'operations') {
                const operationRouteMap: any = {
                    [OperationState.addOperation]: `/add${
                        paramAdapterId ? '/' : paramAdapterId ?? ''
                    }`,
                    [OperationState.viewOperation]: `/view/${paramAdapterId}`,
                    [OperationState.editOperation]: `/edit/${paramAdapterId}`,
                    [OperationState.copyOperation]: `/copy/${paramAdapterId}`,
                };

                route = operationRouteMap[operationState] || '';
            } else if (activeTabKey === 'monitoring') {
                const monitoringRouteMap: any = {
                    [MonitoringState.viewMonitoring]: `/view/${paramAdapterId}`,
                };

                route = monitoringRouteMap[monitoringState] || '';
            }

            navigate(`${baseRoute}${route}`, { replace: true });
        }
    }, [activeTabKey, connectionsState, mappingState, monitoringState, operationState, paramAdapterId]);

    const getHeading = (): any => {
        const headings: any = {
            [TabName.operations]: t('BusinessIntegrator.subHeading.operations'),
            [TabName.mapping]: t('BusinessIntegrator.subHeading.mapping'),
            [TabName.monitoring]: t('BusinessIntegrator.subHeading.monitoring'),
            [TabName.connections]: t(
                'BusinessIntegrator.subHeading.connections'
            ),
        };

        return headings[activeTabKey] || headings[TabName.connections];
    };

    const onTabClick = (): any => {
        dispatch(setConnectionsState(ConnectionsState.tableView));
        dispatch(setMappingState(ConnectionsState.tableView));
    };

    const tabItems = [
        {
            id: 1,
            permissionKey: 'Connections',
            key: 'connections',
            label: t('BusinessIntegrator.tabs.connections'),
            children: [
                ConnectionsState.addConnections,
                ConnectionsState.viewConnections,
                ConnectionsState.copyConnections,
                ConnectionsState.editConnection,
            ].includes(connectionsState) ? (
                <AddConnections />
            ) : (
                <Connections />
            ),
        },
        {
            id: 2,
            permissionKey: 'Connection Mapping',
            key: 'mapping',
            label: t('BusinessIntegrator.tabs.mapping'),
            children: [
                MappingState.addConnections,
                MappingState.viewConnections,
                MappingState.copyConnections,
                MappingState.editConnection,
            ]?.includes(mappingState) ? (
                <AddMapping />
            ) : (
                <Mapping />
            ),
        },
        {
            id: 3,
            permissionKey: 'Operations',
            key: 'operations',
            label: t('BusinessIntegrator.tabs.operations'),
            children: [
                OperationState.addOperation,
                OperationState.viewOperation,
                OperationState.copyOperation,
                OperationState.editOperation,
            ]?.includes(operationState) ? (
                <AddOperation />
            ) : (
                <Operations />
            ),
        },
        {
            id: 4,
            permissionKey: 'Monitoring',
            key: 'monitoring',
            label: t('BusinessIntegrator.tabs.monitoring'),
            children: monitoringState === MonitoringState.tableView ? <Monitoring/> : <ViewMonitoring setActiveTabKey = {setActiveTabKey} />,
        },
    ];

    const getCountAnalyticsDetails = (): any => {
        switch (activeTabKey) {
            case 'mapping':
                return [
                    {
                        title: t('BusinessIntegrator.countAnalytics.active'),
                        count: mappingTableList?.activeMappingCount || '--',
                        icon: <HealthyIcon />,
                    },
                    {
                        title: t('BusinessIntegrator.countAnalytics.inactive'),
                        count: mappingTableList?.inActiveMappingCount || '--',
                        icon: <ErrorIcon />,
                    },
                ];
            case 'monitoring':
                return [
                    {
                        title: t('BusinessIntegrator.countAnalytics.healthy'),
                        count: monitoringTableList?.healthyConnections || '--',
                        icon: <HealthyIcon />,
                    },
                    {
                        title: t('BusinessIntegrator.countAnalytics.warning'),
                        count: monitoringTableList?.warningConnections || '--',
                        icon: <WarningIcon />,
                    },
                    {
                        title: t('BusinessIntegrator.countAnalytics.error'),
                        count: monitoringTableList?.errorConnections || '--',
                        icon: <ErrorIcon />,
                    },
                ];
            case 'operations':
                return [
                    {
                        title: "Scheduled",
                        count: monitoringTableList?.healthyConnections || '--',
                        icon: <OperationScheduled />,
                    },
                    {
                        title: "Excecuted",
                        count: monitoringTableList?.warningConnections || '--',
                        icon: <OperationExcecuted />,
                    },
                    {
                        title: "Error",
                        count: monitoringTableList?.errorConnections || '--',
                        icon: <OperationError />,
                    },
                ];
            default:
                return [
                    {
                        title: t('BusinessIntegrator.countAnalytics.healthy'),
                        count: connectionsTableList?.healthyConnections || '--',
                        icon: <HealthyIcon />,
                    },
                    {
                        title: t('BusinessIntegrator.countAnalytics.warning'),
                        count: connectionsTableList?.warningConnections || '--',
                        icon: <WarningIcon />,
                    },
                    {
                        title: t('BusinessIntegrator.countAnalytics.error'),
                        count: connectionsTableList?.errorConnections || '--',
                        icon: <ErrorIcon />,
                    },
                ];
        }
    };

    return (
        <>
            <div className="businessIntegrator">
                <Row className="businessIntegrator__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t('BusinessIntegrator.heading')}
                            subHeading={getHeading()}
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails()}
                        />
                    </Col>
                </Row>
                <Row className="businessIntegrator__tabswrapper fw-500 fs-16">
                    <Col span={24}>
                        <TabsComponent
                            tabItem={
                                loggedInUserDetails?.admin
                                    ? tabItems
                                    : hasTabPermission(tabItems, allowedTabList)
                            }
                            setTabKey={activate}
                            setActiveTabKey={setActiveTabKey}
                            activeTabKey={activeTabKey}
                            onTabClick={() => onTabClick()}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default BusinessIntegrator;

export const DISPLAY_TOTAL_TAGS = 'DISPLAY_TOTAL_TAGS';
export const REMOVE_TOTAL_TAGS_COUNT = 'REMOVE_TOTAL_TAGS_COUNT';
export const SET_EQUIPMENT_STATE = 'SET_EQUIPMENT_STATE';

export const SET_NODE_DATA = 'SET_NODE_DATA';
export const SET_NODE_DATA_SUCCESS = 'SET_NODE_DATA_SUCCESS';
export const SET_NODE_DATA_FAILURE = 'SET_NODE_DATA_FAILURE';

export const GET_NODE_TYPE_DETAILS = 'GET_NODE_TYPE_DETAILS';
export const GET_NODE_TYPE_DETAILS_SUCCESS = 'GET_NODE_TYPE_DETAILS_SUCCESS';
export const GET_NODE_TYPE_DETAILS_FAILURE = 'GET_NODE_TYPE_DETAILS_FAILURE';

export const PUT_NODE_TYPE_UPDATE = 'PUT_NODE_TYPE_UPDATE';
export const PUT_NODE_TYPE_UPDATE_SUCCESS = 'PUT_NODE_TYPE_UPDATE_SUCCESS';
export const PUT_NODE_TYPE_UPDATE_FAILURE = 'PUT_NODE_TYPE_UPDATE_FAILURE';

export const GET_TOTAL_MODEL_LIST = 'GET_TOTAL_MODEL_LIST';
export const GET_TOTAL_MODEL_LIST_SUCCESS = 'GET_TOTAL_MODEL_LIST_SUCCESS';
export const GET_TOTAL_MODEL_LIST_FAILURE = 'GET_TOTAL_MODEL_LIST_FAILURE';

export const SET_MODEL_DETAILS = 'SET_MODEL_DETAILS';
export const SET_MODEL_DETAILS_SUCCESS = 'SET_MODEL_DETAILS_SUCCESS';
export const SET_MODEL_DETAILS_FAILURE = 'SET_MODEL_DETAILS_FAILURE';

export const GET_MODEL_DETAILS_BY_ID = 'GET_MODEL_DETAILS_BY_ID';
export const GET_MODEL_DETAILS_BY_ID_SUCCESS =
    'GET_MODEL_DETAILS_BY_ID_SUCCESS';
export const GET_MODEL_DETAILS_BY_ID_FAILURE =
    'GET_MODEL_DETAILS_BY_ID_FAILURE';

export const DELETE_MODEL = 'DELETE_MODEL';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_FAILURE = 'DELETE_MODEL_FAILURE';

export const EDIT_MODEL_DETAILS = 'EDIT_MODEL_DETAILS';
export const EDIT_MODEL_DETAILS_SUCCESS = 'EDIT_MODEL_DETAILS_SUCCESS';
export const EDIT_MODEL_DETAILS_FAILURE = 'EDIT_MODEL_DETAILS_FAILURE';

export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';

export const OPEN_SUB_MODEL_TABS = 'OPEN_SUB_MODEL_TABS';

export const CLOSE_SUB_MODEL_TABS = 'CLOSE_SUB_MODEL_TABS';

export const CLEAR_MODEL_DETAILS = 'CLEAR_MODEL_DETAILS';

export const GET_TAG_LIST_BY_DEVICE = 'GET_TAG_LIST_BY_DEVICE';
export const GET_TAG_LIST_BY_DEVICE_SUCCESS = 'GET_TAG_LIST_BY_DEVICE_SUCCESS';
export const GET_TAG_LIST_BY_DEVICE_FAILURE = 'GET_TAG_LIST_BY_DEVICE_FAILURE';

export const MAP_TAG_NODES = 'MAP_TAG_NODES';
export const MAP_TAG_NODES_SUCCESS = 'MAP_TAG_NODES_SUCCESS';
export const MAP_TAG_NODES_FAILURE = 'MAP_TAG_NODES_FAILURE';

export const GET_DATATYPE_LIST = 'GET_DATATYPE_LIST';
export const GET_DATATYPE_LIST_SUCCESS = 'GET_DATATYPE_LIST_SUCCESS';
export const GET_DATATYPE_LIST_FAILURE = 'GET_DATATYPE_LIST_FAILURE';

export const GET_UNITS_LIST = 'GET_UNITS_LIST';
export const GET_UNITS_LIST_SUCCESS = 'GET_UNITS_LIST_SUCCESS';
export const GET_UNITS_LIST_FAILURE = 'GET_UNITS_LIST_FAILURE';

export const UPDATE_TAG_FACTOR = 'UPDATE_TAG_FACTOR';
export const UPDATE_TAG_FACTOR_SUCCESS = 'UPDATE_TAG_FACTOR_SUCCESS';
export const UPDATE_TAG_FACTOR_FAILURE = 'UPDATE_TAG_FACTOR_FAILURE';
export const SET_EQUIPMENT_DATA = 'SET_EQUIPMENT_DATA';
export const SET_EQUIPMENT_DATA_SUCCESS = 'SET_EQUIPMENT_DATA_SUCCESS';
export const SET_EQUIPMENT_DATA_FAILURE = 'SET_EQUIPMENT_DATA_FAILURE';

export const GET_EQUIPMENT_DETAILS = 'GET_EQUIPMENT_DETAILS';
export const GET_EQUIPMENT_DETAILS_SUCCESS = 'GET_EQUIPMENT_DETAILS_SUCCESS';
export const GET_EQUIPMENT_DETAILS_FAILURE = 'GET_EQUIPMENT_DETAILS_FAILURE';

export const GET_NODE_TYPE_BY_ID = 'GET_NODE_TYPE_BY_ID';
export const GET_NODE_TYPE_BY_ID_SUCCESS = 'GET_NODE_TYPE_BY_ID_SUCCESS';
export const GET_NODE_TYPE_BY_ID_FAILURE = 'GET_NODE_TYPE_BY_ID_FAILURE';

export const PUT_EQUIPMENT_CLASS_UPDATE = 'PUT_EQUIPMENT_CLASS_UPDATE';
export const PUT_EQUIPMENT_CLASS_UPDATE_SUCCESS =
    'PUT_EQUIPMENT_CLASS_UPDATE_SUCCESS';
export const PUT_EQUIPMENT_CLASS_UPDATE_FAILURE =
    'PUT_EQUIPMENT_CLASS_UPDATE_FAILURE';

export const CLONE_EQUIPMENT_CLASS_REQUEST = 'CLONE_EQUIPMENT_CLASS_REQUEST';
export const CLONE_EQUIPMENT_CLASS_SUCCESS = 'CLONE_EQUIPMENT_CLASS_SUCCESS';
export const CLONE_EQUIPMENT_CLASS_FAILURE = 'CLONE_EQUIPMENT_CLASS_FAILURE';

export const CLONE_NODE_TYPE_REQUEST = 'CLONE_NODE_TYPE_REQUEST';
export const CLONE_NODE_TYPE_SUCCESS = 'CLONE_NODE_TYPE_SUCCESS';
export const CLONE_NODE_TYPE_FAILURE = 'CLONE_NODE_TYPE_FAILURE';

export const DELETE_NODE_TYPE = 'DELETE_NODE_TYPE';
export const DELETE_NODE_TYPE_SUCCESS = 'DELETE_NODE_TYPE_SUCCESS';
export const DELETE_NODE_TYPE_FAILURE = 'DELETE_NODE_TYPE_FAILURE';

export const GET_MAPPED_TAGS_LIST = 'GET_MAPPED_TAGS_LIST';
export const GET_MAPPED_TAGS_LIST_SUCCESS = 'GET_MAPPED_TAGS_LIST_SUCESS';
export const GET_MAPPED_TAGS_LIST_FAILURE = 'GET_MAPPED_TAGS_LIST_FAILURE';

export const GET_TREE_BY_MODEL_ID = 'GET_TREE_BY_MODEL_ID';
export const GET_TREE_BY_MODEL_ID_SUCCESS = 'GET_TREE_BY_MODEL_ID_SUCCESS';
export const GET_TREE_BY_MODEL_ID_FAILURE = 'GET_TREE_BY_MODEL_ID_FAILURE';

export const OPEN_ADD_NODE_DRAWER = 'OPEN_ADD_NODE_DRAWER';
export const CLOSE_ADD_NODE_DRAWER = 'CLOSE_ADD_NODE_DRAWER';

export const OPEN_DELETE_NODE_MODAL = 'OPEN_DELETE_NODE_MODAL';
export const CLOSE_DELETE_NODEL_MODAL = 'CLOSE_DELETE_NODEL_MODAL';

export const GET_NODE_DETAILS_BY_ID = 'GET_NODE_DETAILS_BY_ID';
export const GET_NODE_DETAILS_BY_ID_SUCCESS = 'GET_NODE_DETAILS_BY_ID_SUCCESS';
export const GET_NODE_DETAILS_BY_ID_FAILURE = 'GET_NODE_DETAILS_BY_ID_FAILURE';

export const DELETE_NODE = 'DELETE_NODE';
export const DELETE_NODE_FAILURE = 'DELETE_NODE_FAILURE';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';

export const SET_NODE_INFO = 'SET_NODE_INFO';

export const ADD_NODE = 'ADD_NODE';
export const ADD_NODE_SUCCESS = 'ADD_NODE_SUCCESS';
export const ADD_NODE_FAILURE = 'ADD_NODE_FAILURE';

export const DISPLAY_HORIZONTALLY = 'DISPLAY_HORIZONTALLY';
export const DISPLAY_VERTICALLY = 'DISPLAY_VERTICALLY';

export const GET_EQUIPMENT_CLASS_BY_ID = 'GET_EQUIPMENT_CLASS_BY_ID';
export const GET_EQUIPMENT_CLASS_BY_ID_SUCCESS =
    'GET_EQUIPMENT_CLASS_BY_ID_SUCCESS';
export const GET_EQUIPMENT_CLASS_BY_ID_FAILURE =
    'GET_EQUIPMENT_CLASS_BY_ID_FAILURE';

export const DELETE_EQUIPMENT_CLASS = 'DELETE_EQUIPMENT_CLASS';
export const DELETE_EQUIPMENT_CLASS_SUCCESS = 'DELETE_EQUIPMENT_CLASS_SUCCESS';
export const DELETE_EQUIPMENT_CLASS_FAILURE = 'DELETE_EQUIPMENT_CLASS_FAILURE';

export const REMOVE_MAPPING = 'REMOVE_MAPPING';
export const REMOVE_MAPPING_SUCCESS = 'REMOVE_MAPPING_SUCCESS';
export const REMOVE_MAPPING_FAILURE = 'REMOVE_MAPPING_FAILURE';

export const OPEN_EDIT_NODE_DETAILS = 'OPEN_EDIT_NODE_DETAILS';
export const CLOSE_EDIT_NODE_DETAILS = 'CLOSE_EDIT_NODE_DETAILS';

export const EMPTY_MAPPED_TAGS_DATA = 'EMPTY_MAPPED_TAGS_DATA';

export const EDIT_NODE_DETAILS = 'EDIT_NODE_DETAILS';
export const EDIT_NODE_DETAILS_SUCCESS = 'EDIT_NODE_DETAILS_SUCCESS';
export const EDIT_NODE_DETAILS_FAILURE = 'EDIT_NODE_DETAILS_FAILURE';

export const SELECTED_ASSET = 'SELECTED_ASSET';

export const EMPTY_EQUIPMENT_CLASS_DETAILS='EMPTY_EQUIPMENT_CLASS_DETAILS';
export const EMPTY_TREE_OBJECT='EMPTY_TREE_OBJECT';
import { Col, Popover, Row, Spin, Switch, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type ColumnsType } from 'antd/es/table';
import { attribute, EMPTY, sortOrder } from 'types/enums';
import { MoreOutlined } from '@ant-design/icons';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as ErrorDotIcon } from 'assets/icons/inactiveDot.svg';
import { ReactComponent as ActivateIcon } from 'assets/icons/BlaIcon/Activate.svg';
import { ReactComponent as DeactiveIcon } from 'assets/icons/BlaIcon/Deactivate.svg';
import CustomPagination from 'components/common/CustomPagination';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import {
    MappingTableColumnSort,
    CreateConnectionScreen,
    ConnectionsState,
    MappingColLength,
    StateURL,
} from 'types/enums/businessIntegratorEnum';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './index.scss';
import MoreContent from './MoreContent';
import MultipleMoreContent from './MoreContent/multipleMoreContent';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import { setMappingState, activateMapping } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import { useNavigate } from 'react-router-dom';

const MappingTable: React.FC<any> = ({
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
}): any => {
    const navigate = useNavigate();
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<any>([]);
    const [selectedRowIds, setSelectedRowIds] = useState<any>([]);
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({})
    const [popoverheaderVisible, setPopoverheaderVisible] = useState<
        Record<string, boolean>
    >({})
    const [selectedRowData, setSelectedRowData] = useState<any>({});
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);

    const mappingTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingTableList
    );
    const mappingTableLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.mapping.mappingTableLoader
    )
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const isMappingActivated = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingActivated
    );
    const tableDataMapper = (): [] => {
        const tableData: any = [];
        mappingTableList?.paginatedResponse?.records?.map(
            (item: any, index: number) => {
                tableData.push({
                    ...item,
                    key: item.id,
                });
            }
        );
        return tableData;
    };
    useEffect(() => {
        isMappingActivated && setActiveSuccessModalOpen(true);
    }, [isMappingActivated]);
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [mappingTableList]);
    const handlePopoverVisibleChange = (
        visible: boolean,
        key: string
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [key]: visible,
        }));
    };
    const handleHeaderPopoverItemClick = (): void => {
        setPopoverheaderVisible({ actions: false });
    };
    const onOkHandler = (): any => {
        modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        setSelectedRowData({});
    };
    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('BusinessIntegrator.mapping.searchNoDataText')}
            loading={mappingTableLoader}
        />
    );

    const onSorting = (columnName: any, sortOrder: any): any => {
        setPage(PAGE);
        setSortColumn(columnName);
        setSortOrder(sortOrder);
    };

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const tempRowIds: number[] = [];
            selectedRows?.map((item: any) => {
                tempRowIds.push(item?.id);
            });
            setSelectedRowIds(tempRowIds);
        },
        onSelectAll: (selected: boolean, selectedRows: any) => {
            const tempRowIds: number[] = [];
            selectedRows?.map((item: any) => {
                tempRowIds.push(item?.id);
            });
            setSelectedRowIds(tempRowIds);
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    {selectedRowIds?.length > 1 ? (
                        <Popover
                            visible={popoverheaderVisible.actions}
                            onVisibleChange={(visible) => {
                                setPopoverheaderVisible((prevState: any) => ({
                                    ...prevState,
                                    actions: visible,
                                }));
                            }}
                            content={
                                <div className="custom-popover-content">
                                    <MultipleMoreContent
                                        selectedRowIds={selectedRowIds}
                                        onItemClick={
                                            handleHeaderPopoverItemClick
                                        }
                                        setSelectedRowIds={setSelectedRowIds}
                                    />
                                </div>
                            }
                            placement="bottomLeft"
                            trigger="click"
                            overlayStyle={{ width: '118px' }}
                            overlayClassName="mappingMultiplePopover"
                        >
                            <MoreOutlined />
                        </Popover>
                    ) : (
                        EMPTY.string
                    )}
                </div>
            ),
            key: 'more',
            width: 26,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            {selectedRowIds?.length <= 1 ? (
                                <Popover
                                    visible={popoverVisible[record.key]}
                                    onVisibleChange={(visible) => {
                                        handlePopoverVisibleChange(
                                            visible,
                                            record.key
                                        );
                                    }}
                                    placement="bottomLeft"
                                    trigger="click"
                                    content={
                                        <MoreContent
                                            record={record}
                                            setPopoverVisible={setPopoverVisible}
                                        />
                                    }
                                    overlayStyle={{ width: '162px' }}
                                >
                                    <MoreOutlined
                                        onClick={() => {
                                            handlePopoverVisibleChange(
                                                true,
                                                record.key
                                            );
                                        }}
                                    />
                                </Popover>
                            ) : (
                                    EMPTY.string
                                )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                                        <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.mappingName'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.mappingName,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.mappingName,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'mappingName',
            key: 'mappingName',
            className: 'column__name',
            width: '110px',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.mappingName?.length > MappingColLength.name
                        ? data?.mappingName?.substring(
                              CreateConnectionScreen.adapterScreen,
                              MappingColLength.name
                          ) + '...'
                        : data?.mappingName;
                return (
                    <>
                        <div
                            className="attributeTableWrapper__nameData"
                            onClick={() => {
                                dispatch(
                                    setMappingState(
                                        ConnectionsState.viewConnections
                                    )
                                );
                                navigate(
                                    `/${StateURL.mapping}/view/${data.key}`
                                );
                            }}
                        >
                            {data?.mappingName?.length >
                            attribute.attributeNameLength ? (
                                <div className="blaTableWrapper__status">
                                    {
                                        data?.isScheduled ? <ActiveDotIcon/> : <ErrorDotIcon/>
                                    }
                                    <Popover
                                        overlayClassName='popoverWidth'
                                        content={data?.mappingName}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        <span className="fs-14 fw-500 name">
                                            {truncatedText}
                                        </span>
                                    </Popover>
                                </div>
                            ) : (
                                <div className="blaTableWrapper__status">
                                    {
                                        data?.isScheduled ? <ActiveDotIcon/> : <ErrorDotIcon/>
                                    }
                                    <span className="fs-14 fw-500 name">
                                        {data?.mappingName}
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                                        <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.status'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.status,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.status,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'isScheduled',
            key: 'isScheduled',
            width: '65px',
            className: 'column__uom',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        <Switch
                            size="small"
                            key={data.key}
                            onClick={(checked: boolean) => {
                                setSelectedRowData(data);
                                setActivateModalOpen(true);
                            }}
                            value={data?.isScheduled}
                        />
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.description'
                        )}
                    </div>
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.description,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.description,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            width: '180px',
            className: 'column__description',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.description?.length > MappingColLength.description
                        ? data?.description?.substring(
                              CreateConnectionScreen.adapterScreen,
                              MappingColLength.description
                          ) + '...'
                        : data?.description;
                return (
                    <>
                        <div className="attributeTableWrapper__subItems">
                            <span className="fs-14 fw-500 name">
                                {data?.description?.length >
                                MappingColLength.description ? (
                                    <Popover
                                        overlayClassName='popoverWidth'
                                        content={data?.description}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        {truncatedText}
                                    </Popover>
                                ) : (
                                    data?.description
                                )}
                            </span>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.connectionName'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.connectionName,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.connectionName,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'connectionName',
            key: 'connectionName',
            className: 'column__name',
            width: '120px',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.connectionName?.length > MappingColLength.name
                        ? data?.connectionName?.substring(
                              CreateConnectionScreen.adapterScreen,
                              MappingColLength.name
                          ) + '...'
                        : data?.connectionName;
                return (
                    <>
                        <div>
                            {data?.connectionName?.length >
                            attribute.attributeNameLength ? (
                                <div>
                                    <Popover
                                        overlayClassName='popoverWidth'
                                        content={data?.connectionName}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        <span className="fs-14 fw-500 name">
                                            {truncatedText}
                                        </span>
                                    </Popover>
                                </div>
                            ) : (
                                <div>
                                    <span className="fs-14 fw-500 name">
                                        {data?.connectionName}
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.fileType'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.fileType,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.fileType,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'fileType',
            key: 'fileType',
            className: 'column__properties',
            width: '80px',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.fileType}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.direction'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.direction,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.direction,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'direction',
            key: 'direction',
            className: 'column__properties',
            width: '90px',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.direction}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.mappingConnect'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.tableName,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.tableName,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: '100px',
            dataIndex: 'tableName',
            key: 'tableName',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.tableName}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.label'
                        )}
                    </div>
                    <div className="sortArrows"></div>
                </div>
            ),
            dataIndex: 'label',
            key: 'label',
            width: '100px',
            className: 'column__valueType',
            render: (_: any, data: any) => {
                const array: string[] = [];
                data?.labels?.map((item: any) => {
                    array?.push(item);
                });
                const maxLength = 18;
                const dataLength= 200;
                const joinedArray = array?.join(', ');
                let truncatedData = joinedArray;
                let remainingData: string[] = [];
                if (joinedArray?.length > maxLength) {
                    const ellipsisLength = 3;
                    const remainingLength = maxLength - ellipsisLength;
                    truncatedData =
                        joinedArray.substring(0, remainingLength) + '...';
                    const lastCommaIndex = truncatedData?.lastIndexOf(',');
                    if (
                        lastCommaIndex !== -1 &&
                        lastCommaIndex < remainingLength
                    ) {
                        const remainingString = truncatedData?.substring(
                            lastCommaIndex + 1,
                            remainingLength
                        );
                        remainingData = [
                            remainingString +
                                joinedArray
                                    ?.substring(remainingLength)
                                    ?.split(',')[0],
                            ...joinedArray
                                ?.substring(remainingLength)
                                ?.split(',')
                                ?.slice(1),
                        ];
                    } else {
                        remainingData = joinedArray
                            ?.substring(remainingLength)
                            ?.split(',');
                    }
                }
                return (
                    <div className="timeCapsuleTableWrapper__subItems">
                        <span>{truncatedData}</span>
                        {remainingData?.length > 0 && (
                            <Tooltip
                                overlayClassName={
                                    remainingData?.join(', ')?.length > dataLength
                                        ? 'monitoringDivToolTip'
                                        : 'monitoringToolTip'
                                }
                                title={remainingData?.join(', ')}
                                color={"white"}
                            >
                                <span className="timeCapsuleTable__subItems">
                                    +{remainingData?.length}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            key: 'createdBy',
            className: 'column__addedOn',
            width: '100px',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.createdBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.createdBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.createdBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record.createdBy}
                    </div>
                </>
            ),
        },
        {
            key: 'createdOn',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.createdOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.createdOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.createdOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdOn',
            width: '100px',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record.createdOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            key: 'changedBy',
            width: '100px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.changedBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.updatedBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.updatedBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record?.updatedBy}
                    </div>
                </>
            ),
        },
        {
            key: 'changedOn',
            width: '100px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.mapping.tableColumn.changedOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.updatedOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MappingTableColumnSort.updatedOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedOn',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record?.updatedOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {mappingTableLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row
                        className={
                            mappingTableList?.paginatedResponse?.totalRecords >
                            50
                                ? 'attributeWrapper__attributeListPagination'
                                : 'attributeWrapper__attributeList'
                        }
                    >
                        <Col span={24}>
                            <Table
                                rowSelection={{
                                    type: 'checkbox',
                                    ...rowSelection,
                                    selectedRowKeys: selectedRowIds,
                                }} 
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                loading={mappingTableLoader}
                                scroll={{
                                    y:
                                        mappingTableList?.paginatedResponse
                                            ?.totalRecords > 50
                                            ? 'calc(100vh - 390px)'
                                            : 'calc(100vh - 360px)',
                                    x: '2000px',
                                }}
                                locale={{ emptyText: SearchNoDataText }}
                            />
                        </Col>
                    </Row>
                    {mappingTableList?.paginatedResponse?.totalRecords > 50 ? (
                        <CustomPagination
                            totalRecords={
                                mappingTableList?.paginatedResponse
                                    ?.totalRecords
                            }
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    ) : (
                        EMPTY.string
                    )}
                </>
            )}
            {activateModalOpen && (
                <ConfirmationModal
                    icon={selectedRowData?.isScheduled ? <DeactiveIcon /> : <ActivateIcon/>}
                    open={activateModalOpen}
                    onOk={() => {
                        dispatch(activateMapping({
                            mappingId: selectedRowData?.id,
                            isScheduled: !selectedRowData?.isScheduled,
                            requestedBy: loggedInUserDetails?.firstName +
                                    loggedInUserDetails?.lastName,
                        }))
                        setSelectedRowIds([]);
                        setActivateModalOpen(false);
                    }}
                    onCancel={() => {
                        setActivateModalOpen(false);
                        setSelectedRowData({});
                    }}
                    text={selectedRowData?.isScheduled ? t('BusinessIntegrator.mapping.confirmationMessageDeactivate') : t('BusinessIntegrator.mapping.confirmationMessageActivate')}
                    customClassName="confirmationModal"
                />
            )}
            <SuccessfulModal
                    open={activeSuccessModalOpen}
                    onOk={() => onOkHandler()}
                    onCancel={() => {
                        cancelHandle(
                            activeSuccessModalOpen,
                            setActiveSuccessModalOpen
                        );
                    }}
                    text={selectedRowData?.isScheduled ? t('commonStr.deactivateSuccess') : t('commonStr.activateSuccess')}
                />
        </>
    );
};

export default MappingTable;

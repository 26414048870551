import { Avatar, Popover, Spin, Table, Tooltip } from 'antd';
import React, { useState, useEffect } from 'react';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { type ColumnsType } from 'antd/es/table';
import { MoreOutlined } from '@ant-design/icons';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as InactiveDotIcon } from 'assets/icons/inactiveDot.svg';
import './AlertTable.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getIntials, randomBackgroundColour } from 'utils/commonFunction';
import type { rolesListObject } from 'types/interfaces/PropsInterfaces';
import {
    ALERTTYPE,
    alertSortOptions,
    kpiDefinition,
    sortOrder,
} from 'types/enums';
import { PAGE } from 'utils/constants';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import AlertMultipleActiveDeactive from './AlertMoreContent/AlertMultipleActivateDeactivate';
import AlertMoreContent from './AlertMoreContent/AlertActivateDeactivate';
import {
    getAlertById,
    getAlertList,
    setAlertState,
} from 'redux/actions/AlertActions/alertActions';
import { useTranslation } from 'react-i18next';

const AlertTable: React.FC<any> = ({
    data,
    payload,
    search,
    setSortColumn,
    setSortOrder,
    setPage,
}) => {
    const [tableData, setTableData] = useState<any>([]);
    const [selectedActiveIds, setSelectedActiveIds] = useState<any>([]);
    const [selectedInactiveIds, setSelectedInactiveIds] = useState<any>([]);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };

    const alertListLoading = useSelector(
        (state: any) => state.alert?.alertListLosding
    );

    const tableDataMapper = (): [] => {
        const temp: any = [];
        data?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.id,
                users: item?.users?.map((user: any) => ({
                    ...user,
                    backgroundColor: randomBackgroundColour(),
                })),
            });
        });

        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
        // Timeout is used to delay the popUp confirmation message for 1sec
        setTimeout(() => {
            setSelectedRowIds([]);
            setSelectedRows([]);
        }, 1000);
    }, [data]);

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('alerts.emptySearchText')}
            loading={alertListLoading}
        />
    );

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );
            setSelectedActiveIds([...selectedActiveRows]);
            setSelectedInactiveIds([...selectedInactiveRows]);
            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
                setSelectedRows([...selectedRows, record]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );
                setSelectedRows(
                    selectedRows.filter((row: any) => row?.key !== record?.key)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );
            setSelectedActiveIds([...selectedActiveRows]);
            setSelectedInactiveIds([...selectedInactiveRows]);
            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record: any) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
                setSelectedRows(selectedRows);
            } else {
                setSelectedRowIds([]);
                setSelectedRows([]);
            }
        },
    };

    const [popoverheaderVisible, setPopoverheaderVisible] = useState({
        actions: false,
    });

    const handlePopoverItemClick = (): void => {
        setPopoverheaderVisible({ actions: false });
    };

    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});

    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };

    const [showPopover, setShowPopover] = useState(false);
    const [showHeaderPopover, setShowHeaderPopover] = useState(false);
    useEffect(() => {
        // Check if selectedRowIds has any entries
        setShowPopover(selectedRowIds.length <= 1);
        setShowHeaderPopover(selectedRowIds.length > 1);
    }, [selectedRowIds]);

    const sortingFunction = (
        columnTobeSorted: number,
        orderOfSort: number
    ): void => {
        dispatch(
            getAlertList({
                ...payload,
                search: search,
                sortOrder: orderOfSort,
                sortColumn: columnTobeSorted,
            })
        );
        setSortOrder(orderOfSort);
        setSortColumn(columnTobeSorted);
        setPage(PAGE);
    };

    const tableColumns: ColumnsType<rolesListObject> = [
        {
            title: (
                <>
                    <div>
                        {showHeaderPopover && (
                            <Popover
                                visible={popoverheaderVisible.actions}
                                onVisibleChange={(visible) => {
                                    setPopoverheaderVisible(
                                        (prevState: any) => ({
                                            ...prevState,
                                            actions: visible,
                                        })
                                    );
                                }}
                                content={
                                    <div className="custom-popover-content">
                                        <AlertMultipleActiveDeactive
                                            multipleRecord={selectedRows}
                                            selectedUserIds={selectedRowIds}
                                            onItemClick={handlePopoverItemClick}
                                            selectedActiveIds={
                                                selectedActiveIds
                                            }
                                            selectedInactiveIds={
                                                selectedInactiveIds
                                            }
                                            paginatedPayload={payload}
                                            searchPayload={search}
                                        />
                                    </div>
                                }
                                placement="bottomLeft"
                                trigger="click"
                            >
                                <MoreOutlined />
                            </Popover>
                        )}
                    </div>
                </>
            ),
            key: 'more',
            width: 56,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                visible={popoverVisible[record.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(visible, record);
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                content={
                                    <AlertMoreContent
                                        record={record}
                                        setPopoverVisible={setPopoverVisible}
                                        paginatedPayload={payload}
                                        searchPayload={search}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },

        {
            key: 'alertName',
            title: (
                <>
                    <div className="alertTableWrapper__columnTitle">
                        <div>{t('alerts.alertName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    sortingFunction(
                                        alertSortOptions.ALERT_NAME,
                                        sortOrder.ascending
                                    );
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    sortingFunction(
                                        alertSortOptions.ALERT_NAME,
                                        sortOrder.descending
                                    );
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'name',
            render: (_: any, data: any) => (
                <>
                    <div
                        className="alertTableWrapper__nameData"
                        onClick={() => {
                            dispatch(getAlertById(data?.id));
                            dispatch(setAlertState(ALERTTYPE?.view));
                        }}
                    >
                        <div className="blaTableWrapper__status">
                            {data.isActive ? (
                                <>
                                    <ActiveDotIcon />
                                </>
                            ) : (
                                <InactiveDotIcon />
                            )}

                            <span className="fs-14 fw-500 name">
                                {data?.name?.length <
                                kpiDefinition.kpiNameLength ? (
                                    data?.name
                                ) : (
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {data?.name}
                                            </div>
                                        }
                                        visible={isPopoverVisibles[data?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {data?.name?.length >
                                        kpiDefinition.kpiNameLength
                                            ? `${data?.name?.slice(
                                                  0,
                                                  kpiDefinition.kpiNameLength
                                              )}...`
                                            : data?.name}
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="alertTableWrapper__columnTitle">
                    <div>{t('alerts.description')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    alertSortOptions.DESCRIPTION,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                sortingFunction(
                                    alertSortOptions.DESCRIPTION,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'description',
            dataIndex: 'description',
            render: (_: any, data: any) => (
                <>
                    <div className="alertTableWrapper__subItems">
                        <span className="fs-14 fw-500 name">
                            {data?.description?.length <
                            kpiDefinition?.kpiDescriptionLength ? (
                                data?.description
                            ) : (
                                <Popover
                                    overlayClassName="customOverlay"
                                    content={
                                        <div className="blaName">
                                            {data?.description}
                                        </div>
                                    }
                                    visible={isPopoverVisibles[data?.key]}
                                    onVisibleChange={
                                        handlePopoverVisibleChanges
                                    }
                                    placement="topLeft"
                                >
                                    {data?.description?.length >
                                    kpiDefinition?.kpiDescriptionLength
                                        ? `${data?.description?.slice(
                                              0,
                                              kpiDefinition?.kpiDescriptionLength
                                          )}...`
                                        : data?.description}
                                </Popover>
                            )}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="alertTableWrapper__columnTitle">
                    <div>{t('alerts.modelName')}</div>
                </div>
            ),
            key: 'modelName',
            dataIndex: 'modelName',
            render: (_: any, data: any) => (
                <>
                    <div className="alertTableWrapper__subItems">
                        <span className="fs-14 fw-500 name">
                            {data?.modelName}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="alertTableWrapper__columnTitle">
                    <div>{t('alerts.assetName')}</div>
                </div>
            ),
            key: 'assetName',
            dataIndex: 'assetName',
            render: (_: any, data: any) => (
                <>
                    <div className="alertTableWrapper__subItems">
                        <span className="fs-14 fw-500 name">
                            {data?.assetName}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="alertTableWrapper__columnTitle">
                    <div>{t('alerts.users')}</div>
                </div>
            ),
            key: 'users',
            dataIndex: 'users',
            render: (_: any, data: any) => (
                <>
                    <Avatar.Group
                        maxCount={3}
                        maxPopoverTrigger="click"
                        size="large"
                        className="alertTableWrapper__avatarGroup "
                    >
                        {data?.users?.map((item: any) => (
                            <Avatar
                                key={item?.id}
                                style={{
                                    backgroundColor: item?.backgroundColor,
                                }}
                                src={item?.profileImage}
                            >
                                {item &&
                                    getIntials(
                                        `${item?.firstName} ${item?.lastName}`
                                    )}
                            </Avatar>
                        ))}
                    </Avatar.Group>
                </>
            ),
        },
        {
            title: (
                <div className="alertTableWrapper__columnTitle">
                    <div>{t('alerts.varType')}</div>
                </div>
            ),
            key: 'variableName',
            dataIndex: 'variableName',
            render: (_: any, data: any) => {
                const array: string[] = [];
                data?.variableName?.map((item: any) => {
                    array?.push(item.name);
                });
                const maxLength = 25;
                const joinedArray = array.join(',');
                let truncatedData = joinedArray;
                let remainingData: string[] = [];
                if (joinedArray.length > maxLength) {
                    const ellipsisLength = 3;
                    const remainingLength = maxLength - ellipsisLength;
                    truncatedData =
                        joinedArray.substring(0, remainingLength) + '...';
                    const lastCommaIndex = truncatedData.lastIndexOf(',');

                    if (
                        lastCommaIndex !== -1 &&
                        lastCommaIndex < remainingLength
                    ) {
                        const remainingString = truncatedData.substring(
                            lastCommaIndex + 1,
                            remainingLength
                        );
                        remainingData = [
                            remainingString +
                                joinedArray
                                    .substring(remainingLength)
                                    .split(',')[0],
                            ...joinedArray
                                .substring(remainingLength)
                                .split(',')
                                .slice(1),
                        ];
                    } else {
                        remainingData = joinedArray
                            .substring(remainingLength)
                            .split(',');
                    }
                }

                return (
                    <div className="subItems">
                        <span>{truncatedData}</span>
                        {remainingData.length > 0 && (
                            <Tooltip
                                title={remainingData.map((item, index) => (
                                    <div key={index}>{item}</div>
                                ))}
                            >
                                <span className="groupTable__subItems">
                                    +{remainingData.length}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {alertListLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRowIds,
                        ...rowSelection,
                    }}
                    pagination={false}
                    columns={tableColumns}
                    dataSource={tableData}
                    loading={alertListLoading}
                    scroll={{ y: 'calc(100vh - 390px)' }}
                    locale={{
                        emptyText: SearchNoDataText,
                    }}
                    onRow={(record: any) => {
                        const isPopoverVisible = popoverVisible[record.id];
                        return isPopoverVisible
                            ? {
                                  onMouseLeave: () => {
                                      // Set popover visibility for the current record
                                      handlePopoverVisibleChange(false, record);
                                  },
                              }
                            : {};
                    }}
                />
            )}
        </>
    );
};

export default AlertTable;

import { baseUrlSetter } from "utils/commonFunction";
import Api from "..";
import { message } from "antd";

export const getLdapServiceListService = async (
  payload?: any
): Promise<any> => {
  baseUrlSetter("userManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/connections/getDetails`, { params: payload })
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const createLdapConfiguratorSettingsService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("userManagement");
  const params = payload;
  try {
    const response = await Promise.resolve(
      Api.post(`/connections/addDetails`, params)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data);
    throw new Error(error);
  }
};

export const getLdapUsersListService = async (payload: any): Promise<any> => {
  baseUrlSetter("userManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/connections/getUsers/${payload}`)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data);
    throw new Error(error);
  }
};

export const getLdapTypeOfIdentityListService = async (): Promise<any> => {
  baseUrlSetter("userManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/connections/identitytypes`)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const testLdapConfiguratorService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("userManagement");
  const params = payload;
  try {
    const response = await Promise.resolve(
      Api.post(`/connections/testConnections`, params)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getLdapServiceConfiguratorDetailsByIdService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("userManagement");
  const params = payload;
  try {
    const response = await Promise.resolve(
      Api.get(`/connections/getDetails/${params}`)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getLdapCountListService = async (): Promise<any> => {
  baseUrlSetter("userManagement");
  try {
    const response = await Promise.resolve(Api.get(`/connections/getCount`));
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const deleteLdapService = async (payload: any): Promise<any> => {
  baseUrlSetter("userManagement");
  try {
    const response = await Promise.resolve(
      Api.put(
        `/connections/deactivate/${payload.id}?isActivated=${payload.isActivated}`
      )
    );
    return response.data.message;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

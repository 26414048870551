import { baseUrlSetter } from "utils/commonFunction";
import Api from "../index";
import { message } from "antd";


export const getqrCodeTableListServices= async (
  payload: any
): Promise<any> => {
  baseUrlSetter("qr");
  const params = payload;
  try {
    const response = Api.get(`/qrcode`, {
      params: params,
    });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getqrCodeListByIdServices= async (
  payload: any
): Promise<any> => {
  baseUrlSetter("qr");
  try {
    const response = Api.get(`/qrcode/${payload}`)
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};






// state of QR code Logs

export const GET_QRCODELOGS_LIST = "GET_QRCODELOGS_LIST";
export const GET_QRCODELOGS_LIST_SUCCESS = "GET_QRCODELOGS_LIST_SUCCESS";
export const GET_QRCODELOGS_LIST_FAILURE = "GET_QRCODELOGS_LIST_FAILURE";

export const GET_QRCODELOGSLIST_BYID = "GET_QRCODELOGSLIST_BYID";
export const GET_QRCODELOGSLIST_BYID_SUCCESS = "GET_QRCODELOGSLIST_BYID_SUCCESS";
export const GET_QRCODELOGSLIST_BYID_FAILURE = "GET_QRCODELOGSLIST_BYID_FAILURE";
// state of QR code Config

export const SET_CONGIF_STATE = "SET_CONGIF_STATE";
export const RESET_CONGIF_STATES = "RESET_CONGIF_STATES";


export const GET_QR_DATATYPE = "GET_QR_DATATYPE";
export const GET_QR_DATATYPE_SUCCESS = "GET_QR_DATATYPE_SUCCESS";
export const GET_QR_DATATYPE_FAILURE = "GET_QR_DATATYPE_FAILURE";

export const GET_IMAGE_TYPE = "GET_IMAGE_TYPE";
export const GET_IMAGE_TYPE_SUCCESS = "GET_IMAGE_TYPE_SUCCESS";
export const GET_IMAGE_TYPE_FAILURE = "GET_IMAGE_TYPE_FAILURE";

export const CREATE_QR_CONFIG = "CREATE_QR_CONFIG";
export const CREATE_QR_CONFIG_SUCCESS = "CREATE_QR_CONFIG_SUCCESS";
export const CREATE_QR_CONFIG_FAILURE = "CREATE_QR_CONFIG_FAILURE";

export const GET_QR_CONFIG_LIST = "GET_QR_CONFIG_LIST";
export const GET_QR_CONFIG_LIST_SUCCESS = "GET_QR_CONFIG_LIST_SUCCESS";
export const GET_QR_CONFIG_LIST_FAILURE = "GET_QR_CONFIG_LIST_FAILURE";

export const GENERATE_QR_CODE = "GENERATE_QR_CODE";
export const GENERATE_QR_CODE_SUCCESS = "GENERATE_QR_CODE_SUCCESS";
export const GENERATE_QR_CODE_FAILURE = "GENERATE_QR_CODE_FAILURE";

export const GET_QRCODELOGSLIST_WITHOUT_PAGINATED = "GET_QRCODELOGSLIST_WITHOUT_PAGINATED";
export const GET_QRCODELOGSLIST_WITHOUT_PAGINATED_SUCCESS = "GET_QRCODELOGSLIST_WITHOUT_PAGINATED_SUCCESS";
export const GET_QRCODELOGSLIST_WITHOUT_PAGINATED_FAILURE = "GET_QRCODELOGSLIST_WITHOUT_PAGINATED_FAILURE";

export const  GET_QR_CONFIG_LIST_WITHOUT_PAGINATED = " GET_QR_CONFIG_LIST_WITHOUT_PAGINATED";
export const  GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_SUCCESS = "GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_SUCCESS";
export const  GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_FAILURE = "GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_FAILURE";

export const GET_QR_CONFIG_DETAILS = "GET_QR_CONFIG_DETAILS";
export const GET_QR_CONFIG_DETAILS_SUCCESS = "GET_QR_CONFIG_DETAILS_SUCCESS";
export const GET_QR_CONFIG_DETAILS_FAILURE = "GET_QR_CONFIG_DETAILS_FAILURE";

export const UPDATE_QR_CONFIG = "UPDATE_QR_CONFIG";
export const UPDATE_QR_CONFIG_SUCCESS = "UPDATE_QR_CONFIG_SUCCESS";
export const UPDATE_QR_CONFIG_FAILURE = "UPDATE_QR_CONFIG_FAILURE";

export const SET_QR_CONFIG_STATUS = "SET_QR_CONFIG_STATUS";
export const SET_QR_CONFIG_STATUS_SUCCESS = "SET_QR_CONFIG_STATUS_SUCCESS";
export const SET_QR_CONFIG_STATUS_FAILURE = "SET_QR_CONFIG_STATUS_FAILURE";

import Table, { type ColumnsType } from 'antd/es/table';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ROLETYPE, assetDetailsTable, sortOrder } from 'types/enums';
import { useTranslation } from 'react-i18next';

const Asset: React.FC<any> = ({ setSortColumn, setSortOrder }) => {
    const { t } = useTranslation('translation');

    const allNodesDetails = useSelector(
        (state: any) => state?.userManagement?.roles?.allNodeDetails?.records
    );

    const [tableData, setTableData] = useState<any>([]);
    const tableDataMapper = (): [] => {
        const temp: any = [];
        allNodesDetails?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item?.id,
            });
        });
        return temp;
    };

    useEffect(() => {
        setTableData(tableDataMapper());
    }, [tableData]);
    const editRoleState = useSelector(
        (state: any) => state.userManagement.roles.editRoleState
    );
    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.assetName')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(assetDetailsTable?.NODE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(assetDetailsTable?.NODE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__nameData">
                        {record?.name}
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.nodeType')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(assetDetailsTable?.NODE_TYPE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(assetDetailsTable?.NODE_TYPE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'nodeType',
            key: 'nodeType',
            className: 'column__description',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {record?.nodeType}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.parentNode')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(assetDetailsTable?.PARENT_NODE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(assetDetailsTable?.PARENT_NODE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'parentNode',
            key: 'parentNode',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {record?.parentNode}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.modelName')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(assetDetailsTable?.MODEL);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(assetDetailsTable?.MODEL);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'modelName',
            dataIndex: 'modelName',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.model}</div>
                </>
            ),
        },
    ];
    return (
        <>
            <div
                style={
                    editRoleState === ROLETYPE?.viewRole
                        ? { pointerEvents: 'none', opacity: 0.5 }
                        : {}
                }
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 500px)' }}
                />
            </div>
        </>
    );
};
export default Asset;

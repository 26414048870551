import { Col, Row } from 'antd';
import CountAnalytics from 'components/common/CountAnalytics';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/deacticeUserCountIcon.svg';
import { LDAPConfiguratorState } from 'types/enums/ldapConfiguratorEnum';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import LDAPScreen from './ldapScreen';
import CreateLdap from './CreateLdap';
import { useEffect } from 'react';
import {
    getLdapCountList,
    getLdapServiceList,
} from 'redux/actions/LdapConfigurator/ldapConfiguratorActions';
import { useTranslation } from 'react-i18next';

const LDAPConfigurator: React.FC = (): any => {
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();
    const ldapState = useSelector(
        (state: any) => state.ldapConfigurator?.ldapConfiguratorState
    );
    const ldapCountList = useSelector(
        (state: any) => state.ldapConfigurator?.ldapCountList
    );

    const getCountAnalyticsDetails = [
        {
            title: t('ldapConfigurator.connected'),
            count:
                ldapCountList?.totalConnectedServer > 0
                    ? ldapCountList?.totalConnectedServer
                    : '--',
            icon: <ActiveUserIcon />,
        },
        {
            title: t('ldapConfigurator.disconnected'),
            count:
                ldapCountList?.totalDisconnectedServer > 0
                    ? ldapCountList?.totalDisconnectedServer
                    : '--',
            icon: <DeactivateUserIcon />,
        },
    ];

    const getComponent = (): any => {
        switch (ldapState) {
            case LDAPConfiguratorState.tableView:
                return <LDAPScreen />;
            default:
                return <CreateLdap />;
        }
    };

    useEffect(() => {
        if (ldapState === LDAPConfiguratorState.tableView) {
            dispatch(getLdapServiceList());
            dispatch(getLdapCountList());
        }
    }, [ldapState]);

    return (
        <>
            <div className="uomConfigurator">
                <Row className="uomConfigurator__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t(
                                'ldapConfigurator.ScreenNameHeading.heading'
                            )}
                            subHeading={t(
                                'ldapConfigurator.ScreenNameHeading.subHeading'
                            )}
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails}
                        />
                    </Col>
                </Row>
                {getComponent()}
            </div>
        </>
    );
};

export default LDAPConfigurator;

import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'antd';
import './index.scss';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import RoleProgress from 'components/common/RoleProgress';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import type { rolePermissionTypes } from 'types/interfaces/PropsInterfaces';
import CustomButton from 'components/common/CustomButton';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useDispatch, useSelector } from 'react-redux';
import {
    setEditRoleState,
    createRoles,
    setResourceTypePayload,
    getResourceType,
    setCheckedItemsForScreen,
    setResourceType,
    setCheckedItemsForKpi,
    setCheckedItemsForAsset,
    updateRole,
} from 'redux/actions/UserManagementActions/rolesAction';
import { BUTTONTYPE, ROLETYPE, screenName } from 'types/enums';
import CreateRoleStep1 from './CreateRoleResourceSelection';
import CreateRoleStep3 from './CreateRoleWithMeta';
import { useTranslation } from 'react-i18next';
import { parseJwt } from 'utils/jwtTokenFunction';
import {
    EMPTY_RESOURCE_TYPE_DETAILS,
    EMPTY_ROLE_DETAILS,
} from 'redux/types/userManagementTypes';

const CreateRole: React.FC<any> = ({ editRoleState }) => {
    const { t } = useTranslation('translation');
    const details = parseJwt();
    const [count, setCount] = useState(1);
    const [nameState, setNameState] = useState('');
    const [descriptionState, setDescriptionState] = useState('');
    const [rolesPermissionListState, setRolesPermissionListState] = useState<
        rolePermissionTypes[]
    >([]);

    const dispatch = useDispatch();

    const createRoleResponse = useSelector(
        (state: any) => state.userManagement.roles.createRoleState
    );

    const updateRoleResponse = useSelector(
        (state: any) => state.userManagement.roles.updateRole
    );

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);

    useEffect(() => {
        createRoleResponse &&
            modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        okHandle(isModalOpen, setIsModalOpen);
    }, [createRoleResponse]);

    useEffect(() => {
        updateRoleResponse &&
            modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        okHandle(isModalOpen, setIsModalOpen);
    }, [updateRoleResponse]);

    const selectedRowData = useSelector(
        (state: any) => state.userManagement.roles.usersRoleListPayload
    );
    const roleDetails = useSelector(
        (state: any) => state.userManagement.roles.roleDetails
    );
    const loggedInUserId = parseJwt();
    const payload = {
        roleId: roleDetails?.roleId,
        roleName:
            editRoleState === ROLETYPE?.edit
                ? nameState || roleDetails?.roleName
                : nameState,
        roleDescription:
            editRoleState === ROLETYPE?.edit
                ? descriptionState || roleDetails?.roleDescription
                : descriptionState,
        resourceGroupDtoList: rolesPermissionListState,
        active: !!roleDetails,
        deleted: false,
        createdBy: details?.username,
        userMetaDataList: selectedRowData,
        updatedBy: loggedInUserId.username,
    };
    const resourcetypeList = useSelector(
        (state: any) => state.userManagement.groups.resourceType
    );

    useEffect(() => {
        dispatch(getResourceType());
    }, []);

    const onOkHandler = (): any => {
        if (editRoleState === ROLETYPE?.create) {
            dispatch(createRoles(payload));
        }
        if (editRoleState === ROLETYPE?.edit) {
            dispatch(updateRole(payload));
        }
        dispatch(setCheckedItemsForScreen([]));
        dispatch(setCheckedItemsForKpi([]));
        dispatch(setCheckedItemsForAsset([]));
        dispatch(setResourceType(null));
        dispatch({
            type: EMPTY_RESOURCE_TYPE_DETAILS,
        });
        dispatch({ type: EMPTY_ROLE_DETAILS });
    };
    return (
        <>
            <div className="createRolesWrapper">
                <Card bordered={false} bodyStyle={{ padding: 0 }}>
                    <Row className="createRolesWrapper__headerWrapper">
                        <Col span={18} className="createRolesWrapper__heading">
                            <div className="createRolesWrapper__backIcon">
                                <BackIcon
                                    onClick={() => {
                                        dispatch({
                                            type: EMPTY_RESOURCE_TYPE_DETAILS,
                                        });
                                        dispatch(
                                            setEditRoleState(ROLETYPE.view)
                                        );
                                        dispatch(setCheckedItemsForScreen([]));
                                        dispatch(setCheckedItemsForKpi([]));
                                        dispatch(setResourceType(null));
                                        dispatch(setCheckedItemsForAsset([]));
                                        dispatch({ type: EMPTY_ROLE_DETAILS });
                                    }}
                                />
                            </div>
                            <ScreenNameHeading
                                heading={
                                    editRoleState === ROLETYPE?.create
                                        ? t('roles.createRole')
                                        : roleDetails?.roleName
                                }
                                subHeading={
                                    editRoleState === ROLETYPE?.create
                                        ? t('roles.roledescp')
                                        : roleDetails?.roleDescription
                                }
                            />
                        </Col>
                        {editRoleState !== ROLETYPE?.viewRole ? (
                            <Col span={6}>
                                <RoleProgress
                                    count={count}
                                    screen={screenName.userManagementRoles}
                                    totalCount={2}
                                />
                            </Col>
                        ) : (
                            <Col span={6} className="editRoleBtn">
                                <div className="editBtn">
                                    <CustomButton
                                        type={BUTTONTYPE?.edit}
                                        disabled={false}
                                        customClassName="editRole"
                                        handleClick={() => {
                                            dispatch(
                                                setEditRoleState(ROLETYPE.edit)
                                            );
                                        }}
                                    />
                                </div>
                            </Col>
                        )}
                    </Row>

                    <div className="createRolesWrapper__scrollContent">
                        {count === 1 ? (
                            <CreateRoleStep1
                                rolesPermissionListState={
                                    rolesPermissionListState
                                }
                                setRolesPermissionListState={
                                    setRolesPermissionListState
                                }
                                resourcetypeList={resourcetypeList}
                                editRoleState={editRoleState}
                            />
                        ) : count === 2 ? (
                            <CreateRoleStep3
                                setNameState={setNameState}
                                setDescriptionState={setDescriptionState}
                                name={nameState}
                                descriptionState={descriptionState}
                            />
                        ) : (
                            ''
                        )}
                    </div>
                    {editRoleState !== ROLETYPE?.viewRole && (
                        <div className="createRolesWrapper__createRoleFooter">
                            <div className="createRolesWrapper__footerButtonWrapper">
                                <CustomButton
                                    type={
                                        count === 1
                                            ? t('commonStr.cancel')
                                            : t('commonStr.back')
                                    }
                                    disabled={false}
                                    handleClick={() => {
                                        if (count === 1) {
                                            dispatch({
                                                type: EMPTY_RESOURCE_TYPE_DETAILS,
                                            });
                                            dispatch(setResourceType(null));
                                            dispatch(
                                                setCheckedItemsForScreen([])
                                            );
                                            dispatch(setCheckedItemsForKpi([]));
                                            dispatch(
                                                setEditRoleState(ROLETYPE.view)
                                            );
                                        } else {
                                            setCount(count - 1);
                                        }
                                    }}
                                />
                            </div>
                            <div className="createRolesWrapper__footerButtonWrapper">
                                {count === 2 ? (
                                    <CustomButton
                                        type={t('commonStr.save')}
                                        disabled={
                                            editRoleState === ROLETYPE.create &&
                                            nameState === ''
                                        }
                                        handleClick={() => {
                                            modalShow(
                                                isModalOpen,
                                                setIsModalOpen
                                            );
                                        }}
                                    />
                                ) : (
                                    <CustomButton
                                        type={t('commonStr.next')}
                                        disabled={
                                            rolesPermissionListState?.length ===
                                            0
                                        }
                                        handleClick={() => {
                                            dispatch(
                                                setResourceTypePayload(
                                                    rolesPermissionListState
                                                )
                                            );
                                            setCount(count + 1);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </Card>
            </div>

            <ConfirmationModal
                open={isModalOpen}
                icon={<ConfirmationIcon />}
                onOk={() => onOkHandler()}
                onCancel={() => cancelHandle(isModalOpen, setIsModalOpen)}
                text={t('roles.confirmText')}
            />
            <SuccessfulModal
                open={activeSuccessModalOpen}
                onOk={() => onOkHandler()}
                onCancel={() => {
                    dispatch(setEditRoleState(ROLETYPE.view));
                    cancelHandle(
                        activeSuccessModalOpen,
                        setActiveSuccessModalOpen
                    );
                }}
                text={t('roles.savetext')}
            />
        </>
    );
};

export default CreateRole;

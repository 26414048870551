import { Button, Card, Col, Form, message, Row } from 'antd';
import CustomButton from 'components/common/CustomButton';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    createLdapConfiguratorSettings,
    getLdapTypeOfIdentityList,
    setLdapState,
    testLdapConfigurator,
} from 'redux/actions/LdapConfigurator/ldapConfiguratorActions';
import { LDAPConfiguratorState } from 'types/enums/ldapConfiguratorEnum';
// TODO : will use later
// import RoleProgress from 'components/common/RoleProgress';
// import { screenName } from 'types/enums';
import { type ReactNode, useEffect, useState } from 'react';
import CreateLdapConfigurationSetting from './CreateLdapSteps/CreateLdapConfigurationSetting';
import CreateLdapPeriodicFetch from './CreateLdapSteps/CreateLdapPeriodicFetch';
import { getDaysList } from 'redux/actions/CommonActions/commonActions';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useTranslation } from 'react-i18next';

const CreateLdap: React.FC = () => {
    const { t } = useTranslation('translation');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [createLdapProgressCount, setCreateLdapProgressCount] = useState(1);
    const [isTestLoginDisable, setIsTestLoginDisable] = useState(true);
    const [onLdapCreateModalOpen, setOnLdapCreateModalOpen] = useState(false);
    const [
        onLdapCreateSuccessfullModalOpen,
        setOnLdapCreateSuccessfullModalOpen,
    ] = useState(false);
    const [onLdapCancelModalOpen, setOnLdapCancelModalOpen] = useState(false);

    const ldapState = useSelector(
        (state: any) => state.ldapConfigurator?.ldapConfiguratorState
    );

    const ldapTestSuccess = useSelector(
        (state: any) => state.ldapConfigurator?.ldapTestSuccess
    );

    const ldapServiceCreated = useSelector(
        (state: any) => state.ldapConfigurator?.ldapServiceCreated
    );

    const getLdapServiceConfiguratorDetailsById = useSelector(
        (state: any) =>
            state.ldapConfigurator?.getLdapServiceConfiguratorDetailsById
    );

    const ldapServiceName = Form.useWatch('serviceName', form);
    const ldapUrl = Form.useWatch('server', form);
    const port = Form.useWatch('port', form);
    const rootDomainField = Form.useWatch('baseDn', form);
    const bindUserName = Form.useWatch('bindDn', form);
    const bindPassword = Form.useWatch('bindPassword', form);
    const searchFilter = Form.useWatch('searchFilter', form);
    const searchScope = Form.useWatch('searchScope', form);
    const typeOfIdentity = Form.useWatch('typeOfIdentity', form);

    useEffect(() => {
        setIsTestLoginDisable(
            !(
                ldapServiceName &&
                typeOfIdentity &&
                ldapUrl &&
                port &&
                rootDomainField &&
                bindUserName &&
                bindPassword
            )
        );
    }, [
        ldapServiceName,
        ldapUrl,
        port,
        rootDomainField,
        bindUserName,
        bindPassword,
        typeOfIdentity,
    ]);

    const onBackClickHandler = (): any => {
        if (createLdapProgressCount === 2) {
            setCreateLdapProgressCount(1);
        } else {
            if (ldapState === LDAPConfiguratorState.ldapConfiguratorEdit) {
                dispatch(
                    setLdapState(LDAPConfiguratorState.ldapConfiguratorView)
                );
            } else {
                dispatch(setLdapState(LDAPConfiguratorState.tableView));
            }
        }
    };
    const onSaveNextClickHandler = (): any => {
        modalShow(onLdapCreateModalOpen, setOnLdapCreateModalOpen);
    };

    const onSuccesfulModalCancelHanlder = (): any => {
        // setCreateLdapProgressCount(createLdapProgressCount + 1);
        cancelHandle(
            onLdapCreateSuccessfullModalOpen,
            setOnLdapCreateSuccessfullModalOpen
        );
        dispatch(setLdapState(LDAPConfiguratorState.tableView));
    };

    useEffect(() => {
        if (ldapServiceCreated) {
            cancelHandle(onLdapCreateModalOpen, setOnLdapCreateModalOpen);
            modalShow(
                onLdapCreateSuccessfullModalOpen,
                setOnLdapCreateSuccessfullModalOpen
            );
        }
    }, [ldapServiceCreated]);

    const onOkHandler = (): any => {
        dispatch(createLdapConfiguratorSettings(ldapTestSuccess));
    };

    let component: ReactNode = <CreateLdapConfigurationSetting form={form} />;

    switch (createLdapProgressCount) {
        case 2:
            component = <CreateLdapPeriodicFetch form={form} />;
            break;
        default:
            component = <CreateLdapConfigurationSetting form={form} />;
            break;
    }

    useEffect(() => {
        dispatch(getDaysList());
        dispatch(getLdapTypeOfIdentityList());
    }, []);

    const onTestLdapConfiguratorHandler = (): any => {
        dispatch(
            testLdapConfigurator({
                id:
                    ldapState === LDAPConfiguratorState.addLdapConfigurator
                        ? null
                        : getLdapServiceConfiguratorDetailsById?.id,
                serviceName: ldapServiceName,
                typeOfIdentity: typeOfIdentity,
                server: ldapUrl,
                port: port,
                baseDn: rootDomainField,
                bindDn: bindUserName,
                bindPassword: bindPassword,
                searchFilter: searchFilter,
                searchScope: searchScope,
                isActivated: true,
            })
        );
    };

    useEffect(() => {
        if (
            getLdapServiceConfiguratorDetailsById &&
            ldapState !== LDAPConfiguratorState.addLdapConfigurator
        ) {
            form.setFieldsValue({
                serviceName: getLdapServiceConfiguratorDetailsById.serviceName,
                typeOfIdentity:
                    getLdapServiceConfiguratorDetailsById?.identityTypeId,
                server: getLdapServiceConfiguratorDetailsById?.server,
                port: getLdapServiceConfiguratorDetailsById?.port,
                baseDn: getLdapServiceConfiguratorDetailsById?.baseDn,
                bindDn: getLdapServiceConfiguratorDetailsById?.bindDn,
                bindPassword:
                    getLdapServiceConfiguratorDetailsById?.bindPassword,
                searchFilter:
                    getLdapServiceConfiguratorDetailsById?.searchFilter,
                searchScope: getLdapServiceConfiguratorDetailsById?.searchScope,
            });
        }
    }, [getLdapServiceConfiguratorDetailsById, form, ldapState]);
    const getTitle = (): any => {
        if (ldapState === LDAPConfiguratorState.ldapConfiguratorView) {
            return 'View LDAP Integration'; // Note: will implement localization after search api intetragiton
        } else if (ldapState === LDAPConfiguratorState.ldapConfiguratorEdit) {
            return 'Edit LDAP Integration'; // Note: will implement localization after search api intetragiton
        } else {
            return 'Create LDAP Integration'; // Note: will implement localization after search api intetragiton
        }
    };

    useEffect(() => {
        if (ldapTestSuccess.isConnected) {
            message.success('Test Connected Succesfully');
        }
        if (ldapTestSuccess.isConnected === false) {
            message.error('Test Connected Fail');
        }
    }, [Object.keys(ldapTestSuccess).length > 0, ldapState]);

    const onCancelClickHandler = (): any => {
        if (ldapState === LDAPConfiguratorState.ldapConfiguratorEdit) {
            modalShow(onLdapCancelModalOpen, setOnLdapCancelModalOpen);
        } else {
            dispatch(setLdapState(LDAPConfiguratorState.tableView));
        }
    };
    const [isSaveDisable, setIsSaveDisable] = useState(false);

    useEffect(() => {
        const formValuesMatch =
            ldapTestSuccess.serviceName === ldapServiceName &&
            ldapTestSuccess.server === ldapUrl &&
            ldapTestSuccess.port === port &&
            ldapTestSuccess.baseDn === rootDomainField &&
            ldapTestSuccess.bindDn === bindUserName &&
            ldapTestSuccess.bindPassword === bindPassword &&
            ldapTestSuccess.searchFilter === searchFilter &&
            ldapTestSuccess.searchScope === searchScope &&
            ldapTestSuccess.typeOfIdentity === typeOfIdentity;

        if (ldapState === LDAPConfiguratorState.addLdapConfigurator) {
            setIsSaveDisable(!ldapTestSuccess.isConnected);
        } else if (ldapState === LDAPConfiguratorState.ldapConfiguratorEdit) {
            setIsSaveDisable(!(formValuesMatch && ldapTestSuccess.isConnected));
        }
    }, [
        ldapTestSuccess,
        ldapServiceName,
        ldapUrl,
        port,
        rootDomainField,
        bindUserName,
        bindPassword,
        searchFilter,
        searchScope,
        typeOfIdentity,
        ldapState,
    ]);

    return (
        <>
            <div className="attributeWrapper adapterList">
                <Card bordered={false}>
                    <Row className="adapterList__header">
                        <Col className="adapterList__backIcon" span={0.5}>
                            <BackIcon
                                onClick={() => {
                                    onBackClickHandler();
                                }}
                            />
                        </Col>
                        <Col
                            className="adapterList__title fw-400 fs-16"
                            span={20}
                        >
                            {getTitle()}
                        </Col>

                        <Col span={2} className="viewEdit">
                            {createLdapProgressCount === 2 ? (
                                <Button
                                    type="primary"
                                    ghost
                                    className="addNewButton"
                                    onClick={() => {}}
                                >
                                    {t('ldapConfigurator.fetchNowBtn')}
                                </Button>
                            ) : (
                                <>
                                    <div className="ldapConfiguratorView__btn">
                                        {ldapState ===
                                            LDAPConfiguratorState.ldapConfiguratorView && (
                                            <Button
                                                type="primary"
                                                ghost
                                                className="addNewButton"
                                                onClick={() => {
                                                    dispatch(
                                                        setLdapState(
                                                            LDAPConfiguratorState.ldapConfiguratorEdit
                                                        )
                                                    );
                                                }}
                                            >
                                                {t('ldapConfigurator.editBtn')}
                                            </Button>
                                        )}
                                        <Button
                                            type="primary"
                                            ghost
                                            className="addNewButton"
                                            disabled={isTestLoginDisable}
                                            onClick={() => {
                                                onTestLdapConfiguratorHandler();
                                            }}
                                        >
                                            {t('ldapConfigurator.testLoginBtn')}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Col>
                        {/* // Note : will use later */}
                        {/* <Col span={5}>
                            <RoleProgress
                                count={createLdapProgressCount}
                                screen={screenName.ldap}
                                totalCount={2}
                            />
                        </Col> */}
                    </Row>
                    <Form
                        form={form}
                        layout="vertical"
                        disabled={
                            ldapState ===
                            LDAPConfiguratorState.ldapConfiguratorView
                        }
                    >
                        <div
                            className={
                                'adapterList__content addMapping formLdap'
                            }
                        >
                            {component}
                        </div>
                        <div className="adapterList__footer addMapping">
                            <div className="adapterList__buttons ldap">
                                <CustomButton
                                    type={t('ldapConfigurator.CancelBtn')}
                                    disabled={false}
                                    handleClick={() => {
                                        onCancelClickHandler();
                                    }}
                                />
                                {createLdapProgressCount === 2 ? (
                                    <CustomButton
                                        type={t('ldapConfigurator.SaveBtn')}
                                        disabled={false}
                                        handleClick={() => {}}
                                    />
                                ) : (
                                    <CustomButton
                                        type={t('ldapConfigurator.SaveBtn')}
                                        disabled={
                                            ldapState ===
                                                LDAPConfiguratorState.ldapConfiguratorView ||
                                            isSaveDisable
                                        }
                                        handleClick={() => {
                                            onSaveNextClickHandler();
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </Form>
                </Card>
            </div>
            <ConfirmationModal
                open={onLdapCreateModalOpen}
                onCancel={() => {
                    cancelHandle(
                        onLdapCreateModalOpen,
                        setOnLdapCreateModalOpen
                    );
                }}
                onOk={() => onOkHandler()}
                icon={<ConfirmationIcon />}
                text={t('ldapConfigurator.confirmationMessage')}
            />
            <SuccessfulModal
                open={onLdapCreateSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={t('ldapConfigurator.successMessage')}
            />
            <ConfirmationModal
                open={onLdapCancelModalOpen}
                onCancel={() => {
                    cancelHandle(
                        onLdapCancelModalOpen,
                        setOnLdapCancelModalOpen
                    );
                }}
                onOk={() =>
                    dispatch(setLdapState(LDAPConfiguratorState.tableView))
                }
                icon={<ConfirmationIcon />}
                text={t('ldapConfigurator.cancelConfirmationMessage')}
            />
        </>
    );
};

export default CreateLdap;

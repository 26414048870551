import * as types from "redux/types/ldapConfiguratorTypes";

export const setLdapState = (payload: any): any => ({
  type: types.SET_LDAP_CONFIGURATOR_STATE,
  payload: payload,
});

export const getLdapServiceList = (payload?: any): any => ({
  type: types.GET_LDAP_SERVICE_LIST,
  payload: payload,
});

export const createLdapConfiguratorSettings = (payload: any): any => ({
  type: types.CREATE_LDAP_CONFIGURATOR_SETTINGS,
  payload: payload,
});

export const getLdapUsers = (payload: any): any => ({
  type: types.GET_LDAP_USERS_LIST,
  payload: payload,
});

export const getLdapTypeOfIdentityList = (): any => ({
  type: types.GET_LDAP_TYPE_OF_IDENTITY_LIST,
});

export const testLdapConfigurator = (payload: any): any => ({
  type: types.TEST_LDAP_CONFIGURATOR,
  payload: payload,
});

export const getLdapServiceConfiguratorDetailsById = (payload: any): any => ({
  type: types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID,
  payload: payload,
});

export const getLdapCountList = (): any => ({
  type: types.GET_LDAP_COUNT,
});

export const deleteLdapService = (payload: any): any => ({
  type: types.DELETE_LDAP_SERVICE,
  payload: payload,
});

export const clearLdapServiceStatus = (): any => ({
  type: types.CLEAR_LDAP_SERVICE_STATUS,
});

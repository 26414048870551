import React from 'react';
import { Card, Col, Row } from 'antd';
import './index.scss';
import AlertTableComponent from './AlertTableComponent';

const AlertConfigurator: React.FC = () => {
    return (
        <>
            <div className="configure">
                <Row className="Container__tabswrapper fw-500 fs-16">
                    <Col span={24} className="tableSection">
                        <div className="alertWrapper">
                            <Card bordered={false}>
                                <>
                                    <AlertTableComponent />
                                </>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AlertConfigurator;

import { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import TabsComponent from 'components/common/TabsComponent';
import CountAnalytics from 'components/common/CountAnalytics';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/tick-circle-green.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/error.svg';
import './index.scss';
// import { useTranslation } from 'react-i18next';
import { hasTabPermission } from 'utils/commonFunction';
import QRCodeLogs from './QRCodeLogs';
import QRCodeConfig from './QRCodeConfig';
import { QRConfigState } from 'types/enums/qrCodeGeneration';
import AddQRConfig from './QRCodeConfig/AddQRConfig';

const QRCodeGeneration: React.FC<{ activate: any }> = ({ activate }): any => {
    const navigate = useNavigate();

    // const { t } = useTranslation('translation');
    const { currentTab = 'config' } = useParams();

    const [activeTabKey, setActiveTabKey] = useState<any>(currentTab);

    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const qrCodeConfigState = useSelector(
        (state: any) => state.qrCodeGeneration.qrCodeConfig?.qrConfigState
    );
    const qrCodeConfigListTableList = useSelector(
        (state: any) => state.qrCodeGeneration?.qrCodeConfig?.qrConfigListData
    );

    useEffect(() => {
        if (currentTab) {
            setActiveTabKey(currentTab);
        }
    }, [currentTab]);
    useEffect(() => {
        navigate(`/configure/qr-code/${activeTabKey}`, {
            replace: true,
        });
    }, [activeTabKey]);
    const tabItems = [
        {
            id: 1,
            permissionKey: 'QRCodeConfig',
            key: 'config',
            label: 'QR Code Config',
            children: [
                QRConfigState.add,
                QRConfigState.view,
                QRConfigState.edit,
            ]?.includes(qrCodeConfigState) ? (
                <AddQRConfig />
            ) : (
                <QRCodeConfig />
            ),
        },
        {
            id: 2,
            permissionKey: 'QRCodeLogs',
            key: 'logs',
            label: 'QR Code Logs',
            children: <QRCodeLogs />,
        },
    ];

    const getCountAnalyticsDetails = (): any => {
        switch (activeTabKey) {
            case 'QRCodeLogs':
                return [
                    {
                        title: 'Active',
                        count: qrCodeConfigListTableList?.data?.activeConfigs || '--',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive',
                        count: qrCodeConfigListTableList?.data?.inactiveConfigs || '--',
                        icon: <DeactivateUserIcon />,
                    },
                ];

            case 'QRCodeConfig':
                return [
                    {
                        title: 'Active',
                        count: qrCodeConfigListTableList?.data?.activeConfigs || '--',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive',
                        count: qrCodeConfigListTableList?.data?.inactiveConfigs || '--',
                        icon: <DeactivateUserIcon />,
                    },
                ];
            default:
                return [
                    {
                        title: 'Active',
                        count: qrCodeConfigListTableList?.data?.activeConfigs || '--',
                        icon: <ActiveUserIcon />,
                    },
                    {
                        title: 'Inactive',
                        count: qrCodeConfigListTableList?.data?.inactiveConfigs || '--',
                        icon: <DeactivateUserIcon />,
                    },
                ];
        }
    };

    return (
        <>
            <div className="qrCodeGeneration">
                <Row className="qrCodeGeneration__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={'QR Code'}
                            subHeading={'You can create, view and edit QR here.'}
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails()}
                        />
                    </Col>
                </Row>
                <Row className="qrCodeGeneration__tabswrapper fw-500 fs-16">
                    <Col span={24}>
                        <TabsComponent
                            tabItem={
                                loggedInUserDetails?.admin
                                    ? tabItems
                                    : hasTabPermission(tabItems, allowedTabList)
                            }
                            setTabKey={activate}
                            setActiveTabKey={setActiveTabKey}
                            activeTabKey={activeTabKey}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default QRCodeGeneration;

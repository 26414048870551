import React, { useEffect } from 'react';
import './index.scss';
import { Row, Col, Drawer, Button, Form, Input, Divider } from 'antd';

import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { generateQRCode } from 'redux/actions/QRCodeGenerationActions/qrCodeConfig';
import { RESET_CONGIF_STATES } from 'redux/types/qrCodeTypes';

const GenerateQRCode: React.FC<any> = ({ open, setDrawerOpen, onClose, selectedFields }) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const qrCodeGenerated = useSelector(
        (state: any) => state.qrCodeGeneration.qrCodeConfig?.qrCodeGenerated
    );
    useEffect(() => {
        qrCodeGenerated && dispatch({ type: RESET_CONGIF_STATES}) && onClose();
    }, [qrCodeGenerated])
    return (
        <>
            <Drawer
                className="editBlaDrawer printQR"
                placement="right"
                getContainer={false}
                size="large"
                closable={false}
                open={open}
                onClose={onClose}
            >
                <Row>
                    <Col span={22}>
                        <Row>
                            <Col
                                span={24}
                                className="editBlaDrawer__heading fw-500 fs-18"
                            >
                                Generate QR Code
                            </Col>
                            
                        </Row>
                    </Col>
                    <Col span={1}>
                        <Button
                            className="editBlaDrawer__icon"
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        ></Button>
                    </Col>
                </Row>
                <Form
                    form={form}
                    layout="vertical"
                    className="printQRForm"
                >
                    {selectedFields?.fields?.map((item: any, index: number) => (
                        <Row key={item?.rank}>
                            <Col span={20} className='qrGenerateField'>
                                <Form.Item
                                    name={`Field ${item?.rank}`}
                                    label={`Field ${item?.rank}`}
                                    rules={[{ required: true }]}
                                >
                                    <Input
                                        placeholder={`${item?.fieldName}`}
                                        maxLength={item?.maxCharLength}
                                    />
                                </Form.Item>
                            </Col>
                            {index + 1 < selectedFields?.fields?.length &&
                                <Col span={1} className='qrGenerateSeparator'>
                                    {selectedFields?.separator}
                                </Col>
                            }
                        </Row>
                    ))}
                </Form>
                    <div className="button">
                        <Divider />
                        <Row className="generateQRFooterButtons">
                            <Col span={8}>
                                <CustomButton
                                    type={"Cancel"}
                                    disabled={false}
                                    handleClick={() => {
                                        onClose();
                                    }}
                                />
                            </Col>
                            <Col span={8}>
                                    <CustomButton
                                        type={"Generate"}
                                        disabled={false}
                                        typeOfButton={'submit'}
                                        handleClick={() => {
                                            dispatch(generateQRCode({
                                                metadataConfigId: selectedFields?._id,
                                                fields: Object.values(form.getFieldsValue()),
                                            }));
                                        }}
                                    />
                            </Col>
                        </Row>
                    </div>
            </Drawer>
        </>
    );
};

export default GenerateQRCode;

import { message } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { createQRConfigService, generateQRCodeService, getDatatypeListService, getImageTypeListService, getQRConfigDetailsService, getQRConfigListServices, setQRConfigStatusService, updateQRConfigService } from "redux/services/QRCodeGenerationServices/qrCodeConfigServices";
import * as types from "redux/types/qrCodeTypes";

export function* getDatatypeListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getQRDatatypeList = yield call(getDatatypeListService, payload);
    yield put({
      type: types.GET_QR_DATATYPE_SUCCESS,
      response: getQRDatatypeList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_QR_DATATYPE_FAILURE
    });
  }
}

export function* getImageTypeListSaga({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }): Generator<any> {
    try {
      const getImageTypeList = yield call(getImageTypeListService, payload);
      yield put({
        type: types.GET_IMAGE_TYPE_SUCCESS,
        response: getImageTypeList,
      });
    } catch (error: any) {
      yield put({
        type: types.GET_IMAGE_TYPE_FAILURE
      });
    }
  }

export function* createQRConfigSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const qrConfigCreated = yield call(createQRConfigService, payload);
        yield put({
            type: types.CREATE_QR_CONFIG_SUCCESS,
            response: qrConfigCreated,
        });
        message.success("QR Config created successfully")
    } catch (error: any) {
        yield put({ type: types.CREATE_QR_CONFIG_FAILURE });
    }
}

export function* getQRConfigListSaga({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }): Generator<any> {
    try {
      const getConfigList = yield call(getQRConfigListServices, payload);
      yield put({
        type: types.GET_QR_CONFIG_LIST_SUCCESS,
        response: getConfigList,
      });
    } catch (error: any) {
      yield put({
        type: types.GET_QR_CONFIG_LIST_FAILURE
      });
    }
  }

export function* generateQRCodeSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const qrCodeGenerated = yield call(generateQRCodeService, payload);
        yield put({
            type: types.GENERATE_QR_CODE_SUCCESS,
            response: qrCodeGenerated,
        });
        message.success("QR Code generated successfully")
    } catch (error: any) {
        yield put({ type: types.GENERATE_QR_CODE_FAILURE });
    }
}

export function* getQRConfigDetailSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const qrConfigDetails = yield call(getQRConfigDetailsService, payload);
        yield put({
            type: types.GET_QR_CONFIG_DETAILS_SUCCESS,
            response: qrConfigDetails,
        });
    } catch (error: any) {
        yield put({ type: types.GET_QR_CONFIG_DETAILS_FAILURE });
    }
}

export function* updateQrConfigSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const qrCodeUpdated = yield call(updateQRConfigService, payload);
        yield put({
            type: types.UPDATE_QR_CONFIG_SUCCESS,
            response: qrCodeUpdated,
        });
        message.success("QR Code updated successfully")
    } catch (error: any) {
        yield put({ type: types.UPDATE_QR_CONFIG_FAILURE });
    }
}

export function* setQRConfigStatusSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const qrConfigStatus = yield call(setQRConfigStatusService, payload);
        yield put({
            type: types.SET_QR_CONFIG_STATUS_SUCCESS,
            response: qrConfigStatus,
        });
    } catch (error: any) {
        yield put({ type: types.SET_QR_CONFIG_STATUS_FAILURE });
    }
}

export function* qrCodeConfigSaga(): any {
  yield all([takeLatest(types.GET_QR_DATATYPE, getDatatypeListSaga)]); 
  yield all([takeLatest(types.GET_IMAGE_TYPE, getImageTypeListSaga)]); 
  yield all([takeLatest(types.CREATE_QR_CONFIG, createQRConfigSaga)]);
  yield all([takeLatest(types.GET_QR_CONFIG_LIST, getQRConfigListSaga)]);
  yield all([takeLatest(types.GENERATE_QR_CODE, generateQRCodeSaga)]);
  yield all([takeLatest(types.GET_QR_CONFIG_DETAILS, getQRConfigDetailSaga)]);
  yield all([takeLatest(types.UPDATE_QR_CONFIG, updateQrConfigSaga)]);
  yield all([takeLatest(types.SET_QR_CONFIG_STATUS, setQRConfigStatusSaga)]);
}

import {
    Button,
    Card,
    Checkbox,
    Col,
    Divider,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Table,
} from 'antd';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import {
    createQRConfig,
    setQRConfigState,
    updateQRConfig,
} from 'redux/actions/QRCodeGenerationActions/qrCodeConfig';
import {
    QRConfigFormFields,
    QRConfigState,
} from 'types/enums/qrCodeGeneration';
import './index.scss';
import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIconTable.svg';
import { ReactComponent as ListingIcon } from 'assets/icons/verticalListIcon.svg';
import { ReactComponent as QuestionMarkIcon } from 'assets/icons/questionMark.svg';
import { useEffect, useState } from 'react';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { modalShow } from 'utils/modalFunction';
import { RESET_CONGIF_STATES } from 'redux/types/qrCodeTypes';
import { getTableDetails } from 'redux/actions/ConfigureActions/tableAction';

const AddQRConfig = (): any => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { TextArea } = Input;
    const [count, setCount] = useState(1);
    const [dataSource, setDataSource] = useState<any>([
        {
            key: 0,
            fieldName: null,
            dataType: null,
            isUnique: true,
            isRequired: true,
            charLength: null,
        },
    ]);
    const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
    const qrCodeConfigState = useSelector(
        (state: any) => state.qrCodeGeneration.qrCodeConfig?.qrConfigState
    );
    const dataTypeList = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrDatatype
    );
    const imageTypeList = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrImageType
    );
    const qrConfigCreated = useSelector(
        (state: any) => state.qrCodeGeneration.qrCodeConfig?.qrConfigCreated
    );
    const qrConfigDetails = useSelector(
        (state: any) => state.qrCodeGeneration.qrCodeConfig?.qrConfigDetails
    );
    const tableData = useSelector(
        (state: any) => state.configure?.table?.tableList
    );
    const tableDetails = useSelector(
        (state: any) => state.configure?.table?.tableDetails
    );
    const qrConfigUpdated = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrConfigUpdated
    );
    useEffect(() => {
        (qrConfigCreated || qrConfigUpdated) &&
            dispatch(setQRConfigState(QRConfigState.list)) &&
            dispatch({ type: RESET_CONGIF_STATES });
    }, [qrConfigCreated, qrConfigUpdated]);

    const name = Form.useWatch(QRConfigFormFields.name, form);
    const description = Form.useWatch(QRConfigFormFields.description, form);
    const imgType = Form.useWatch(QRConfigFormFields.imgType, form);
    const width = Form.useWatch(QRConfigFormFields.width, form);
    const configType = Form.useWatch(QRConfigFormFields.configType, form);
    const label = Form.useWatch(QRConfigFormFields.label, form);
    const table = Form.useWatch(QRConfigFormFields.table, form);
    const separator = Form.useWatch(QRConfigFormFields.separator, form);

    useEffect(() => {
        table && dispatch(getTableDetails(table));
        if (tableDetails?.columnList?.length && table) {
            setDataSource([
                {
                    key: 0,
                    fieldName: null,
                    dataType: null,
                    isUnique: true,
                    isRequired: true,
                    charLength: null,
                },
            ]);
            setCount(1);
        }
    }, [table]);

    useEffect(() => {
        if (
            (qrCodeConfigState === QRConfigState.view ||
                qrCodeConfigState === QRConfigState.edit) &&
            qrConfigDetails?.data
        ) {
            form.setFieldsValue({
                name: qrConfigDetails?.data?.name,
                description: qrConfigDetails?.data?.description,
                imgType: qrConfigDetails?.data?.qrCodeConfig?.type,
                width: qrConfigDetails?.data?.qrCodeConfig?.width,
                separator: qrConfigDetails?.data?.separator,
                configType: qrConfigDetails?.data?.generationType,
                label: qrConfigDetails?.data?.label?.join(),
                color: 'Dark',
                table: parseInt(qrConfigDetails?.data?.table?.id),
            });
            form.getFieldsValue() &&
                Object.keys(form.getFieldsValue())?.map((item: any) => {
                    item?.includes('charLength') &&
                        form.resetFields([`${item}`]);
                });
            const tableFields = qrConfigDetails?.data?.fields?.map(
                (item: any, index: number) => {
                    form.setFieldsValue({
                        [`${index}_charLength`]: item?.maxCharLength,
                    });
                    return {
                        key: index,
                        fieldName: item?.fieldName,
                        dataType: item?.dataType,
                        isUnique: item?.isUnique,
                        charLength: item?.maxCharLength,
                        isRequired: true,
                    };
                }
            );
            setDataSource(tableFields);
            setCount(tableFields?.length);
        }
    }, [qrConfigDetails?.data, form]);

    const handleConfirmationModalYes = (): any => {
        form.submit();
        setIsSaveModalOpen(false);
    };
    const handleKeyDown = (event: any): void => {
        // Allow control keys such as backspace, tab, delete, and arrow keys
        const controlKeys = [
            'Backspace',
            'Tab',
            'Delete',
            'ArrowLeft',
            'ArrowRight',
        ];
        if (
            (event.key >= '0' && event.key <= '9') || // Allow number keys
            controlKeys.includes(event.key) || // Allow control keys
            (event.key === '.' && !event.target.value.includes('.')) // Allow a single decimal point
        ) {
            // Do nothing, allow the key
        } else {
            event.preventDefault();
        }
    };
    const handleFieldNameChange = (e: any, record: any): any => {
        const inputValue = e.target.value;

        // Regular expression to allow letters, numbers, spaces, underscores, hyphens, and special characters
        const regex = /^[\w\s-!@#$%^&*()_+={}\\[\]:;"'<>,.?/]*$/;

        // Check if the input matches the regex
        if (regex.test(inputValue)) {
            const newData = dataSource?.map((item: any) => {
                if (item?.key === record?.key) {
                    return {
                        ...item,
                        fieldName: inputValue, // Update only if the input is valid
                    };
                }
                return item;
            });
            setDataSource(newData);
        }
    };

    const columns = [
        {
            title: '',
            dataIndex: 'icon',
            key: 'icon',
            width: 50,
            render: (text: any, record: any) => (
                <ListingIcon style={{ marginRight: 8 }} />
            ),
        },
        {
            title: 'Field Name',
            dataIndex: 'columnName',
            key: 'columnName',
            width: '25%',
            className: 'columnName',
            render: (text: any, record: any) => (
                <>
                    {configType !== 'System Generated' ? (
                        <Input
                            placeholder={'Enter Field Name'}
                            disabled={qrCodeConfigState === QRConfigState.view}
                            value={record?.fieldName}
                            onChange={(e) => {
                                handleFieldNameChange(e, record);
                            }}
                        />
                    ) : (
                        <Select
                            key={`${tableDetails?.columnList?.length}`}
                            placeholder={'Select Field Name'}
                            disabled={
                                !tableDetails?.columnList?.length ||
                                qrCodeConfigState === QRConfigState.view
                            }
                            options={tableDetails?.columnList?.map(
                                (item: any) => {
                                    return {
                                        label: item?.name,
                                        value: item?.name,
                                    };
                                }
                            )}
                            onChange={(value) => {
                                const newData = dataSource?.map((item: any) => {
                                    if (item?.key === record?.key) {
                                        return { ...item, fieldName: value };
                                    }
                                    return item;
                                });
                                setDataSource(newData);
                            }}
                            value={record?.fieldName}
                        />
                    )}
                </>
            ),
        },
        {
            title: 'Data Type',
            dataIndex: 'dataTypeId',
            key: 'dataType',
            width: '25%',
            className: 'dataType',
            render: (text: any, record: any) => (
                <Select
                    placeholder={'Data Type'}
                    options={dataTypeList?.map((item: any) => {
                        return {
                            label: item,
                            value: item,
                        };
                    })}
                    onChange={(value) => {
                        const newData = dataSource?.map((item: any) => {
                            if (item?.key === record?.key) {
                                return { ...item, dataType: value };
                            }
                            return item;
                        });
                        setDataSource(newData);
                    }}
                    value={record?.dataType}
                    disabled={
                        configType === 'System Generated' ||
                        qrCodeConfigState === QRConfigState.view
                    }
                />
            ),
        },
        {
            title: 'Character Length',
            dataIndex: 'length',
            key: 'length',
            width: '25%',
            className: 'length',
            render: (text: any, record: any) => (
                <Form.Item
                    name={`${record?.key}_charLength`}
                    className="charLenItem"
                    rules={[
                        {
                            type: 'number',
                            min: 1,
                            max: 20,
                            message: 'Number must be between 1 and 20',
                        },
                    ]}
                >
                    <InputNumber
                        placeholder={'Enter Length'}
                        onKeyDown={handleKeyDown}
                        controls={false}
                        onChange={(value) => {
                            const newData = dataSource?.map((item: any) => {
                                if (item?.key === record?.key) {
                                    return {
                                        ...item,
                                        charLength: value && parseInt(value),
                                    };
                                }
                                return item;
                            });
                            setDataSource(newData);
                        }}
                        value={record?.charLength}
                        defaultValue={
                            configType === 'System Generated' ? 20 : ''
                        }
                        disabled={
                            configType === 'System Generated' ||
                            qrCodeConfigState === QRConfigState.view
                        }
                    />
                </Form.Item>
            ),
        },
        {
            title: 'Unique',
            dataIndex: 'unique',
            key: 'unique',
            width: '20%',
            className: 'unique',
            render: (text: any, record: any, index: any) => (
                <Checkbox
                    style={{ marginRight: 8 }}
                    disabled={
                        qrCodeConfigState === QRConfigState.view || !index
                    }
                    onChange={(e) => {
                        const newData = dataSource?.map((item: any) => {
                            if (item?.key === record?.key) {
                                return { ...item, isUnique: e.target.checked };
                            }
                            return item;
                        });
                        setDataSource(newData);
                    }}
                    checked={record?.isUnique}
                />
            ),
        },
        {
            title: 'Action',
            dataIndex: 'action',
            width: '15%',
            className: 'column__action',
            render: (_: any, record: any, index: any) =>
                index >= 1 && dataSource?.length >= 1 ? (
                    <Popconfirm
                        title="Sure to delete?"
                        disabled={qrCodeConfigState === QRConfigState.view}
                        onConfirm={() => handleDelete(record?.key)}
                    >
                        <a className="deleteicon">
                            <DeleteIcon />
                        </a>
                    </Popconfirm>
                ) : null,
        },
    ];

    const handleAdd = (): any => {
        const newData = {
            key: count,
            fieldName: null,
            dataType: null,
            isUnique: false,
            isRequired: true,
            charLength: null,
        };
        setDataSource([...dataSource, newData]);
        setCount(count + 1);
    };

    const onSaveConfigHandler = (): any => {
        const fieldData =
            configType === 'System Generated'
                ? dataSource?.map((item: any, index: any) => {
                      return {
                          rank: index + 1,
                          fieldName: item?.fieldName,
                          isUnique: item?.isUnique,
                          isRequired: true,
                      };
                  })
                : dataSource?.map((item: any, index: any) => {
                      return {
                          rank: index + 1,
                          fieldName: item?.fieldName,
                          dataType: item?.dataType,
                          isUnique: item?.isUnique,
                          isRequired: true,
                          minCharLength: 1,
                          maxCharLength: item?.charLength,
                      };
                  });
        if (qrCodeConfigState === QRConfigState.add) {
            dispatch(
                createQRConfig({
                    name: name,
                    description: description,
                    generationType: configType,
                    separator: separator,
                    numberOfFields: fieldData?.length,
                    type: 'test',
                    fields: [...fieldData],
                    table:
                        configType === 'System Generated'
                            ? table.toString()
                            : '',
                    label: [label],
                    qrCodeConfig: {
                        type: imgType,
                        width: parseInt(width),
                        color: {
                            dark: '#000000',
                            light: '#FFFFFF',
                        },
                    },
                })
            );
        } else if (qrCodeConfigState === QRConfigState.edit) {
            dispatch(
                updateQRConfig({
                    id: qrConfigDetails?.data?._id,
                    name: name,
                    description: description,
                    generationType: configType,
                    separator: separator,
                    numberOfFields: fieldData?.length,
                    type: 'test',
                    fields: [...fieldData],
                    table:
                        configType === 'System Generated'
                            ? table.toString()
                            : '',
                    label: [label],
                    qrCodeConfig: {
                        type: imgType,
                        width: parseInt(width),
                        color: {
                            dark: '#000000',
                            light: '#FFFFFF',
                        },
                    },
                })
            );
        }
    };

    const handleDelete = (key: any): any => {
        const newData = dataSource?.filter((item: any) => item.key !== key);
        setDataSource(newData);
    };
    return (
        <>
            <div className="attributeWrapper adapterList">
                <Card bordered={false}>
                    <Row className="adapterList__header">
                        <Col className="adapterList__backIcon" span={0.5}>
                            <BackIcon
                                onClick={() => {
                                    dispatch(
                                        setQRConfigState(QRConfigState.list)
                                    );
                                }}
                            />
                        </Col>
                        <Col
                            className="adapterList__title fw-400 fs-16"
                            span={18}
                        >
                            {qrCodeConfigState === QRConfigState.add
                                ? 'Create New QR Code Config'
                                : qrConfigDetails?.data?.name}
                        </Col>
                        {qrCodeConfigState === QRConfigState.view &&
                            qrConfigDetails?.data?.status === 'active' && (
                                <Col span={5} className="viewEdit">
                                    <Button
                                        type="primary"
                                        ghost
                                        className="addNewButton"
                                        onClick={() => {
                                            dispatch(
                                                setQRConfigState(
                                                    QRConfigState.edit
                                                )
                                            );
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Col>
                            )}
                    </Row>
                    <Form
                        form={form}
                        layout="vertical"
                        className="addQRConfigWrapper"
                        disabled={qrCodeConfigState === QRConfigState.view}
                        onFinish={onSaveConfigHandler}
                    >
                        <Divider>Basic Information</Divider>
                        <Row
                            gutter={10}
                            className="addQRConfigWrapper__basicInfo"
                        >
                            <Col span={5}>
                                <Form.Item
                                    name="name"
                                    label={<span>Enter QR Name</span>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Enter QR Name',
                                        },
                                        {
                                            pattern:
                                                /^[\w\s-!@#$%^&*()_+={}\\[\]:;"'<>,.?/]*$/, // Allow letters, numbers, spaces, underscores, hyphens, and special characters
                                            message:
                                                'Only letters, numbers, spaces, underscores, hyphens, and special characters are allowed.',
                                        },
                                        {
                                            validator: async (_, value) => {
                                                if (
                                                    value &&
                                                    value.trimStart().length ===
                                                        0
                                                ) {
                                                    return await Promise.reject(
                                                        new Error(
                                                            'Name cannot start with a space.'
                                                        )
                                                    );
                                                }
                                                await Promise.resolve();
                                            },
                                        },
                                    ]}
                                >
                                    <Input placeholder={'Enter QR Name'} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item
                                    name="description"
                                    label={<span>Description</span>}
                                >
                                    <TextArea
                                        placeholder={
                                            'Enter your description here'
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="label"
                                    label={<span>Label</span>}
                                >
                                    <Input placeholder={'Enter label'} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>QR Code Configuration</Divider>
                        <Row
                            gutter={10}
                            className="addQRConfigWrapper__configuration"
                        >
                            <Col span={5}>
                                <Form.Item
                                    name="imgType"
                                    label={<span>QR Code Image Type</span>}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        placeholder={'Select Image Type'}
                                        options={imageTypeList?.map(
                                            (item: any) => {
                                                return {
                                                    label: item,
                                                    value: item,
                                                };
                                            }
                                        )}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="width"
                                    label={<span>Width (px)</span>}
                                    rules={[
                                        { required: true },
                                        {
                                            type: 'number',
                                            min: 100,
                                            max: 2000,
                                            message:
                                                'Number must be between 100 and 2000',
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        placeholder={'Enter Width'}
                                        onKeyDown={handleKeyDown}
                                        controls={false}
                                        min={1}
                                        max={10000}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="color"
                                    label={<span>Color Theme</span>}
                                >
                                    <Select
                                        placeholder={'Select Theme'}
                                        options={[
                                            {
                                                label: 'Dark',
                                                value: 'Dark',
                                            },
                                        ]}
                                        defaultValue={'Dark'}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Divider>Configuration Type</Divider>
                        <Row
                            gutter={10}
                            className="addQRConfigWrapper__configuration"
                        >
                            <Col span={5}>
                                <Form.Item
                                    name="configType"
                                    label={<span>QR Code Configuration</span>}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        placeholder={
                                            'Select QR Code Configuration'
                                        }
                                        options={[
                                            {
                                                label: 'Manual',
                                                value: 'Manual',
                                            },
                                            {
                                                label: 'System Generated',
                                                value: 'System Generated',
                                            },
                                        ]}
                                        onChange={() => {
                                            form.resetFields(['table']);
                                            dataSource?.map(
                                                (item: any, index: any) => {
                                                    form.resetFields([
                                                        `${index}_charLength`,
                                                    ]);
                                                }
                                            );
                                            setDataSource([
                                                {
                                                    key: 0,
                                                    fieldName: null,
                                                    dataType: null,
                                                    isUnique: true,
                                                    isRequired: true,
                                                    charLength: null,
                                                },
                                            ]);
                                            setCount(1);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    name="separator"
                                    label={<span>Separator</span>}
                                    rules={[{ required: true }]}
                                >
                                    <Select
                                        placeholder={'Select Separator'}
                                        options={[
                                            {
                                                label: '&',
                                                value: '&',
                                            },
                                            {
                                                label: '_',
                                                value: '_',
                                            },
                                            {
                                                label: '-',
                                                value: '-',
                                            },
                                            {
                                                label: '$',
                                                value: '$',
                                            },
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                            {configType === 'System Generated' && (
                                <Col span={5}>
                                    <Form.Item
                                        name="table"
                                        label={<span>Table</span>}
                                        rules={[{ required: true }]}
                                    >
                                        <Select
                                            placeholder={'Select Table'}
                                            options={tableData?.paginatedResponse?.records?.map(
                                                (item: any) => {
                                                    return {
                                                        label: item?.name,
                                                        value: item?.id,
                                                    };
                                                }
                                            )}
                                        />
                                    </Form.Item>
                                </Col>
                            )}
                            {/* <Col span={5}>
                            <Form.Item
                                name="label"
                                label={
                                    <span>
                                        Label
                                    </span>
                                }
                                rules={[{ required: true }]}
                            >
                                <Input></Input>
                            </Form.Item>
                        </Col> */}
                        </Row>
                        <Divider>Field Configuration</Divider>
                        <div className="addQRConfigWrapper__addButton">
                            <Button
                                onClick={handleAdd}
                                className="custom-add-button"
                                type="default"
                                style={{
                                    marginBottom: 16,
                                }}
                            >
                                Add Field
                            </Button>
                        </div>

                        <div className="addQRConfigWrapper__fieldTable">
                            <Table
                                dataSource={dataSource}
                                columns={columns}
                                pagination={false}
                                scroll={{ y: 'calc(100vh - 64vh)' }}
                            />
                        </div>
                    </Form>
                    <div className="qrConfigFooter__footer">
                        <div className="qrConfigFooter__buttons">
                            <CustomButton
                                type={'Cancel'}
                                disabled={false}
                                handleClick={() => {
                                    dispatch(
                                        setQRConfigState(QRConfigState.list)
                                    );
                                }}
                            />
                            <Button
                                type="primary"
                                disabled={
                                    (configType === 'Manual' &&
                                        dataSource?.some((obj: any) =>
                                            Object.values(obj)?.some(
                                                (value) => value === null
                                            )
                                        )) ||
                                    !name ||
                                    !configType ||
                                    !separator ||
                                    !imgType ||
                                    !width ||
                                    (configType === 'System Generated' &&
                                        !table) ||
                                    qrCodeConfigState === QRConfigState.view
                                }
                                onClick={() => {
                                    modalShow(
                                        isSaveModalOpen,
                                        setIsSaveModalOpen
                                    );
                                }}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                    {isSaveModalOpen && (
                        <ConfirmationModal
                            customClassName="confirmationModal attributeImplementationModal"
                            icon={<QuestionMarkIcon />}
                            open={isSaveModalOpen}
                            onOk={() => {
                                handleConfirmationModalYes();
                            }}
                            onCancel={() => {
                                setIsSaveModalOpen(false);
                            }}
                            text={
                                qrCodeConfigState === QRConfigState.add
                                    ? 'Are you sure you want to save the QR Config?'
                                    : 'Are you sure you want to update the QR Config?'
                            }
                        />
                    )}
                </Card>
            </div>
        </>
    );
};

export default AddQRConfig;

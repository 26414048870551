import { baseUrlSetter } from 'utils/commonFunction';
import Api from '..';
import { message } from 'antd';

export const getAuditTrailsService = async (payload: any): Promise<any> => {
    baseUrlSetter('audit');
    const params = {
        startTime: payload?.startTime,
        endTime: payload?.endTime,
        pageNumber: payload?.pageNumber,
        pageSize: payload?.pageSize,
        moduleId: payload?.moduleId,
        action: payload?.action?.toString(),
        userId: payload?.userId?.toString(),
        sortOrder: payload?.sortOrder,
        sortColumn: payload?.sortColumn,
        moduleVariableId: payload?.moduleVariableId?.toString(),
    };
    try {
        const response = Api.get(`/audit/trail/logs`, { params: params });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getModulesService = async (payload: any): Promise<any> => {
    baseUrlSetter('audit');
    try {
        const response = Api.get(`/audit/trail/list/module`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getModulesConfigurationService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('audit');
    try {
        const response = Api.get(
            `/audit/trail/list/variableName?moduleId=${payload}`
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

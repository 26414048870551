import { Col, Form, Input, Row, Select } from 'antd';
import Password from 'antd/es/input/Password';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const CreateLdapConfigurationSetting: React.FC<any> = ({ form }: any): any => {
    const { t } = useTranslation('translation');

    const { Option } = Select;
    const ldapTypeOfIdentityList = useSelector(
        (state: any) => state.ldapConfigurator?.ldapTypeOfIdentityList
    );

    return (
        <>
            <div className="ldapConfiguration">
                <Row gutter={10}>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.serviceName'
                            )}
                            name="serviceName"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.serviceName'
                                    ),
                                },
                                {
                                    max: 30,
                                    message: t(
                                        'UomConfigurator.errorMessage.maxlength'
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.serviceNamePlaceHolder'
                                )}
                                maxLength={30}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.typeOfIdentity'
                            )}
                            name="typeOfIdentity"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.typeOfIdentity'
                                    ),
                                },
                            ]}
                        >
                            <Select
                                popupClassName="adapter"
                                className="typeOfIdentity"
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.typeOfIdentityPlaceHolder'
                                )}
                            >
                                {ldapTypeOfIdentityList?.map((item: any) => {
                                    return (
                                        <Option key={item?.id} value={item?.id}>
                                            {item?.identity}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.ldapUrl'
                            )}
                            name="server"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.ldapUrl'
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.ldapUrlPlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.port'
                            )}
                            name="port"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.port'
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.portPlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.rootDomainField'
                            )}
                            name="baseDn"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.rootDomainField'
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.rootDomainFieldPlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.bindUserName'
                            )}
                            name="bindDn"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.bindUserName'
                                    ),
                                },
                            ]}
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.bindUserNamePlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.bindPassword'
                            )}
                            name="bindPassword"
                            rules={[
                                {
                                    required: true,
                                    message: t(
                                        'ldapConfigurator.CreateLdapConfigurationSettingForm.errorMessage.bindPassword'
                                    ),
                                },
                            ]}
                        >
                            <Password
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.bindPasswordPlaceHolder'
                                )}
                                className="ldapPassword"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.searchFilter'
                            )}
                            name="searchFilter"
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.searchFilterPlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={t(
                                'ldapConfigurator.CreateLdapConfigurationSettingForm.searchScope'
                            )}
                            name="searchScope"
                        >
                            <Input
                                placeholder={t(
                                    'ldapConfigurator.CreateLdapConfigurationSettingForm.searchScopePlaceHolder'
                                )}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default CreateLdapConfigurationSetting;

import React, { useEffect, useState } from 'react';
import { Select, Form, Input, Divider, Row, Col, Empty, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import { type GroupDrawerProps } from 'types/interfaces/PropsInterfaces/UserManagement/groupPropsInterface';
import CustomButton from 'components/common/CustomButton';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import {
    createGroup,
    editGroup,
    getGroupsList,
    getResourceByTypeByResourceId,
    getResourceType,
} from 'redux/actions/UserManagementActions/groupsAction';
import { useDispatch, useSelector } from 'react-redux';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useTranslation } from 'react-i18next';
import { getModelList } from 'redux/actions/DataExplorer/DataVisualizationActions';
import Listing from './listing';
import { EMPTY, resourceType } from 'types/enums';
import {
    CLOSE_GROUP_DRAWER,
    EDIT_BUTTON_CLICKED,
} from 'redux/types/userManagementTypes';
import { getTreeByModelId } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { EMPTY_TREE_OBJECT } from 'redux/types/assetModellingTypes';
import { getKpiList } from 'redux/actions/ConfigureActions/kpiActions';
const { Option } = Select;
const GroupDrawer: React.FC<GroupDrawerProps> = ({
    onClose,
    editData,
    formDisable,
    openEditDrawer,
    onEditClick,
    paginationPayload,
    selectedResourceTypeId,
}) => {
    const { t } = useTranslation('translation');
    const [form] = Form.useForm();
    const values = Form.useWatch([], form);
    const [expandedKpiKeys, setExpandedKpiKeys] = useState<any>([]);
    useEffect(() => {
        setExpandedKpiKeys([]);
    }, []);
    const resourceTypeValue = useSelector(
        (state: any) => state.userManagement.groups.resourceType
    );

    const [selectedOption, setSelectedOption] = useState<string>('');
    const option: any = resourceTypeValue?.find(
        (item: any) => item.resourceTypeName === values?.resourceType
    );
    const dataById = useSelector(
        (state: any) => state.userManagement.groups.dataByGroupId
    );
    const [selectedOptionData, setSelectedOptionData] =
        useState<string>(option);
    const resourceTypeById = useSelector(
        (state: any) => state.userManagement.groups.resourceTypeDataById
    );
    const [modelId, setModelId] = useState();
    useEffect(() => {
        if (selectedOptionData === resourceType.ASSET) {
            setModelId(dataById?.modelId);
        }
    }, [dataById, selectedOptionData]);

    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const selectedItems = (): any => {
        const selectedValues: any = [];
        editData?.resourceList?.map((item: any) => {
            selectedValues.push(item.resourceId);
        });
        return selectedValues;
    };
    useEffect(() => {
        dataById?.resourceType?.resourceTypeName === resourceType?.SCREEN &&
            setCheckedItems(selectedItems());
    }, [dataById]);
    useEffect(() => {
        if (dataById?.resourceType?.resourceTypeName === 'Asset') {
            const resourceIds = dataById?.resourceList?.map((resource: any) =>
                parseInt(resource?.resourceId)
            );

            setCheckedKeys(resourceIds);
        }
    }, [dataById]);

    useEffect(() => {
        if (dataById?.resourceType?.resourceTypeName === 'KPI') {
            const resourceKpis = dataById?.resourceList?.map(
                (resource: any) => ({
                    kpiId: resource?.resourceId,
                    nodeTypeId: resource?.nodeTypeId,
                })
            );

            setCheckedKpis(resourceKpis);
        }
    }, [dataById]);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getResourceType());
    }, []);

    const details = parseJwt();

    const onChange = (checkedValues: any): void => {};
    useEffect(() => {
        if (editData)
            dispatch(getResourceByTypeByResourceId(editData?.resourceType?.id));
    }, [editData]);

    useEffect(() => {
        if (editData) {
            setSelectedOptionData(editData?.resourceType?.resourceTypeName);
            selectedItems();
            if (editData?.resourceType?.resourceTypeName === 'Screen') {
                const allChecked =
                    editData?.resourceList?.length ===
                        resourceTypeById?.length &&
                    editData?.resourceList?.length > 0;
                const someChecked =
                    editData?.resourceList?.length > 0 && !allChecked;
                setCheckAll(() => allChecked);
                setIndeterminate(() => someChecked);
            } else {
                const totalKpisCount = (kpiDetails ?? [])?.reduce(
                    (count: number, node: any) => count + node?.kpis?.length,
                    0
                );
                const allChecked =
                    editData?.resourceList?.length ===
                        totalKpisCount &&
                    editData?.resourceList?.length > 0;
                const someChecked =
                    editData?.resourceList?.length > 0 && !allChecked;
                setCheckAll(() => allChecked);
                setIndeterminate(() => someChecked);
            }
        }
    }, [resourceTypeById, selectedOptionData, editData]);
    const [payload, setPayload] = useState({
        createdBy: details.username,
        updatedBy: details.username,
        id: 0,
        groupName: '',
        resourceType: {
            id: '',
            resourceTypeName: '',
        },
        resourceList: [
            {
                resourceId: '',
                resourceType: {
                    id: '',
                },
                nodeTypeId: '',
            },
        ],
        modelId: '',
    });

    const { groupName } = payload;

    const handleChange = (value: string): void => {
        setSelectedOptionData(value);
        setSelectedOption(value);
        setCheckedItems([]);
        setCheckedKeys([]);
        setSelectedModel(null);
        setCheckedKpis([]);
        setSearchValue(EMPTY?.string);
        form.setFieldsValue({
            model: null,
        });
    };
    useEffect(() => {
        if (selectedOptionData === resourceType.SCREEN) {
            dispatch({ type: EMPTY_TREE_OBJECT });
        }
    }, [selectedOptionData]);
    const handleSelectAll = (checked: boolean): void => {
        if (checked) {
            const updatedCheckedItems = resourceTypeById?.map(
                (item: any) => item?.resourceId
            );
            setCheckedItems(updatedCheckedItems);
        } else {
            setCheckedItems([]);
        }
        setIndeterminate(false);
        setCheckAll(checked);
    };

    const initialValues = {
        groupName: editData?.groupName || '',
        resourceType: editData?.resourceType?.resourceTypeName || '',
        id: editData?.id || '',
        model: editData?.modelId || '',
    };

    const editDrawer = (): void => {
        if (onEditClick) {
            onEditClick();
        }
        if (openEditDrawer) {
            openEditDrawer(true);
        }
    };

    const handleSubItemChange = (
        resourceId: string,
        checked: boolean
    ): void => {
        // LOGIC-- updatedCheckedItems contains copy of checkItems.If value of checked is true (i.e. if resourceItem is checked),
        // then its resourceId is appended in updatedCheckedItems & if the checked is false(i.e the resource item is unchecked).
        // In that case, it finds the index of resourceId in the updatedCheckedItems array using indexOf.
        // If the index is not -1 (means resourceId was found in the array),it removes the element at that index using the splice method.
        const updatedCheckedItems = checkedItems ? [...checkedItems] : [];
        if (checked) {
            updatedCheckedItems.push(resourceId);
        } else {
            const index = updatedCheckedItems.indexOf(resourceId);
            if (index !== -1) {
                updatedCheckedItems.splice(index, 1);
            }
        }

        setCheckedItems(updatedCheckedItems);
        // LOGIC-- Here checking if all the resource items are checked by comparing values of updatedCheckedItems.length and resourceTypeById.length , then assigning to variable- allChecked.
        // and if some resourceItems are checked then verifying that updatedCheckedItems has a length > 0 and allChecked is false, then assigning to variable someChecked.
        const allChecked =
            updatedCheckedItems?.length === resourceTypeById?.length;
        const someChecked = updatedCheckedItems?.length > 0 && !allChecked;

        setIndeterminate(someChecked);
        setCheckAll(allChecked);
    };
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [checkedKpis, setCheckedKpis] = useState<React.Key[]>([]);

    useEffect(() => {
        setPayload({
            ...payload,
            groupName: editData ? editData?.groupName : groupName,
            id: editData ? editData?.id : '',
            resourceType: {
                id: editData ? editData?.resourceType?.id : option?.id,
                resourceTypeName: editData
                    ? editData?.resourceType?.resourceTypeName
                    : option?.resourceTypeName,
            },
            modelId: editData ? editData?.modelId : '',
            resourceList: editData
                ? editData?.resourceList?.map((item: any) => ({
                      resourceId: item.resourceId,
                      resourceType: {
                          id: editData?.resourceType?.id,
                      },
                  }))
                : checkedItems?.length
                ? checkedItems?.map((item: any) => ({
                      resourceId: item,
                      resourceType: {
                          id: option?.id,
                      },
                  }))
                : checkedKeys?.length
                ? checkedKeys?.map((item: any) => ({
                      resourceId: item,
                      resourceType: {
                          id: option?.id,
                      },
                  }))
                : checkedKpis?.map((item: any) => ({
                      resourceId: item?.kpiId,
                      resourceType: {
                          id: option?.id,
                      },
                      nodeTypeId: item?.nodeTypeId,
                  })),
        });
    }, [checkedItems, editData, option, checkedKeys, checkedKpis]);

    const submitHandler = (values: any): any => {
        if (editData) {
            dispatch(
                editGroup({
                    createdBy: details?.username,
                    updatedBy: details?.username,
                    id: editData?.id,
                    groupName: values?.groupName,
                    resourceList: checkedItems?.length
                        ? checkedItems?.map((item: any) => ({
                              resourceId: item,
                              resourceType: {
                                  id: option?.id,
                              },
                          }))
                        : checkedKeys?.length
                        ? checkedKeys?.map((item: any) => ({
                              resourceId: item,
                              resourceType: {
                                  id: option?.id,
                              },
                          }))
                        : checkedKpis?.map((item: any) => ({
                              resourceId: item?.kpiId,
                              resourceType: {
                                  id: option?.id,
                              },
                              nodeTypeId: item?.nodeTypeId,
                          })),

                    resourceType: {
                        id: option?.id,
                        resourceTypeName: values?.resourceType,
                    },
                    modelId: values?.model,
                    paginationPayload: paginationPayload,
                })
            );

            dispatch({ type: CLOSE_GROUP_DRAWER });
            dispatch({ type: EMPTY_TREE_OBJECT });

            onClose();
        } else {
            dispatch(
                createGroup({
                    ...payload,
                    groupName: values.groupName,
                    paginationPayload: paginationPayload,
                    modelId: selectedModel ?? '',
                    selectedResourceTypeId: selectedResourceTypeId,
                })
            );
            dispatch({ type: CLOSE_GROUP_DRAWER });
            dispatch({ type: EMPTY_TREE_OBJECT });

            onClose();
        }
    };

    const subItems = (name: string, id: string): void => {
        dispatch(getResourceByTypeByResourceId(id));
    };
    const filteredItems = resourceTypeById.filter((item: any): void =>
        item.resourceName.toLowerCase().includes(searchValue.toLowerCase())
    );
    const modelList: any = useSelector(
        (state: any) => state.dataVisualization.modelList
    );

    useEffect(() => {
        selectedOptionData === resourceType.ASSET &&
            dispatch(getModelList({ page: 1, pageSize: 0 })); // NOTE- we have used 0,1 here to display the list of all models on 1 page

        selectedOptionData === resourceType.KPI &&
            dispatch(getKpiList({ page: -1, pageSize: 0, activeStatus: 1 }));
    }, [selectedOptionData]);

    const kpiData = useSelector(
        (state: any) => state.configure?.kpi?.kpiList?.pageResponse?.records
    );
    const [kpiDetails, setKpiDetails] = useState();
    const [selectedModel, setSelectedModel] = useState(null);

    useEffect(() => {
        const nodeTypes: any = [];

        for (let i = 0; i < kpiData?.length; i++) {
            const { nodeType } = kpiData[i];

            // Check if this nodeType.id is already in the nodeTypes array
            const nodeTypeExists = nodeTypes.some(
                (entry: any) => entry.nodeTypeId === nodeType.id
            );

            if (!nodeTypeExists) {
                // If not, add it to the nodeTypes array
                nodeTypes.push({
                    nodeTypeId: nodeType.id,
                    nodeTypeName: nodeType.name,
                    kpis: [], // Initialize an empty array to hold the KPIs
                });
            }
        }

        // Now, map over kpiData to assign KPIs to the appropriate nodeType
        kpiData?.forEach((kpi: any) => {
            const { nodeType, id: kpiId, name: kpiName } = kpi;

            // Find the matching nodeType entry in nodeTypes
            const nodeTypeEntry = nodeTypes.find(
                (entry: any) => entry.nodeTypeId === nodeType.id
            );

            // Add the current KPI details to the matching nodeTypeId
            if (nodeTypeEntry) {
                nodeTypeEntry.kpis.push({
                    kpiId,
                    kpiName,
                });
            }
        });

        // Set the state after all KPIs have been assigned
        setKpiDetails(nodeTypes);
    }, [kpiData]);

    useEffect(() => {
        modelId && dispatch(getTreeByModelId(modelId));
    }, [modelId]);

    useEffect(() => {
        selectedModel && dispatch(getTreeByModelId(selectedModel));
    }, [selectedModel]);
    useEffect(() => {
        const nodeTypeIds = Array.from(
            new Set(
                dataById?.resourceList?.map((resource: any) =>
                    String(resource?.nodeTypeId)
                )
            )
        );

        setExpandedKpiKeys(nodeTypeIds);
    }, [dataById]);

    useEffect(() => {
        selectedOptionData === resourceType.ASSET &&
            dispatch(getModelList({ page: 1, pageSize: 0 })); // NOTE- we have used 0,1 here to display the list of all models on 1 page
    }, [selectedOptionData]);
    const modelListOptions = (): any => {
        const result: any = [];
        modelList?.map((modelObject: any) => {
            result.push({
                label: modelObject?.name,
                value: modelObject?.id,
            });
        });
        return result;
    };
    const modelSelectHandler = (value: any): any => {
        setSelectedModel(value);
    };
    useEffect(() => {
        modelId && dispatch(getTreeByModelId(modelId));
    }, [modelId]);

    useEffect(() => {
        selectedModel && dispatch(getTreeByModelId(selectedModel));
    }, [selectedModel]);

    const handleSelectAllKpis = (checked: boolean): void => {
        if (checked) {
            const nestedKpis = (kpiDetails ?? [])?.map((nodeType: any) =>
                nodeType.kpis.map((kpi: any) => ({
                    kpiId: kpi?.kpiId,
                    nodeTypeId: nodeType?.nodeTypeId,
                }))
            );

            const allKpis = nestedKpis?.flat();

            setCheckedKpis(allKpis);
        } else {
            setCheckedKpis([]);
        }

        setIndeterminate(false);
        setCheckAll(checked);
    };

    const handleKpiSelection = (
        id: any,
        checked: any,
        collapsePanel?: any
    ): any => {
        if (collapsePanel === 'collapseHeader') {
            let updatedCheckedKpis: any = checkedKpis ? [...checkedKpis] : [];
            if (checked) {
                const extractedData = id.kpis.map((kpi: any) => ({
                    kpiId: kpi?.kpiId,
                    nodeTypeId: id?.nodeTypeId,
                }));
                updatedCheckedKpis.push(...extractedData);
            } else {
                id.kpis.forEach((kpi: any) => {
                    updatedCheckedKpis = updatedCheckedKpis.filter(
                        (item: any) =>
                            !(
                                item.kpiId === kpi?.kpiId &&
                                item?.nodeTypeId === id?.nodeTypeId
                            )
                    );
                });
            }

            setCheckedKpis(updatedCheckedKpis);
            const totalKpisCount = (kpiDetails ?? [])?.reduce(
                (count: number, node: any) => count + node?.kpis?.length,
                0
            );
            const allChecked = updatedCheckedKpis?.length === totalKpisCount;
            const someChecked = updatedCheckedKpis?.length > 0 && !allChecked;

            setIndeterminate(someChecked);
            setCheckAll(allChecked);
        } else {
            const updatedCheckedKpis: any = checkedKpis ? [...checkedKpis] : [];

            (kpiDetails ?? [])?.forEach((node: any) => {
                node?.kpis?.forEach((kpi: any) => {
                    if (kpi?.kpiId === id) {
                        if (checked) {
                            if (
                                !updatedCheckedKpis?.some(
                                    (k: any) => k?.kpiId === id
                                )
                            ) {
                                updatedCheckedKpis.push({
                                    kpiId: kpi?.kpiId,
                                    nodeTypeId: node?.nodeTypeId,
                                });
                            }
                        } else {
                            const index = updatedCheckedKpis?.findIndex(
                                (k: any) => k?.kpiId === id
                            );
                            if (index !== -1) {
                                updatedCheckedKpis?.splice(index, 1);
                            }
                        }
                    }
                });
            });

            setCheckedKpis(updatedCheckedKpis);
            const totalKpisCount = (kpiDetails ?? [])?.reduce(
                (count: number, node: any) => count + node?.kpis?.length,
                0
            );
            const allChecked = updatedCheckedKpis?.length === totalKpisCount;
            const someChecked = updatedCheckedKpis?.length > 0 && !allChecked;

            setIndeterminate(someChecked);
            setCheckAll(allChecked);
        }
    };

    return (
        <div className="groupDrawer__wrapper">
            <Form
                layout="vertical"
                initialValues={initialValues}
                disabled={formDisable}
                onFinish={submitHandler}
                form={form}
            >
                <Form.Item
                    name="groupName"
                    label={
                        <div className="groupDrawer__labelContainer">
                            <span className="groupDrawer__label">
                                {t('groups.groupName')}
                            </span>
                            {formDisable && (
                                <span className="groupDrawer__editLink">
                                    <a
                                        onClick={() => {
                                            editDrawer();
                                            dispatch({
                                                type: EDIT_BUTTON_CLICKED,
                                            });
                                        }}
                                    >
                                        <EditIcon className="editIcon" />
                                        {t('commonStr.edit')}
                                    </a>
                                </span>
                            )}
                        </div>
                    }
                    rules={[{ required: true }]}
                >
                    <Input
                        placeholder={t('groups.enterGroupNamePlaceholder')}
                        className="groupDrawer__Input"
                        value={groupName}
                    />
                </Form.Item>
                <Form.Item
                    name="resourceType"
                    label={t('groups.resourceType')}
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select"
                        className="groupDrawer__Input"
                        onChange={handleChange}
                        value={selectedOption}
                        onSelect={(value, option) => {
                            subItems(value, option.key);
                        }}
                    >
                        {resourceTypeValue?.map((item: any) => (
                            <Option key={item.id} value={item.resourceTypeName}>
                                {item.resourceTypeName}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                {values?.resourceType === resourceType.ASSET && (
                    <Form.Item
                        name="model"
                        label="Select Model"
                        rules={[{ required: true }]}
                    >
                        <Select
                            placeholder={t('users.userDetails.select')}
                            className="groupDrawer__Input"
                            options={modelListOptions()}
                            onSelect={modelSelectHandler}
                            value={selectedModel}
                        />
                    </Form.Item>
                )}
                {values?.resourceType === resourceType.SCREEN && (
                    <div className="groupDrawer__selectAll">
                        <Checkbox
                            className="groupDrawer__subItems"
                            checked={
                                checkedItems?.length === 0 ? false : checkAll
                            }
                            indeterminate={
                                checkedItems?.length === 0
                                    ? false
                                    : indeterminate
                            }
                            onChange={(e) => {
                                handleSelectAll(e.target.checked);
                            }}
                        >
                            {t('groups.subItems')}
                        </Checkbox>
                    </div>
                )}
                {values?.resourceType === resourceType.KPI && (
                    <div className="groupDrawer__selectAll">
                        <Checkbox
                            className="groupDrawer__subItems"
                            checked={
                                checkedKpis?.length === 0 ? false : checkAll
                            }
                            indeterminate={
                                checkedKpis?.length === 0
                                    ? false
                                    : indeterminate
                            }
                            onChange={(e) => {
                                handleSelectAllKpis(e.target.checked);
                            }}
                        >
                            Select All
                        </Checkbox>
                    </div>
                )}

                <Input
                    className="groupDrawer__search"
                    prefix={<SearchOutlined />}
                    placeholder={t('commonStr.searchFromTheList')}
                    value={searchValue}
                    onChange={(e) => {
                        setSearchValue(e.target.value);
                    }}
                />
                {selectedOptionData ? (
                    <div className="list-container">
                        <Listing
                            filteredItems={filteredItems}
                            checkedItems={checkedItems}
                            onChange={onChange}
                            handleSubItemChange={handleSubItemChange}
                            selectedOptionData={selectedOptionData}
                            setCheckedKeys={setCheckedKeys}
                            modelId={modelId}
                            checkedKeys={checkedKeys}
                            values={values}
                            kpiDetails={kpiDetails}
                            checkedKpis={checkedKpis}
                            setCheckedKpis={setCheckedKpis}
                            searchValue={searchValue}
                            expandedKpiKeys={expandedKpiKeys}
                            setExpandedKpiKeys={setExpandedKpiKeys}
                            handleKpiSelection={handleKpiSelection}
                        />
                    </div>
                ) : (
                    <div className="groupDrawer__noData">
                        <Empty
                            description={
                                <span className="empty-description">
                                    {t('groups.selectAccessControl')}
                                </span>
                            }
                        />
                    </div>
                )}

                {!formDisable && (
                    <div className="button">
                        <Divider />
                        <Row className="footerButtons">
                            <Col span={8}>
                                <CustomButton
                                    type={t('commonStr.cancel')}
                                    disabled={false}
                                    handleClick={() => {
                                        dispatch({ type: CLOSE_GROUP_DRAWER });
                                        dispatch(getGroupsList());
                                        dispatch({ type: EMPTY_TREE_OBJECT });

                                        onClose();
                                    }}
                                />
                            </Col>
                            <Col span={8}>
                                {checkedItems?.length !== 0 ||
                                checkedKeys?.length !== 0 ||
                                checkedKpis?.length !== 0 ? (
                                    <CustomButton
                                        type={t('commonStr.save')}
                                        disabled={false}
                                        typeOfButton={'submit'}
                                    />
                                ) : (
                                    <CustomButton
                                        type={t('commonStr.save')}
                                        disabled={true}
                                        typeOfButton={'submit'}
                                    />
                                )}
                            </Col>
                        </Row>
                    </div>
                )}
            </Form>
        </div>
    );
};

export default GroupDrawer;

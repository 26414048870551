import initialState from '../initialStates';
import * as types from 'redux/types/auditTrailsTypes';

export default function auditTrailsReducer(
    state = initialState.auditTrails,
    action: any
): any {
    switch (action.type) {
        case types.GET_AUDIT_TRAILS:
            state = {
                ...state,
                auditTrailListLoading: true,
            };
            return state;
        case types.GET_AUDIT_TRAILS_SUCCESS:
            state = {
                ...state,
                auditList: action?.response?.data,
                auditTrailListLoading: false,
            };
            return state;
        case types.GET_AUDIT_TRAILS_FAILURE:
            state = {
                ...state,
                auditTrailListLoading: false,
            };
            return state;
        case types.SET_AUDIT_LIST_EMPTY:
            state = {
                ...state,
                auditList: [],
            };
            return state;
        case types.GET_MODULES_SUCCESS:
            state = {
                ...state,
                moduleList: action?.response?.data,
            };
            return state;
        case types.GET_MODULES_CONFIGURATION_SUCCESS:
            state = {
                ...state,
                moduleConfigurationList: action?.response?.data,
            };
            return state;
        default:
            return state;
    }
}

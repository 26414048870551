import * as types from 'redux/types/auditTrailsTypes';

export const getAuditTrailsList = (payload?: any): any => ({
    type: types.GET_AUDIT_TRAILS,
    payload: payload,
});

export const getModulesList = (): any => ({
    type: types.GET_MODULES,
});

export const getModulesConfigurationList = (payload?: any): any => ({
    type: types.GET_MODULES_CONFIGURATION,
    payload: payload,
});

import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from 'redux/types/ldapConfiguratorTypes';
import {
    createLdapConfiguratorSettingsService,
    deleteLdapService,
    getLdapCountListService,
    getLdapServiceConfiguratorDetailsByIdService,
    getLdapServiceListService,
    getLdapTypeOfIdentityListService,
    getLdapUsersListService,
    testLdapConfiguratorService,
} from 'redux/services/LdapConfiguratorServices/ldapConfiguratorServices';
import { message } from 'antd';

export function* getLdapServiceListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const ldapServiceList = yield call(getLdapServiceListService, payload);
        yield put({
            type: types.GET_LDAP_SERVICE_LIST_SUCCESS,
            response: ldapServiceList,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_LDAP_SERVICE_LIST_FAILURE,
            response: error,
        });
    }
}

export function* createLdapConfiguratorSettingsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const isCreated = yield call(
            createLdapConfiguratorSettingsService,
            payload
        );
        yield put({
            type: types.CREATE_LDAP_CONFIGURATOR_SETTINGS_SUCCESS,
            response: isCreated,
        });
    } catch (error: any) {
        yield put({
            type: types.CREATE_LDAP_CONFIGURATOR_SETTINGS_FAILURE,
            response: error,
        });
    }
}

export function* getLdapUsersListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const ldapUsersList = yield call(getLdapUsersListService, payload);
        yield put({
            type: types.GET_LDAP_USERS_LIST_SUCCESS,
            response: ldapUsersList,
        });
        ldapUsersList && message.success('User Fetched Successfully');
    } catch (error: any) {
        yield put({
            type: types.GET_LDAP_USERS_LIST_FAILURE,
            response: error,
        });
    }
}

export function* getLdapTypeOfIdentityListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const ldaptypeOfidentityList = yield call(
            getLdapTypeOfIdentityListService
        );
        yield put({
            type: types.GET_LDAP_TYPE_OF_IDENTITY_LIST_SUCCESS,
            response: ldaptypeOfidentityList,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_LDAP_TYPE_OF_IDENTITY_LIST_FAILURE,
            response: error,
        });
    }
}

export function* testLdapConfiguratorSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const isTested = yield call(testLdapConfiguratorService, payload);
        yield put({
            type: types.TEST_LDAP_CONFIGURATOR_SUCCESS,
            response: isTested,
        });
    } catch (error: any) {
        yield put({
            type: types.TEST_LDAP_CONFIGURATOR_FAILURE,
            response: error,
        });
    }
}

export function* getLdapServiceConfiguratorDetailsByIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const getLdapServiceConfiguratorDetailsById = yield call(
            getLdapServiceConfiguratorDetailsByIdService,
            payload
        );
        yield put({
            type: types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_SUCCESS,
            response: getLdapServiceConfiguratorDetailsById,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_FAILURE,
            response: error,
        });
    }
}

export function* getLdapCountList({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const getLdapCount = yield call(getLdapCountListService);
        yield put({
            type: types.GET_LDAP_COUNT_SUCCESS,
            response: getLdapCount,
        });
    } catch (error: any) {
        yield put({
            type: types.GET_LDAP_COUNT_FAILURE,
            response: error,
        });
    }
}
export function* deleteLdapServiceSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const isDeleted: any = yield call(deleteLdapService, payload);
        yield put({
            type: types.DELETE_LDAP_SERVICE_SUCCESS,
            response: isDeleted,
        });
        if (isDeleted) {
            message.success(isDeleted);
            const ldapServiceList = yield call(getLdapServiceListService);
            yield put({
                type: types.GET_LDAP_SERVICE_LIST_SUCCESS,
                response: ldapServiceList,
            });
            const getLdapCount = yield call(getLdapCountListService);
            yield put({
                type: types.GET_LDAP_COUNT_SUCCESS,
                response: getLdapCount,
            });
        }
    } catch (error: any) {
        yield put({
            type: types.DELETE_LDAP_SERVICE_FAILURE,
            response: error,
        });
    }
}

export function* ldapConfiguratorSaga(): any {
    yield all([
        takeLatest(types.GET_LDAP_SERVICE_LIST, getLdapServiceListSaga),
    ]);
    yield all([
        takeLatest(
            types.CREATE_LDAP_CONFIGURATOR_SETTINGS,
            createLdapConfiguratorSettingsSaga
        ),
    ]);
    yield all([takeLatest(types.GET_LDAP_USERS_LIST, getLdapUsersListSaga)]);
    yield all([
        takeLatest(
            types.GET_LDAP_TYPE_OF_IDENTITY_LIST,
            getLdapTypeOfIdentityListSaga
        ),
    ]);
    yield all([
        takeLatest(types.TEST_LDAP_CONFIGURATOR, testLdapConfiguratorSaga),
    ]);
    yield all([
        takeLatest(
            types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID,
            getLdapServiceConfiguratorDetailsByIdSaga
        ),
    ]);
    yield all([takeLatest(types.GET_LDAP_COUNT, getLdapCountList)]);
    yield all([takeLatest(types.DELETE_LDAP_SERVICE, deleteLdapServiceSaga)]);
}

export enum qrTableColumnSort {
  qrCode = "uniqueData",
  configName = "metadataConfig.name",
  type = "type",
  qrCodetext = "qrCodeContent",
  generateTime = "createdAt",
}

export enum qrConfigTableColumnSort {
  name = "name",
  generationType = "generationType",
  imgType = "qrCodeConfig.type",
  width = "qrCodeConfig.width",
  scale = "qrCodeConfig.scale",
  createdBy = "createdBy.name",
  createdAt = "createdAt",
  updatedBy = "updatedBy.name",
  updatedAt = "updatedAt",
}

export enum sortOrderQrCode {
  ascending = "asc",
  descending = "desc",
}
export enum qrName {
  qrNameLength = "18",
  qrCodeText = "34",
  qrCodeConfigLength = "15",
}

export enum ColumnLength {
  name = 20,
  description = 40,
}

export enum QRConfigState {
  list = "list",
  view = "view",
  add = "add",
  edit = "edit",
}

export enum QRConfigFormFields {
  name = "name",
  description = "description",
  label = "label",
  imgType = "imgType",
  width = "width",
  scale = "scale",
  color = "color",
  configType = "configType",
  separator = "separator",
  table = "table",
}

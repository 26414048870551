import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, DatePicker, Input, Row, Select } from 'antd';
import { SearchOutlined, CloseOutlined } from '@ant-design/icons';
import './index.scss';
import { BUTTONTYPE } from 'types/enums';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import QRCodeConfigTable from './QRCodeConfigTable';
import CustomButton from 'components/common/CustomButton';
import { getQRConfigList, getQRDatatypeList, getQRImageTypeList, setQRConfigState } from 'redux/actions/QRCodeGenerationActions/qrCodeConfig';
import { QRConfigState } from 'types/enums/qrCodeGeneration';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import { getQRConfigListWithoutPaginated } from 'redux/actions/QRCodeGenerationActions/qrCodeLogs';
import { useParams } from 'react-router-dom';
import { getTableList } from 'redux/actions/ConfigureActions/tableAction';

const QRCodeConfig: React.FC = (): any => {
    const dispatch = useDispatch();
    const { RangePicker } = DatePicker;
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const [type, setType] = useState<any>();
    const [imgType, setImgType] = useState<any>();
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [advFilter, setAdvFilter] = useState<any>(false);
    const [attributeSearchTimeout, setAttributeSearchTimeout] =
    useState<any>(undefined);
    const [searchState, setSearchState] = useState('');
    const [reset, setReset] = useState<any>(false);

    const imageTypeList = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrImageType
    );
    const { currentTab } = useParams();

    useEffect(() => {
        if (
            currentTab === "config"
        ) {
            dispatch(
                getQRConfigList({
                    page: PAGE,
                    limit: PAGE_SIZE,
                })
            );
            dispatch(setQRConfigState(QRConfigState.list));
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
            setType(null);
            setImgType(null);
            setStartTime(null);
            setEndTime(null);
            setAdvFilter(false);
            setSearchState('');
        }
    }, [currentTab]);
    useEffect(() => {
        dispatch(getQRDatatypeList());
        dispatch(getQRImageTypeList());
        dispatch(getQRConfigListWithoutPaginated({}));
        dispatch(getTableList({ page: -1 })); // Note - Sending page as -1 to get data without pagination.
    }, [])
    const qrConfigStatusUpdated = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrConfigStatusUpdated
    );
   
    useEffect(() => {
        !qrConfigStatusUpdated && dispatch(
            getQRConfigList({
                page: page,
                limit: pageSize,
                sortOrder: sortOrder,
                sort: sortColumn,
                generationType: type,
                "qrCodeConfig.type": imgType,
                "createdAt.gte": startTime || null,
                "createdAt.lte": endTime || null,
            })
        );
    }, [pageSize, page, sortOrder, sortColumn, qrConfigStatusUpdated]);

    const onApply = (): void => {
        dispatch(
            getQRConfigList({
                page: PAGE,
                limit: PAGE_SIZE,
                generationType: type,
                "qrCodeConfig.type": imgType,
                "createdAt.gte": startTime || null,
                "createdAt.lte": endTime || null,
            })
        );
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
        setSortOrder(null);
        setSortColumn(null);
    };


    const resetFilter = (): void => {
        if (
            type ||
            imgType ||
            startTime ||
            endTime
        ) {
            dispatch(
                getQRConfigList({
                    page: PAGE,
                    limit: PAGE_SIZE,
                })
            );
        }
        setType(null);
        setImgType(null);
        setStartTime(null);
        setEndTime(null);
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
        setSortOrder(null);
        setSortColumn(null);
        setReset(!reset);
    };

    const onOk = (value: any): void => {
        value[0] && setStartTime(value[0]?.toISOString()); // Note- used 0th index to get the start time in Rangepicker
        value[1] && setEndTime(value[1]?.toISOString()); // Note- used 1st index to get the end time in Rangepicker
    };

    return (
        <>
            <div className="attributeWrapper qrCodeConfigWrapper">
                <Card bordered={false}>
                        <>{
                            advFilter ? (
                                <>
                                    <Row>
                                        <Col className="qrCodeConfigWrapper__rowHeader">
                                            <Select
                                                key={`${advFilter}`}
                                                placeholder="Generation Type"
                                                options={[
                                                    {
                                                        value: "Manual",
                                                        label: "Manual"
                                                    },
                                                    {
                                                        value: "System Generated",
                                                        label: "System Generated"
                                                    }
                                                ]}
                                                value={type}
                                                onChange={(value: any) => {
                                                    setType(value);
                                                }}
                                            />
                                            <Select
                                                key={`${advFilter}`}
                                                placeholder="Image Type"
                                                options={imageTypeList?.map((item: any) => {
                                                    return {
                                                        label: item,
                                                        value: item,
                                                    };
                                                })}
                                                value={imgType}
                                                onChange={(value: any) => {
                                                    setImgType(value);
                                                }}
                                            />
                                            <RangePicker
                                                key={`${advFilter}_ ${reset}`}
                                                allowClear={false}
                                                showTime
                                                onOk={onOk}
                                                placeholder={['Start Timestamp', 'End Timestamp']}
                                            />  
                                            <CustomButton
                                                type="Apply"
                                                disabled={false}
                                                handleClick={onApply}
                                            />
                                            <span
                                                className="customHeader__child-resetButton"
                                                onClick={resetFilter}
                                            >
                                                <ResetIcon />
                                            </span>  
                                            <CloseOutlined
                                                className="closeOutlinedIcon"
                                                onClick={() => {
                                                    setAdvFilter(false);
                                                }}
                                            />                               
                                            
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                    <>
                                        <Row className="qrCodeConfigWrapper__filterHeader">
                                                <Col span={5}>
                                                    <Input
                                                        allowClear
                                                        className="qrCodeConfigWrapper__search"
                                                        placeholder={"Search Name"}
                                                        prefix={<SearchOutlined />}
                                                        value={searchState}
                                                        onChange={(e) => {
                                                            setSearchState(e.target.value);
                                                            if (attributeSearchTimeout) {
                                                                clearTimeout(attributeSearchTimeout);
                                                            }
                                                            // Debouncing for search implemented
                                                            setAttributeSearchTimeout(
                                                                setTimeout(() => {
                                                                    dispatch(
                                                                        getQRConfigList({
                                                                            page: PAGE,
                                                                            limit: PAGE_SIZE,
                                                                            name: e?.target?.value + "*"
                                                                        })
                                                                    );
                                                                }, 1000)
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    className="mappingWrapper__textButton"
                                                    span={5}
                                                >
                                                    <span
                                                        onClick={() => {
                                                            setAdvFilter(true);
                                                            setSearchState('');
                                                        }}
                                                    >
                                                        Advance Filter
                                                    </span>
                                                </Col>
                                                <Col span={7}></Col>
                                                <Col
                                                    className="mappingWrapper__textButton"
                                                    span={5}
                                                >
                                                    <CustomButton
                                                        type={BUTTONTYPE.qrCodeConfig}
                                                        disabled={false}
                                                        handleClick={() => {
                                                            dispatch(setQRConfigState(QRConfigState.add))
                                                        }}
                                                    />
                                                </Col>
                                                
                                        </Row>
                                    </>
                                )
                            }
                            <QRCodeConfigTable
                                setPage={setPage}
                                page={page}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                            />
                        </>
                </Card>
            </div>
        </>
    );
};

export default QRCodeConfig


import * as types from "redux/types/qrCodeTypes";

export const getQRCodeLogsList = (payload: any): any => ({
    type: types.GET_QRCODELOGS_LIST,
    payload: payload,
  });

  export const getQRCodeLogsListById = (payload: any): any => ({
    type: types.GET_QRCODELOGSLIST_BYID,
    payload: payload,
  });

  export const getQRCodeLogsListWithoutPaginated = (payload: any): any => ({
    type: types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED,
    payload: payload,
  });
  
  export const getQRConfigListWithoutPaginated = (payload: any): any => ({
    type: types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED,
    payload: payload,
  });

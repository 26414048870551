import initialState from '../initialStates';
import * as types from 'redux/types/assetModellingTypes';

export default function assetModellingReducer(
    state = initialState.assetModelling,
    action: any
): any {
    switch (action.type) {
        case types.SELECTED_ASSET:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    selectedAsset: action?.payload,
                },
            };
            return state;
        case types.DISPLAY_TOTAL_TAGS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    displayTagsCount: true,
                },
            };
            return state;
        case types.REMOVE_TOTAL_TAGS_COUNT:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    selectedAsset: {},
                    mappedTagsList: {},
                    displayTagsCount: false,
                },
            };
            return state;

        case types.SET_EQUIPMENT_STATE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    eqipmentState: action.payload,
                },
            };
            return state;
        case types.SET_NODE_DATA:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: true,
                },
            };
            return state;
        case types.SET_NODE_DATA_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeData: action?.payload,
                },
            };
            return state;
        case types.SET_NODE_DATA_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeData: action?.payload,
                },
            };
            return state;
        case types.GET_NODE_TYPE_DETAILS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    nodeDataLoading: true,
                },
            };
            return state;
        case types.GET_NODE_TYPE_DETAILS_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    nodeTypeListDetails: action?.response?.data,
                    nodeDataLoading: false,
                },
            };
            return state;
        case types.GET_NODE_TYPE_DETAILS_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    nodeDataLoading: false,
                    nodeTypeListDetails: action?.response?.data,
                },
            };
            return state;
        case types.GET_TOTAL_MODEL_LIST_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelList: action.response.data,
                    modelListLoading: false,
                    modelDetailsEdited: false,
                    horizontalView: false,
                },
            };
            return state;

        case types.GET_TOTAL_MODEL_LIST:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelListLoading: true,
                },
            };
            return state;
        case types.SET_MODEL_DETAILS_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelAdded: true,
                    modelListLoading: true,
                },
            };
            return state;
        case types.GET_MODEL_DETAILS_BY_ID:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelDetailsLoading: true,
                },
            };
            return state;
        case types.GET_MODEL_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelDetails: action?.response?.data,
                    modelDetailsLoading: false,
                },
            };
            return state;
        case types.DELETE_MODEL:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelListLoading: true,
                },
            };
            return state;
        case types.DELETE_MODEL_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelDeleted: true,
                    modelListLoading: false,
                },
            };
            return state;
        case types.EDIT_MODEL_DETAILS_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelDetailsEdited: true,
                },
            };
            return state;
        case types.OPEN_SUB_MODEL_TABS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openSubModelTabs: true,
                },
            };
            return state;
        case types.CLOSE_SUB_MODEL_TABS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openSubModelTabs: false,
                },
            };
            return state;

        case types.CLEAR_MODEL_DETAILS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    modelDetails: {},
                },
            };
            return state;
        case types.PUT_NODE_TYPE_UPDATE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: true,
                },
            };
            return state;
        case types.PUT_NODE_TYPE_UPDATE_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeUpdateForm: action?.response?.data,
                },
            };
            return state;
        case types.PUT_NODE_TYPE_UPDATE_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeUpdateForm: action?.response?.data,
                },
            };
            return state;
        case types.GET_TAG_LIST_BY_DEVICE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagListByDeviceLoader: true,
                },
            };
            return state;
        case types.GET_TAG_LIST_BY_DEVICE_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagListByDevice: action?.response?.data,
                    tagListByDeviceLoader: false,
                },
            };
            return state;
        case types.GET_MAPPED_TAGS_LIST:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    mappedTagsListLoading: true,
                },
            };
            return state;

        case types.SET_EQUIPMENT_DATA:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: true,
                },
            };
            return state;
        case types.SET_EQUIPMENT_DATA_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassData: action?.payload,
                },
            };
            return state;
        case types.SET_EQUIPMENT_DATA_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassData: action?.payload,
                },
            };
            return state;
        case types.GET_EQUIPMENT_DETAILS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    configurationDataLoading: true,
                },
            };
            return state;
        case types.GET_EQUIPMENT_DETAILS_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    equipmentClassTableList: action?.response?.data,
                    configurationDataLoading: false,
                },
            };
            return state;
        case types.GET_EQUIPMENT_DETAILS_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    configurationDataLoading: false,
                    equipmentClassTableList: action?.response?.data,
                },
            };
            return state;
        case types.GET_NODE_TYPE_BY_ID:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: true,
                },
            };
            return state;
        case types.GET_NODE_TYPE_BY_ID_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeById: action?.response?.data,
                },
            };
            return state;
        case types.GET_NODE_TYPE_BY_ID_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeById: action?.response?.data,
                },
            };
            return state;
        case types.PUT_EQUIPMENT_CLASS_UPDATE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: true,
                },
            };
            return state;
        case types.PUT_EQUIPMENT_CLASS_UPDATE_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassUpdate: action?.response?.data,
                },
            };
            return state;
        case types.PUT_EQUIPMENT_CLASS_UPDATE_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassUpdate: action?.response?.data,
                },
            };
            return state;
        case types.CLONE_EQUIPMENT_CLASS_REQUEST:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: true,
                },
            };
            return state;
        case types.CLONE_EQUIPMENT_CLASS_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassClone: action?.response?.data,
                },
            };
            return state;
        case types.CLONE_EQUIPMENT_CLASS_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassClone: action?.response?.data,
                },
            };
            return state;
        case types.CLONE_NODE_TYPE_REQUEST:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: true,
                },
            };
            return state;
        case types.CLONE_NODE_TYPE_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeClone: action?.response?.data,
                },
            };
            return state;
        case types.CLONE_NODE_TYPE_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeClone: action?.response?.data,
                },
            };
            return state;
        case types.DELETE_NODE_TYPE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: true,
                },
            };
            return state;
        case types.DELETE_NODE_TYPE_SUCCESS:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeDelete: action?.response?.data,
                },
            };
            return state;
        case types.DELETE_NODE_TYPE_FAILURE:
            state = {
                ...state,
                nodeType: {
                    ...state.nodeType,
                    loader: false,
                    nodeTypeDelete: action?.response?.data,
                },
            };
            return state;
        case types.GET_MAPPED_TAGS_LIST_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    mappedTagsList: action?.response?.data,
                    mappedTagsListLoading: false,
                    tagFactorUpdated: false,
                },
            };
            return state;
        case types.GET_MAPPED_TAGS_LIST_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    mappedTagsListLoading: false,
                },
            };
            return state;
        case types.MAP_TAG_NODES_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagsMapped: true,
                    mappedTagsListLoading: true,
                },
            };
            return state;
        case types.MAP_TAG_NODES_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagsMapped: false,
                },
            };
            return state;
        case types.GET_DATATYPE_LIST_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    dataTypeList: action?.response?.data,
                },
            };
            return state;
        case types.GET_UNITS_LIST_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    unitsList: action?.response?.data,
                },
            };
            return state;
        case types.UPDATE_TAG_FACTOR_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagFactorUpdated: true,
                    mappedTagsListLoading: true,
                },
            };
            return state;
        case types.UPDATE_TAG_FACTOR_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    tagFactorUpdated: false,
                },
            };
            return state;
        case types.GET_TREE_BY_MODEL_ID_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    getTreeByModelId: action?.response?.data,
                    displayTreeLoader: false,
                },
            };
            return state;
        case types.EMPTY_TREE_OBJECT:
            state={
                ...state,
                modelOverview:{
                    ...state.modelOverview,
                    getTreeByModelId:{}
                }
            };
            return state;
        case types.GET_TREE_BY_MODEL_ID_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    displayTreeLoader: false,
                },
            };
            return state;

        case types.OPEN_ADD_NODE_DRAWER:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openAddNodeDrawer: true,
                },
            };
            return state;
        case types.CLOSE_ADD_NODE_DRAWER:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openAddNodeDrawer: false,
                },
            };
            return state;
        case types.OPEN_DELETE_NODE_MODAL:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openDeleteNodeModal: true,
                },
            };
            return state;
        case types.CLOSE_DELETE_NODEL_MODAL:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    openDeleteNodeModal: false,
                },
            };
            return state;
        case types.GET_NODE_DETAILS_BY_ID:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    nodeDetailsLoading: true,
                },
            };
            return state;
        case types.GET_NODE_DETAILS_BY_ID_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    nodeDetailsById: action?.response?.data,
                    nodeDetailsLoading: false,
                },
            };
            return state;
        case types.GET_NODE_DETAILS_BY_ID_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    nodeDetailsLoading: false,
                },
            };
            return state;
        case types.DELETE_NODE_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeDeleted: true,
                    displayTreeLoader: true,
                },
            };
            return state;
        case types.DELETE_NODE_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeDeleted: false,
                },
            };
            return state;

        case types.SET_NODE_INFO:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    nodeData: action?.payload,
                },
            };
            return state;
        case types.ADD_NODE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeAdded: false,
                },
            };
            return state;
        case types.ADD_NODE_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeAdded: true,
                    displayTreeLoader: true,
                },
            };
            return state;
        case types.ADD_NODE_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeAdded: false,
                },
            };
            return state;
        case types.DISPLAY_HORIZONTALLY:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    horizontalView: true,
                    verticalView: false,
                },
            };
            return state;
        case types.DISPLAY_VERTICALLY:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    verticalView: true,
                    horizontalView: false,
                },
            };
            return state;
        case types.GET_EQUIPMENT_CLASS_BY_ID:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: true,
                },
            };
            return state;
        case types.GET_EQUIPMENT_CLASS_BY_ID_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassById: action?.response?.data,
                },
            };
            return state;
        case types.GET_EQUIPMENT_CLASS_BY_ID_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassById: action?.response?.data,
                },
            };
            return state;

        case types.DELETE_EQUIPMENT_CLASS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: true,
                },
            };
            return state;
        case types.DELETE_EQUIPMENT_CLASS_SUCCESS:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassDelete: action?.response?.data,
                },
            };
            return state;
        case types.DELETE_EQUIPMENT_CLASS_FAILURE:
            state = {
                ...state,
                equipment: {
                    ...state.equipment,
                    loader: false,
                    equipmentClassDelete: action?.response?.data,
                },
            };
            return state;
        case types.REMOVE_MAPPING_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    unMapTagNodes: true,
                },
            };
            return state;
        case types.REMOVE_MAPPING_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    unMapTagNodes: false,
                },
            };
            return state;
        case types.OPEN_EDIT_NODE_DETAILS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    editNodeDetails: true,
                },
            };
            return state;
        case types.CLOSE_EDIT_NODE_DETAILS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    editNodeDetails: false,
                },
            };
            return state;
        case types.EMPTY_MAPPED_TAGS_DATA:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    mappedTagsList: {},
                    unitsList: [],
                    dataTypeList: [],
                },
            };
            return state;
        case types.EDIT_NODE_DETAILS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeDetailsEdited: false,
                },
            };
            return state;
        case types.EDIT_NODE_DETAILS_SUCCESS:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeDetailsEdited: true,
                    displayTreeLoader: true,
                },
            };
            return state;
        case types.EDIT_NODE_DETAILS_FAILURE:
            state = {
                ...state,
                modelOverview: {
                    ...state.modelOverview,
                    isNodeDetailsEdited: false,
                },
            };
            return state;
            case types.EMPTY_EQUIPMENT_CLASS_DETAILS:
                state = {
                    ...state,
                    equipment: {
                        ...state.equipment,
                        equipmentClassById: [],
                    },
                };
                return state;
        default:
            return state;
    }
}

import { Checkbox, Col, Form, Input, Row, Select, TimePicker } from 'antd';
import { useSelector } from 'react-redux';

const CreateLdapPeriodicFetch: React.FC<any> = ({ form }: any): any => {
    const daysList = useSelector((state: any) => state.commonState.daysList);
    return (
        <>
            <div className="ldapForm">
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item label={'Start Time'} name="startTime">
                            <TimePicker className="ldapTimePicker" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={'Start Time'} name="startTime">
                            <TimePicker className="ldapTimePicker" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            label={'Frequency Unit'}
                            name="frequencyUnit"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label={'Frequency Value'}
                            name="frequencyValue"
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={24}>
                        <Form.Item name="weekDays">
                            <Checkbox.Group>
                                <Row gutter={10}>
                                    {daysList?.map((item: any, index: any) => {
                                        return (
                                            <Col span={5} key={index}>
                                                <Checkbox value={item.id}>
                                                    {item.name}
                                                </Checkbox>
                                            </Col>
                                        );
                                    })}
                                </Row>
                            </Checkbox.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item label={'Run on Shift'} name="runOnShift">
                            <Select placeholder="select" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default CreateLdapPeriodicFetch;

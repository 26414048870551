import * as types from 'redux/types/qrCodeTypes';

export const setQRConfigState = (payload: any): any => ({
    type: types.SET_CONGIF_STATE,
    payload: payload,
});

export const getQRDatatypeList = (): any => ({
    type: types.GET_QR_DATATYPE,
});

export const getQRImageTypeList = (): any => ({
    type: types.GET_IMAGE_TYPE,
});

export const createQRConfig = (payload: any): any => ({
    type: types.CREATE_QR_CONFIG,
    payload: payload,
});

export const getQRConfigList = (payload: any): any => ({
    type: types.GET_QR_CONFIG_LIST,
    payload: payload,
});

export const generateQRCode = (payload: any): any => ({
    type: types.GENERATE_QR_CODE,
    payload: payload,
});

export const updateQRConfig = (payload: any): any => ({
    type: types.UPDATE_QR_CONFIG,
    payload: payload,
});

export const getQRConfigDetails = (payload: any): any => ({
    type: types.GET_QR_CONFIG_DETAILS,
    payload: payload,
});

export const setQRConfigStatus = (payload: any): any => ({
    type: types.SET_QR_CONFIG_STATUS,
    payload: payload,
});

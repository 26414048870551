import React, { useState, useEffect } from 'react';
import { Input, Typography } from 'antd';
import './index.scss';
import TextArea from 'antd/es/input/TextArea';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ROLETYPE } from 'types/enums';
const { Text } = Typography;

const CreateRoleStep3: React.FC<any> = ({
    setNameState,
    setDescriptionState,
    descriptionState,
    name,
}) => {
    const editRoleState = useSelector(
        (state: any) => state.userManagement.roles.editRoleState
    );
    const roleDetails = useSelector(
        (state: any) => state.userManagement.roles.roleDetails
    );

    const { t } = useTranslation('translation');

    const [localName, setLocalName] = useState('');
    const [localDescription, setLocalDescription] = useState('');

    useEffect(() => {
        if (editRoleState === ROLETYPE?.edit && roleDetails?.roleName) {
            setLocalName(roleDetails.roleName);
        }
        if (editRoleState === ROLETYPE?.edit && roleDetails?.roleDescription) {
            setLocalDescription(roleDetails.roleDescription);
        }
    }, [editRoleState, roleDetails]);

    const handleNameChange = (e: any): any => {
        const newName = e.target.value;
        setLocalName(newName);
        setNameState(newName);
    };

    const handleDescriptionChange = (e: any): any => {
        const newDesc = e.target.value;
        setLocalDescription(newDesc);
        setDescriptionState(newDesc);
    };
    return (
        <div className="createRolesWrapper__createRoleContent3">
            <div className="createRolesWrapper__createRoleInnerContent">
                <Text
                    type="secondary"
                    strong
                    className="createRolesWrapper__createRoleInnerContentText"
                >
                    <span className="mandatoryClass">*</span>
                    {t('roles.enterRoleName')}
                </Text>
                <Input
                    placeholder={t('commonStr.name')}
                    onChange={handleNameChange}
                    value={editRoleState === ROLETYPE?.edit ? localName : name}
                />
            </div>
            <div className="createRolesWrapper__createRoleInnerContent">
                <Text
                    type="secondary"
                    strong
                    className="createRolesWrapper__createRoleInnerContentText"
                >
                    {t('roles.descpRole')}
                </Text>
                <TextArea
                    rows={4}
                    placeholder={t('roles.description')}
                    // onChange={(e) => {
                    //     setDescriptionState(e.target.value);
                    // }}
                    onChange={handleDescriptionChange}
                    value={
                        editRoleState === ROLETYPE?.edit
                            ? localDescription
                            : descriptionState
                    }
                />
            </div>
        </div>
    );
};

export default CreateRoleStep3;

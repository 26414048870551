import { all, call, put, takeLatest } from "redux-saga/effects";
import { getQRConfigListServices } from "redux/services/QRCodeGenerationServices/qrCodeConfigServices";
import { getqrCodeListByIdServices, getqrCodeTableListServices} from "redux/services/QRCodeGenerationServices/qrCodeLogsServices";
import * as types from "redux/types/qrCodeTypes";

export function* getqrCodeLogsListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getQRCodeLogsList = yield call(getqrCodeTableListServices,payload);
    yield put({
      type: types.GET_QRCODELOGS_LIST_SUCCESS,
      response: getQRCodeLogsList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_QRCODELOGS_LIST_FAILURE
    });
  }
}

export function* getqrCodeLogsListWithoutPaginationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getQRCodeLogsListWithoutPagination = yield call(getqrCodeTableListServices,payload);
    yield put({
      type: types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED_SUCCESS,
      response: getQRCodeLogsListWithoutPagination,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED_FAILURE
    });
  }
}

export function* getqrCodeConfigListWithoutPaginationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getQRCodeConfigListWithoutPagination = yield call(getQRConfigListServices,payload);
    yield put({
      type: types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_SUCCESS,
      response: getQRCodeConfigListWithoutPagination,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_FAILURE 
    });
  }
}
export function* getqrCodeLogsListByIDSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
 
  try {
   
    const getQRCodeLogsListById = yield call(getqrCodeListByIdServices,payload);
    yield put({
      type: types.GET_QRCODELOGSLIST_BYID_SUCCESS,
      response: getQRCodeLogsListById,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_QRCODELOGSLIST_BYID_FAILURE
    });
  }
}


export function* qrCodeGenerationSaga(): any {
  yield all([takeLatest(types.GET_QRCODELOGS_LIST, getqrCodeLogsListSaga)]); 
  yield all([takeLatest(types.GET_QRCODELOGSLIST_BYID, getqrCodeLogsListByIDSaga)]); 
  yield all([takeLatest(types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED, getqrCodeLogsListWithoutPaginationSaga)]); 
  yield all([takeLatest(types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED, getqrCodeConfigListWithoutPaginationSaga)]); 
}

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as RemoveIcon } from 'assets/icons/removeConfirmIcon.svg';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import { parseJwt } from 'utils/jwtTokenFunction';
import { ReactComponent as AuditIcon } from 'assets/icons/auditIcon.svg';
import {
    ATTRIBUTETYPEIMPL,
    assignAttributeStatus,
    implementationAssignStatus,
    EMPTY,
    implementationPopoverItems,
    dataReferenceType,
    moduleList,
} from 'types/enums';
import {
    AssignAttributeList,
    setAttributeImplState,
    editAttributeValue,
} from 'redux/actions/ImplementationActions/attributeActions';
import { useTranslation } from 'react-i18next';
import {
    formulaCreatorScreenType,
    ruleCategoryEnum,
} from 'types/enums/FormulaCreatorEnum';
import {
    fetchFormulaAction,
    viewFormulaScreenOpenAction,
} from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import { useNavigate } from 'react-router-dom';

const AttributeMoreContent: React.FC<{
    record: any;
    setPopoverVisible: Function;
    searchPayload: any;
    paginatedPayload: any;
    setStaticValueModal: Function;
    textAreaValue: any;
    setAttributeRecord: Function;
    setTextAreaValue: Function;
    isEditTag?: boolean;
    setSearchState: any;
}> = ({
    record,
    setPopoverVisible,
    searchPayload,
    paginatedPayload,
    textAreaValue,
    setStaticValueModal,
    setAttributeRecord,
    setTextAreaValue,
    isEditTag,
    setSearchState,
}) => {
    const selectedModel = useSelector(
        (state: any) => state.implementation?.attribute?.modelId
    );
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selectedAsset = useSelector(
        (state: any) => state.implementation?.attribute?.selectedAsset
    );
    const { t } = useTranslation('translation');
    const [activeModalOpen, setActiveModalOpen] = useState(false);
    const details = parseJwt();

    const handleEditClick = (): void => {
        const payload = {
            assetId: selectedAsset?.key,
            view: true,
            type: formulaCreatorScreenType.ATRRIBUTE,
            modelId: selectedModel,
            title: record?.name,
            ruleCategory: ruleCategoryEnum.ATTRIBUTE,
            ruleCategoryId: record?.id,
            ruleId: record?.attributeValueResponse?.ruleId,
        };
        const fetchPayload = {
            ruleId: record?.attributeValueResponse?.ruleId,
            assetId: selectedAsset?.key,
            ruleCategory: ruleCategoryEnum.ATTRIBUTE,
            ruleCategoryId: `${record?.id}`,
        };
        if (record?.dataReference?.id === dataReferenceType.formula) {
            dispatch(viewFormulaScreenOpenAction(payload));
            dispatch(fetchFormulaAction(fetchPayload));
        } else {
            setStaticValueModal(true);
            setAttributeRecord(record);
            setPopoverVisible(false);
            dispatch(setAttributeImplState(ATTRIBUTETYPEIMPL.edit));
            setTextAreaValue(record?.attributeValueResponse?.ruleId);
            setTextAreaValue(record?.attributeValueResponse?.value);
        }
    };

    const handleViewAudits = (): void => {
        const queryParams = new URLSearchParams({
            module: moduleList?.ATTRIBUTE_IMPLEMENTATION,
            moduleEntity: record?.id,
        }).toString();

        navigate(`/audit-trails?${queryParams}`);
    };

    const handleEditTagClick = (): void => {
        setStaticValueModal(true);
        setAttributeRecord(record);
        dispatch(setAttributeImplState(ATTRIBUTETYPEIMPL.edit));
        setPopoverVisible(false);
        setTextAreaValue(record?.attributeValueResponse?.value);
    };
    // will use later
    // const handleDeactivateClick = (): void => {
    //     setPopoverVisible(false);
    // };

    const attributeStateImpl = useSelector(
        (state: any) => state.implementation?.attribute?.attributeStateImpl
    );
    const handleActivateClick = (): void => {
        setPopoverVisible(false);
    };
    const values = [
        {
            title: implementationPopoverItems?.EDIT,
            disable: !record?.isValidated,
        },

        {
            title: implementationPopoverItems?.REMOVE,
            disable: false,
        },
        {
            title: t('commonStr.viewAudits'),
            icon: <AuditIcon width={14} height={14} />,
            disable: false,
        },
    ];

    const onOkActiveHandler = (): any => {
        attributeStateImpl === ATTRIBUTETYPEIMPL.edit
            ? dispatch(
                  editAttributeValue({
                      ...paginatedPayload,
                      assetId: selectedAsset?.key,
                      attributeId: record?.id,
                      value: textAreaValue,
                  })
              )
            : dispatch(
                  AssignAttributeList({
                      ...paginatedPayload,
                      assetId: selectedAsset?.key,
                      attributeIdList: [record?.id],
                      requestedBy: details?.username,
                      doAssign: assignAttributeStatus?.UNASSIGN,
                      assignStatus: implementationAssignStatus?.ASSIGN,
                  })
              );
        setSearchState(null);

        // setActivateStateLocal('Active');
        okHandle(activeModalOpen, setActiveModalOpen);
    };
    // To hide edit functionality from non validated Attribute
    if (!record?.isValidated) {
        values.splice(0, 1);
    }
    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values.length > 0 &&
                            values.map((item) => (
                                <li
                                    key={item.title}
                                    className={`moreContent__items  ${
                                        item.disable ? 'disable' : EMPTY.string
                                    }`}
                                    onClick={() => {
                                        if (
                                            item.title ===
                                                implementationPopoverItems?.EDIT &&
                                            !item.disable
                                        ) {
                                            isEditTag
                                                ? handleEditTagClick()
                                                : handleEditClick();
                                        } else if (
                                            item.title ===
                                            implementationPopoverItems?.REMOVE
                                        ) {
                                            handleActivateClick();
                                            modalShow(
                                                activeModalOpen,
                                                setActiveModalOpen
                                            );
                                        } else if (
                                            item.title ===
                                            t('commonStr.viewAudits')
                                        ) {
                                            handleViewAudits();
                                        }
                                    }}
                                >
                                    <span
                                        className={`moreContent__option  ${
                                            item.disable
                                                ? 'disable'
                                                : EMPTY.string
                                        }`}
                                    >
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <ConfirmationModal
                open={activeModalOpen}
                onOk={() => {
                    onOkActiveHandler();
                }}
                onCancel={() =>
                    cancelHandle(activeModalOpen, setActiveModalOpen)
                }
                text={t('implementation.removeModalText')}
                icon={<RemoveIcon />}
            />
        </>
    );
};

export default AttributeMoreContent;

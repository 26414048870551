import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SHOW_LOADER_SUCCESS } from '../../types/userManagementTypes';
import * as types from '../../types/auditTrailsTypes';
import {
    getAuditTrailsService,
    getModulesConfigurationService,
    getModulesService,
} from 'redux/services/AuditTrailsServices/auditTrailsServices';

export function* getAuditTrailsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
        const getAuditTrails = yield call(getAuditTrailsService, payload);
        yield put({
            type: types.GET_AUDIT_TRAILS_SUCCESS,
            response: getAuditTrails,
        });
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_AUDIT_TRAILS_FAILURE,
            response: error,
        });
    }
}
export function* getModulesSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
        const getModules = yield call(getModulesService, payload);
        yield put({
            type: types.GET_MODULES_SUCCESS,
            response: getModules,
        });
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_MODULES_FAILURE,
            response: error,
        });
    }
}

export function* getModulesConfigurationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
        const getModuleConfiguration = yield call(
            getModulesConfigurationService,
            payload
        );
        yield put({
            type: types.GET_MODULES_CONFIGURATION_SUCCESS,
            response: getModuleConfiguration,
        });
        yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_MODULES_CONFIGURATION_FAILURE,
            response: error,
        });
    }
}

export function* auditTrailsSaga(): any {
    yield all([takeLatest(types.GET_AUDIT_TRAILS, getAuditTrailsSaga)]);
    yield all([takeLatest(types.GET_MODULES, getModulesSaga)]);
    yield all([
        takeLatest(
            types.GET_MODULES_CONFIGURATION,
            getModulesConfigurationSaga
        ),
    ]);
}

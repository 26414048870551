export enum LDAPConfiguratorState {
  tableView = "tableView",
  addLdapConfigurator = "add",
  ldapConfiguratorView = "view",
  ldapConfiguratorEdit = "edit",
}

export enum LDAPSortEnum {
  recentlyCreated = "recentlyCreated",
  lastModified = "lastModified",
  oldToNew = "oldToNew",
  connectedOnly = "connectedOnly",
  disconnectedOnly = "disconnectedOnly",
}

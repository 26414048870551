import { Divider, Checkbox, Tree, Collapse, Space, Popover } from 'antd';
import { useSelector } from 'react-redux';
import { resourceType } from 'types/enums';
import { mapTree } from 'utils/commonFunction';
import type { TreeProps } from 'antd';
import { useEffect, useState } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';

const Listing: React.FC<any> = ({
    filteredItems,
    checkedItems,
    onChange,
    handleSubItemChange,
    selectedOptionData,
    setCheckedKeys,
    checkedKeys,
    values,
    searchValue,
    kpiDetails,
    setCheckedKpis,
    checkedKpis,
    expandedKpiKeys,
    setExpandedKpiKeys,
    handleKpiSelection,
}) => {
    const { t } = useTranslation('translation');
    const treeObject = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.getTreeByModelId
    );
    const checkedKey = useSelector(
        (state: any) => state.userManagement.groups.dataByGroupId?.resourceList
    );

    const viewGroup = useSelector(
        (state: any) => state.userManagement.groups.viewGroupDrawer
    );
    const onCheck: TreeProps['onCheck'] = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue as React.Key[]);
    };
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);

    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    useEffect(() => {
        if (treeObject) {
            const resourceIds = checkedKey?.map((item: any) =>
                parseInt(item?.resourceId)
            );
            setExpandedKeys(resourceIds);
        }
    }, [treeObject, checkedKey]);

    const onExpand: TreeProps['onExpand'] = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };
    const [filteredTreeData, setFilteredTreeData] = useState<any>([]);
    // Helper function to flatten the tree
    const flattenTree = (tree: any): any => {
        const flatArray: any[] = [];
        const traverse = (node: any): any => {
            flatArray.push(node);
            if (node?.children) {
                node?.children?.forEach(traverse);
            }
        };
        traverse(tree);
        return flatArray;
    };

    // Helper function to reconstruct the tree from filtered flat array
    const constructTree = (flatArray: any[], tree: any): any => {
        const traverse = (node: any): any => {
            const filteredChildren = node?.children
                ?.map((child: any) => traverse(child))
                ?.filter((child: any) => child);
            if (
                filteredChildren?.length ||
                flatArray?.some((item) => item?.key === node?.key)
            ) {
                return { ...node, children: filteredChildren };
            }
            return null;
        };
        return traverse(tree);
    };

    useEffect(() => {
        if (searchValue && treeObject) {
            const flatTree = flattenTree(mapTree(treeObject));

            // Filter the flattened tree
            const filteredFlatTree = flatTree?.filter((item: any) =>
                item?.title?.toLowerCase()?.includes(searchValue?.toLowerCase())
            );

            // Collect keys of all parent nodes leading to matched nodes
            const expandKeys = new Set<React.Key>();
            const collectParentKeys = (
                node: any,
                parentKey: React.Key | null = null
            ): any => {
                if (
                    node?.title
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                ) {
                    let currentNode = node;
                    while (parentKey) {
                        expandKeys.add(parentKey);
                        currentNode = flatTree?.find(
                            (item: any) => item?.key === parentKey
                        );
                        parentKey = currentNode?.parentKey || null;
                    }
                }
                node?.children?.forEach((child: any) =>
                    collectParentKeys(child, node?.key)
                );
            };

            collectParentKeys(mapTree(treeObject));

            const newTree = constructTree(
                filteredFlatTree,
                mapTree(treeObject)
            );
            setFilteredTreeData(newTree ? [newTree] : []);
            setExpandedKeys(Array.from(expandKeys));
            setAutoExpandParent(true);
        } else {
            setFilteredTreeData([mapTree(treeObject)]);
        }
    }, [searchValue, treeObject]);

    useEffect(() => {
        if (searchValue && values?.resourceType === resourceType.KPI) {
            const matchingPanels = kpiDetails?.filter((item: any) =>
                item?.kpis?.some((kpi: any) =>
                    kpi?.kpiName
                        ?.toLowerCase()
                        ?.includes(searchValue?.toLowerCase())
                )
            );

            const matchingNodeTypeIds = matchingPanels?.map((item: any) =>
                String(item?.nodeTypeId)
            );

            setExpandedKpiKeys(matchingNodeTypeIds || []);
        }
    }, [searchValue, kpiDetails, values?.resourceType]);
    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };
    return (
        <>
            {values?.resourceType === resourceType.SCREEN && (
                <ul>
                    <Checkbox.Group
                        onChange={onChange}
                        className="checkBox-group"
                        value={checkedItems}
                    >
                        {filteredItems?.map((item: any) => (
                            <>
                                <li
                                    key={item?.resourceId}
                                    className="groupDrawer__listItem"
                                >
                                    <Checkbox
                                        value={item?.resourceId}
                                        onChange={(e) => {
                                            handleSubItemChange(
                                                item?.resourceId,
                                                e.target.checked
                                            );
                                        }}
                                    >
                                        {item?.resourceName}
                                    </Checkbox>
                                </li>
                                <Divider />
                            </>
                        ))}
                    </Checkbox.Group>
                </ul>
            )}
            {searchValue &&
                expandedKeys?.length === 0 &&
                values?.resourceType === resourceType.ASSET && (
                    <div className="noResultFoundText">
                        {t('implementation.noResultFoundInSearch')}
                    </div>
                )}
            {values?.resourceType === resourceType.ASSET &&
                treeObject?.id &&
                !viewGroup && (
                    <Tree
                        checkable
                        disabled={false}
                        treeData={filteredTreeData}
                        onCheck={onCheck}
                        checkedKeys={checkedKeys}
                        expandedKeys={expandedKeys}
                        onExpand={onExpand}
                        autoExpandParent={autoExpandParent}
                    />
                )}

            {selectedOptionData === resourceType.ASSET &&
                treeObject?.id &&
                viewGroup && (
                    <Tree
                        disabled={true}
                        checkable
                        expandedKeys={expandedKeys}
                        treeData={treeObject ? [mapTree(treeObject)] : []}
                        onCheck={onCheck}
                        autoExpandParent={autoExpandParent}
                        onExpand={onExpand}
                        checkedKeys={checkedKeys}
                    />
                )}
            {searchValue &&
                expandedKpiKeys?.length === 0 &&
                values?.resourceType === resourceType.KPI && (
                    <div className="noResultFoundText">
                        {t('implementation.noResultFoundInSearch')}
                    </div>
                )}
            {!searchValue && values?.resourceType === resourceType.KPI && (
                <Space direction="vertical" className="kpiCollapse">
                    <Collapse
                        expandIconPosition="end"
                        collapsible="icon"
                        activeKey={expandedKpiKeys}
                        onChange={(keys) => {
                            setExpandedKpiKeys(keys);
                        }}
                    >
                        {kpiDetails?.map((item: any) => (
                            <Collapse.Panel
                                className="kpiCollapsePanel"
                                key={item?.nodeTypeId}
                                header={
                                    <span>
                                        <Checkbox
                                            indeterminate={
                                                item?.kpis?.some((kpi: any) =>
                                                    checkedKpis?.some(
                                                        (checkedKpi: any) =>
                                                            checkedKpi.kpiId ===
                                                            kpi.kpiId
                                                    )
                                                ) &&
                                                !item?.kpis?.every((kpi: any) =>
                                                    checkedKpis?.some(
                                                        (checkedKpi: any) =>
                                                            checkedKpi.kpiId ===
                                                            kpi.kpiId
                                                    )
                                                )
                                            }
                                            checked={item?.kpis?.every(
                                                (kpi: any) =>
                                                    checkedKpis?.some(
                                                        (checkedKpi: any) =>
                                                            checkedKpi.kpiId ===
                                                            kpi.kpiId
                                                    )
                                            )}
                                            onChange={(e) => {
                                                handleKpiSelection(item,e.target.checked,'collapseHeader')
                                                const isChecked =
                                                    e.target.checked;
                                                const kpiIds = item?.kpis?.map(
                                                    (kpi: any) => ({
                                                        kpiId: kpi?.kpiId,
                                                        nodeTypeId:
                                                            item?.nodeTypeId,
                                                    })
                                                );

                                                if (isChecked) {
                                                    setCheckedKpis(
                                                        (prev: any) => [
                                                            ...prev,
                                                            ...kpiIds?.filter(
                                                                (newKpi: any) =>
                                                                    !prev?.some(
                                                                        (
                                                                            checkedKpi: any
                                                                        ) =>
                                                                            checkedKpi.kpiId ===
                                                                            newKpi.kpiId
                                                                    )
                                                            ),
                                                        ]
                                                    );
                                                } else {
                                                    setCheckedKpis(
                                                        (prev: any) =>
                                                            prev?.filter(
                                                                (
                                                                    checkedKpi: any
                                                                ) =>
                                                                    !kpiIds?.some(
                                                                        (
                                                                            kpi: any
                                                                        ) =>
                                                                            kpi.kpiId ===
                                                                            checkedKpi.kpiId
                                                                    )
                                                            )
                                                    );
                                                }
                                            }}
                                        />

                                        <span className="kpiHeading">
                                            {item?.nodeTypeName}
                                        </span>
                                    </span>
                                }
                            >
                                <Checkbox.Group
                                    className="kpiItemGroup"
                                    value={checkedKpis
                                        ?.filter(
                                            (checkedKpi: any) =>
                                                checkedKpi.nodeTypeId ===
                                                item?.nodeTypeId
                                        )
                                        .map(
                                            (checkedKpi: any) =>
                                                checkedKpi.kpiId
                                        )}
                                    onChange={(checkedValues) => {
                                        const selectedKpis = checkedValues.map(
                                            (kpiId: any) => ({
                                                kpiId,
                                                nodeTypeId: item?.nodeTypeId,
                                            })
                                        );

                                        setCheckedKpis((prev: any) => {
                                            const remainingKpis = prev?.filter(
                                                (kpi: any) =>
                                                    kpi.nodeTypeId !==
                                                    item.nodeTypeId
                                            );
                                            return [
                                                ...remainingKpis,
                                                ...selectedKpis,
                                            ];
                                        });
                                    }}
                                >
                                    <ul className="kpiListItem">
                                        {item?.kpis?.map((kpi: any) => (
                                            <>
                                                <li
                                                    key={kpi?.kpiId}
                                                    className="groupDrawer__listItem"
                                                >
                                                    <Checkbox
                                                        value={kpi?.kpiId}
                                                        onChange={(e) => {
                                                            handleKpiSelection(
                                                                kpi?.kpiId,
                                                                e.target.checked
                                                            );
                                                        }}
                                                    >
                                                        <span>
                                                            {kpi?.kpiName
                                                                ?.length <
                                                            20 ? (
                                                                kpi?.kpiName
                                                            ) : (
                                                                <Popover
                                                                    overlayClassName="customOverlay"
                                                                    content={
                                                                        <div className="blaName">
                                                                            {
                                                                                kpi?.kpiName
                                                                            }
                                                                        </div>
                                                                    }
                                                                    visible={
                                                                        isPopoverVisibles[
                                                                            kpi
                                                                                ?.kpiId
                                                                        ]
                                                                    }
                                                                    onVisibleChange={
                                                                        handlePopoverVisibleChanges
                                                                    }
                                                                    placement="topLeft"
                                                                >
                                                                    {kpi
                                                                        ?.kpiName
                                                                        ?.length >
                                                                    20
                                                                        ? `${kpi?.kpiName?.slice(
                                                                              0,
                                                                              20
                                                                          )}...`
                                                                        : kpi?.kpiName}
                                                                </Popover>
                                                            )}
                                                        </span>
                                                    </Checkbox>
                                                </li>
                                                <Divider />
                                            </>
                                        ))}
                                    </ul>
                                </Checkbox.Group>
                            </Collapse.Panel>
                        ))}
                    </Collapse>
                </Space>
            )}
            {searchValue && values?.resourceType === resourceType.KPI && (
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Collapse
                        accordion
                        expandIconPosition="end"
                        activeKey={expandedKpiKeys}
                        onChange={(keys) => {
                            setExpandedKpiKeys(keys);
                        }}
                    >
                        {kpiDetails
                            ?.filter((item: any) =>
                                item?.kpis?.some((kpi: any) =>
                                    kpi?.kpiName
                                        ?.toLowerCase()
                                        ?.includes(searchValue?.toLowerCase())
                                )
                            )
                            ?.map((item: any) => (
                                <Collapse.Panel
                                    className="kpiCollapsePanel"
                                    key={item?.nodeTypeId}
                                    header={
                                        <span className="kpiHeading">
                                            {item?.nodeTypeName}
                                        </span>
                                    }
                                >
                                    <Checkbox.Group
                                        className="kpiItemGroup"
                                        value={checkedKpis
                                            ?.filter(
                                                (checkedKpi: any) =>
                                                    checkedKpi.nodeTypeId ===
                                                    item?.nodeTypeId
                                            )
                                            .map(
                                                (checkedKpi: any) =>
                                                    checkedKpi.kpiId
                                            )}
                                        onChange={(checkedValues) => {
                                            const selectedKpis =
                                                checkedValues.map(
                                                    (kpiId: any) => ({
                                                        kpiId,
                                                        nodeTypeId:
                                                            item?.nodeTypeId,
                                                    })
                                                );

                                            setCheckedKpis((prev: any) => {
                                                const remainingKpis =
                                                    prev?.filter(
                                                        (kpi: any) =>
                                                            !selectedKpis?.some(
                                                                (newKpi: any) =>
                                                                    newKpi?.kpiId ===
                                                                    kpi?.kpiId
                                                            )
                                                    );
                                                return [
                                                    ...remainingKpis,
                                                    ...selectedKpis,
                                                ];
                                            });
                                        }}
                                    >
                                        <ul className="kpiListItem">
                                            {item?.kpis
                                                ?.filter((kpi: any) =>
                                                    kpi?.kpiName
                                                        ?.toLowerCase()
                                                        ?.includes(
                                                            searchValue?.toLowerCase()
                                                        )
                                                )
                                                ?.map((kpi: any) => (
                                                    <li
                                                        key={kpi?.kpiId}
                                                        className="groupDrawer__listItem"
                                                    >
                                                        <Checkbox
                                                            value={kpi?.kpiId}
                                                        >
                                                            {kpi?.kpiName}
                                                        </Checkbox>
                                                        <Divider />
                                                    </li>
                                                ))}
                                        </ul>
                                    </Checkbox.Group>
                                </Collapse.Panel>
                            ))}
                    </Collapse>
                </Space>
            )}
        </>
    );
};
export default Listing;

import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    getAlertDetailsService,
    getAlertListsService,
    getCalculationCycleListService,
    getDurationListService,
    getConsequenceListService,
    getKpiListByAssetService,
    getNotificationChannelListService,
    getOperatorByIdService,
    getOperatorsService,
    createAlertService,
    updateAlertStatusService,
    updateAlertService,
    getAlertLevelService,
} from 'redux/services/AlertServices/alertService';
import * as types from 'redux/types/alertTypes';

export function* getAlertListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAlertListData = yield call(getAlertListsService, payload);
        yield put({
            type: types.GET_ALERT_LIST_SUCCESS,
            response: getAlertListData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_ALERT_LIST_FAILURE });
    }
}

export function* getAlertDetailsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAlertData = yield call(getAlertDetailsService, payload);
        yield put({
            type: types.GET_ALERT_DETAILS_SUCCESS,
            response: getAlertData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_ALERT_DETAILS_FAILURE });
    }
}

export function* getOperatorSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getOperators = yield call(getOperatorsService, payload);
        yield put({
            type: types.GET_OPERATOR_LIST_SUCCESS,
            response: getOperators,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_OPERATOR_LIST_FAILURE });
    }
}

export function* getOperatorByIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getOperatorsbyId = yield call(getOperatorByIdService, payload);
        yield put({
            type: types.GET_OPERATOR_BY_ID_SUCCESS,
            response: getOperatorsbyId,
            payload: payload,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_OPERATOR_BY_ID_FAILURE });
    }
}

export function* getKpiListByAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getKpiData = yield call(getKpiListByAssetService, payload);
        yield put({
            type: types.GET_KPI_LIST_BY_ASSET_SUCCESS,
            response: getKpiData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_KPI_LIST_BY_ASSET_FAILURE });
    }
}

export function* getDurationListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getDurationData = yield call(getDurationListService, payload);
        yield put({
            type: types.GET_DURATION_LIST_SUCCESS,
            response: getDurationData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_DURATION_LIST_FAILURE });
    }
}

export function* getCalculationCycleListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getCalculationCycleData = yield call(
            getCalculationCycleListService,
            payload
        );
        yield put({
            type: types.GET_CALCULATION_CYCLE_LIST_SUCCESS,
            response: getCalculationCycleData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_CALCULATION_CYCLE_LIST_FAILURE });
    }
}

export function* getNotificationChannelListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getNotificationChannelData = yield call(
            getNotificationChannelListService,
            payload
        );
        yield put({
            type: types.GET_NOTIFICATION_CHANNEL_LIST_SUCCESS,
            response: getNotificationChannelData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_NOTIFICATION_CHANNEL_LIST_FAILURE });
    }
}

export function* getConsequenceListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getConsequenceList = yield call(
            getConsequenceListService,
            payload
        );
        yield put({
            type: types.GET_CONSEQUENCE_LIST_SUCCESS,
            response: getConsequenceList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}

export function* updateAlertStatusSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const paginatedPayload = {
            ...payload?.paginatedPayload,
            search: payload?.searchPayload,
        };
        const alertStatus = yield call(
            updateAlertStatusService,
            payload?.updatedPayload
        );
        yield put({
            type: types.UPDATE_ALERT_STATUS_SUCCESS,
            response: alertStatus,
        });
        if (alertStatus) {
            const alertListData = yield call(
                getAlertListsService,
                paginatedPayload
            );
            yield put({
                type: types.GET_ALERT_LIST_SUCCESS,
                response: alertListData,
            });
        }
    } catch (error) {}
}

export function* createAlertSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const setAlertDetails = yield call(createAlertService, payload);
        yield put({
            type: types.CREATE_ALERT_SUCCESS,
            response: setAlertDetails,
        });
    } catch (error: any) {
        yield put({ type: types.CREATE_ALERT_FAILURE });
    }
}

export function* updateAlertSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        const setAlertDetails = yield call(updateAlertService, payload);
        yield put({
            type: types.UPDATE_ALERT_SUCCESS,
            response: setAlertDetails,
        });
    } catch (error: any) {
        yield put({ type: types.UPDATE_ALERT_FAILURE });
    }
}

export function* getAlertLevelSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAlertLevel = yield call(getAlertLevelService, payload);
        yield put({
            type: types.GET_ALERT_LEVEL_LIST_SUCCESS,
            response: getAlertLevel,
            payload: payload,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}

export function* alertSaga(): any {
    yield all([takeLatest(types.GET_ALERT_LIST, getAlertListSaga)]);
    yield all([takeLatest(types.GET_ALERT_DETAILS, getAlertDetailsSaga)]);
    yield all([takeLatest(types.GET_OPERATOR_LIST, getOperatorSaga)]);
    yield all([takeLatest(types.GET_OPERATOR_BY_ID, getOperatorByIdSaga)]);
    yield all([takeLatest(types.GET_KPI_LIST_BY_ASSET, getKpiListByAssetSaga)]);
    yield all([takeLatest(types.GET_DURATION_LIST, getDurationListSaga)]);
    yield all([
        takeLatest(
            types.GET_CALCULATION_CYCLE_LIST,
            getCalculationCycleListSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.GET_NOTIFICATION_CHANNEL_LIST,
            getNotificationChannelListSaga
        ),
    ]);
    yield all([takeLatest(types.GET_CONSEQUENCE_LIST, getConsequenceListSaga)]);
    yield all([takeLatest(types.CREATE_ALERT, createAlertSaga)]);
    yield all([takeLatest(types.UPDATE_ALERT_STATUS, updateAlertStatusSaga)]);
    yield all([takeLatest(types.UPDATE_ALERT, updateAlertSaga)]);
    yield all([takeLatest(types.GET_ALERT_LEVEL_LIST, getAlertLevelSaga)]);
}

import React from 'react';
import { Row, Col, List } from 'antd';
import RoleSwitch from './RoleSwitch';
import { PERMISSIONS, ROLETYPE, resourceTypeID } from 'types/enums';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Screen: React.FC<any> = ({
    setKeyProp,
    keyProp,
    rolesList,
    updatePermissions,
}) => {
    const { t } = useTranslation('translation');

    const handlePermissionChange: any = (
        resourceId: string,
        permission: string,
        checked: boolean
    ) => {
        const role = rolesList?.find(
            (r: any) => r?.resource?.resourceId === resourceId
        );
        if (role) {
            let newPermissions = [...role.permission];

            if (checked) {
                if (!newPermissions?.includes(permission)) {
                    newPermissions?.push(permission);
                }
                if (
                    permission !== PERMISSIONS.read &&
                    !newPermissions?.includes(PERMISSIONS.read)
                ) {
                    newPermissions?.push(PERMISSIONS.read);
                }
            } else {
                newPermissions = newPermissions?.filter(
                    (p: string) => p !== permission
                );
                if (
                    permission !== PERMISSIONS.read &&
                    !newPermissions?.some((p) =>
                        [
                            PERMISSIONS.write,
                            PERMISSIONS.update,
                            PERMISSIONS.delete,
                        ]?.includes(p)
                    )
                ) {
                    newPermissions = newPermissions.filter(
                        (p: string) => p !== PERMISSIONS.read
                    );
                }
            }

            updatePermissions(resourceId, newPermissions);
            setKeyProp((prevKey: number) => prevKey + 1);
        }
    };
    const editRoleState = useSelector(
        (state: any) => state.userManagement.roles.editRoleState
    );
    return (
        <>
            <div className="createRolesWrapper__createRolesTableContent">
                <Row className="createRolesWrapper__createRolesTableContentRowHeader">
                    <Col span={8}>
                        <RoleSwitch
                            types="secondary"
                            text={t('roles.permissionControl')}
                            switchBoolean={false}
                            disabled={editRoleState === ROLETYPE?.viewRole}
                        />
                    </Col>

                    {Object?.values(PERMISSIONS)?.map((permission) => (
                        <Col span={4} key={permission}>
                            <RoleSwitch
                                disabled={
                                    editRoleState === ROLETYPE?.viewRole ||
                                    !rolesList?.some(
                                        (item: any) =>
                                            item?.resource?.resourceType?.id ===
                                            resourceTypeID?.SCREEN
                                    )
                                }
                                types="secondary"
                                text={
                                    permission?.charAt(0)?.toUpperCase() +
                                    permission?.slice(1)
                                }
                                key={keyProp}
                                switchBoolean={true}
                                checked={
                                    rolesList?.length > 0 &&
                                    rolesList?.some(
                                        (item: any) =>
                                            item?.resource?.resourceType?.id ===
                                                resourceTypeID?.SCREEN &&
                                            item?.permission?.includes(
                                                permission
                                            )
                                    )
                                }
                                onChangeFunction={(eventProp: boolean) => {
                                    rolesList?.forEach((role: any) => {
                                        if (
                                            role?.resource?.resourceType?.id ===
                                            resourceTypeID?.SCREEN
                                        ) {
                                            handlePermissionChange(
                                                role?.resource?.resourceId,
                                                permission,
                                                eventProp
                                            );
                                        }
                                    });
                                }}
                            />
                        </Col>
                    ))}
                </Row>
                {rolesList?.length > 0 &&
                    rolesList?.some(
                        (item: any) =>
                            item?.resource?.resourceType?.id ===
                            resourceTypeID?.SCREEN
                    ) && (
                        <List
                            className="createRolesWrapper__createRoleList"
                            split={false}
                            bordered
                            dataSource={rolesList?.filter(
                                (item: any) =>
                                    item?.resource?.resourceType?.id ===
                                    resourceTypeID?.SCREEN
                            )}
                            renderItem={(item: any) => (
                                <List.Item>
                                    <Row className="createRolesWrapper__createRolesTableContentRow">
                                        <Col span={8}>
                                            <RoleSwitch
                                                text={
                                                    item?.resource?.resourceName
                                                }
                                                switchBoolean={false}
                                                disabled={
                                                    editRoleState ===
                                                    ROLETYPE?.viewRole
                                                }
                                            />
                                        </Col>
                                        {Object.values(PERMISSIONS)?.map(
                                            (permission) => (
                                                <Col span={4} key={permission}>
                                                    <RoleSwitch
                                                        disabled={
                                                            editRoleState ===
                                                            ROLETYPE?.viewRole
                                                        }
                                                        switchBoolean={true}
                                                        key={keyProp}
                                                        checked={item?.permission?.includes(
                                                            permission
                                                        )}
                                                        onChangeFunction={(
                                                            eventProp: boolean
                                                        ) => {
                                                            handlePermissionChange(
                                                                item?.resource
                                                                    ?.resourceId,
                                                                permission,
                                                                eventProp
                                                            );
                                                        }}
                                                    />
                                                </Col>
                                            )
                                        )}
                                    </Row>
                                </List.Item>
                            )}
                        />
                    )}
            </div>
        </>
    );
};

export default Screen;

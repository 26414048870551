import { Col, Popover, Row,  Spin,  Table } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type ColumnsType } from 'antd/es/table';
import { attribute, EMPTY } from 'types/enums';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as ErrorDotIcon } from 'assets/icons/inactiveDot.svg';
import CustomPagination from 'components/common/CustomPagination';
import { MoreOutlined } from '@ant-design/icons';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import {
    QRConfigState,
    qrConfigTableColumnSort,
    sortOrderQrCode,
} from 'types/enums/qrCodeGeneration';
import { useTranslation } from 'react-i18next';
import { CreateConnectionScreen, MappingColLength } from 'types/enums/businessIntegratorEnum';
import moment from 'moment';
import MoreContent from './MoreContent';
import GenerateQRCode from './GenerateQRCode';
import { getQRConfigDetails, setQRConfigState } from 'redux/actions/QRCodeGenerationActions/qrCodeConfig';

const QRCodeConfigTable: React.FC<any> = ({
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
}): any => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<any>([]);
    const [selectedFields, setSelectedFields] = useState<any>([]);
    const [drawerOpen, setDrawerOpen] = useState<any>(false);
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const qrCodeConfigListTableList = useSelector(
        (state: any) => state.qrCodeGeneration?.qrCodeConfig?.qrConfigListData
    );
    const qrCodeConfigListLoader = useSelector(
        (state: any) => state.qrCodeGeneration?.qrCodeConfig?.qrConfigListLoading
    );
    
    
    const tableDataMapper = (): [] => {
        const temp: any = [];
        qrCodeConfigListTableList?.data?.metadataConfigs?.map(
            (item: any, index: number) => {
                temp.push({
                    ...item,
                    key: item._id,
                });
            }
        );
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [qrCodeConfigListTableList]);

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('BusinessIntegrator.connections.searchNoDataText')}
            // loading={connectionsTableLoader}
        />
    );

    const onSorting = (columnName: any, sortOrderQrCode: any): any => {
        setPage(PAGE);
        setSortColumn(columnName);
        setSortOrder(sortOrderQrCode);
    };
    const handlePopoverVisibleChange = (
        visible: boolean,
        key: string
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [key]: visible,
        }));
    };
    const columns: ColumnsType<any> = [
        {
            title: '',
            key: 'more',
            width: 26,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                visible={popoverVisible[record.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(
                                        visible,
                                        record.key
                                    );
                                }}
                                placement="bottomLeft"
                                trigger="click"
                                content={
                                    <MoreContent
                                        record={record}
                                        setPopoverVisible={setPopoverVisible}
                                        setDrawerOpen={setDrawerOpen}
                                        drawerOpen={drawerOpen}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined
                                    onClick={() => {
                                        handlePopoverVisibleChange(
                                            true,
                                            record?.key
                                        );
                                        setSelectedFields(record);
                                    }}
                                />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>QR Code Config Name</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.name,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.name,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            width: '150px',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.name?.length > MappingColLength.name
                        ? data?.name?.substring(
                              CreateConnectionScreen.adapterScreen,
                              MappingColLength.name
                          ) + '...'
                        : data?.name;
                return (
                    <>
                        <div
                            className="attributeTableWrapper__nameData"
                            onClick={() => {
                                dispatch(setQRConfigState(QRConfigState.view));
                                dispatch(getQRConfigDetails(data?._id));
                            }}
                        >
                            {data?.name?.length >
                            attribute.attributeNameLength ? (
                                <div className="blaTableWrapper__status">
                                    {
                                        data?.status === "active" ? <ActiveDotIcon/> : <ErrorDotIcon/>
                                    }
                                    <Popover
                                        overlayClassName="popoverWidth"
                                        content={data?.name}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        <span className="fs-14 fw-500 name">
                                            {truncatedText}
                                        </span>
                                    </Popover>
                                </div>
                            ) : (
                                <div className="blaTableWrapper__status">
                                    {data?.status === "active" ? (
                                        <ActiveDotIcon />
                                    ) : (
                                        <ErrorDotIcon />
                                    )}
                                    <span className="fs-14 fw-500 name">
                                        {data?.name}
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Generation Type</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.generationType,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.generationType,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'generationType',
            key: 'generationType',
            width: '120px',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.generationType}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>QR Code Image Type</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.imgType,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.imgType,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'type',
            key: 'type',
            width: '150px',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.qrCodeConfig?.type}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Width(px)</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.width,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.width,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'width',
            key: 'width',
            width: '110px',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.qrCodeConfig?.width}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Color Theme</div>
                </div>
            ),
            dataIndex: 'color',
            key: 'color',
            width: '110px',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {"Dark"}
                    </div>
                </>
            ),
        },
        {
            key: 'createdBy',
            className: 'column__addedOn',
            width: '150px',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.createdBy,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.createdBy,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record?.createdBy?.name}
                    </div>
                </>
            ),
        },
        {
            key: 'createdOn',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.createdAt,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.createdAt,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdOn',
            width: '180px',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record?.createdAt).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            key: 'changedBy',
            width: '150px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.updatedBy,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.updatedBy,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record?.updatedBy?.name}
                    </div>
                </>
            ),
        },
        {
            key: 'changedOn',
            width: '180px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.updatedAt,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrConfigTableColumnSort.updatedAt,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedOn',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record?.updatedAt).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {qrCodeConfigListLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row
                        className={
                            qrCodeConfigListTableList?.metadata
                                ?.totalCount > 50
                                ? 'attributeWrapper__attributeListPagination'
                                : 'attributeWrapper__attributeList'
                        }
                    >
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                loading={qrCodeConfigListLoader}
                                scroll={{
                                    y:
                                        qrCodeConfigListTableList?.metadata
                                                ?.totalCount > 50
                                                ? 'calc(100vh - 370px)'
                                                : 'calc(100vh - 340px)',
                                    x: '2000px',
                                }}
                                locale={{ emptyText: SearchNoDataText }}
                            />
                        </Col>
                    </Row>
                    {qrCodeConfigListTableList?.metadata?.totalCount >
                    50 ? (
                        <CustomPagination
                            totalRecords={
                                qrCodeConfigListTableList?.metadata
                                    ?.totalCount
                            }
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    ) : (
                        EMPTY.string
                    )}
                    {drawerOpen &&
                        <GenerateQRCode
                            setDrawerOpen={setDrawerOpen}
                            open={drawerOpen}
                            selectedFields={selectedFields}
                            onClose={() => {
                                setDrawerOpen(false);
                            }}
                        />
                    }
                </>
            )}
        </>
    );
};

export default QRCodeConfigTable;

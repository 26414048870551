import React, { useEffect, useState } from 'react';
import { Col, Row, DatePicker, Select, Cascader } from 'antd';
import './index.scss';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import AuditTable from './AuditTable';
import CustomButton from 'components/common/CustomButton';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAuditTrailsList,
    getModulesConfigurationList,
    getModulesList,
} from 'redux/actions/AuditTrailsActions/auditTrailsAction';
import { dateFormat } from 'types/enums';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import { getAllUsers } from 'redux/actions/UserManagementActions/usersAction';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { SET_AUDIT_LIST_EMPTY } from 'redux/types/auditTrailsTypes';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import CustomPagination from 'components/common/CustomPagination';
import { toTitleCase } from 'utils/commonFunction';

const AuditTrails: React.FC = () => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const { SHOW_CHILD } = Cascader;
    // TODO- IF in future we need to set default date range for 24hrs
    // const now = dayjs();
    // const [startTime, setStartTime] = useState<any>(now.subtract(24, 'hour'));
    // const [endTime, setEndTime] = useState<any>(now);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [users, setUsers] = useState([]);
    const [userList, setUserList] = useState([]);
    const [module, setModule] = useState<Number | null>(null);
    const [moduleEntity, setModuleEntity] = useState<any>([]);
    const [reset, setReset] = useState(false);
    const [operation, setOperation] = useState<any>([]);
    const { t } = useTranslation('translation');
    const { currentTab } = useParams();
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const allUsersList = useSelector(
        (state: any) => state.userManagement.users.allUsersList
    );
    const moduleList = useSelector(
        (state: any) => state.auditTrails.moduleList
    );
    const moduleConfigurationList = useSelector(
        (state: any) => state.auditTrails.moduleConfigurationList
    );
    const auditList = useSelector((state: any) => state.auditTrails.auditList);

    useEffect(() => {
        dispatch({ type: SET_AUDIT_LIST_EMPTY });
    }, [currentTab]);
    useEffect(() => {
        dispatch(getModulesList());
        dispatch(getAllUsers());
    }, []);

    const handlePage = (page: any): any => {
        setPage(page);
    };

    const handlePageSize = (current: any): any => {
        setPageSize(current);
    };

    useEffect(() => {
        const options = allUsersList?.map((item: any) => {
            return {
                value: item?.userId,
                label: `${item?.firstName} ${item?.lastName}`,
            };
        });
        setUserList(options);
    }, [allUsersList]);

    useEffect(() => {
        dispatch(
            getAuditTrailsList({
                ...(startTime ? { startTime: Date.parse(startTime) } : {}),
                ...(endTime ? { endTime: Date.parse(endTime) } : {}),
                moduleId: module,
                ...(users.length > 0 ? { userId: users } : {}),
                ...(operation.length > 0 ? { action: operation } : {}),
                pageNumber: page,
                pageSize: pageSize,
                sortColumn,
                sortOrder,
            })
        );
    }, [page, pageSize]);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const moduleParam = queryParams.get('module');
    const moduleEntityParam = queryParams.get('moduleEntity');

    useEffect(() => {
        if (moduleParam && moduleEntityParam) {
            setModule(Number(moduleParam));
            dispatch(getModulesConfigurationList(Number(moduleParam)));
            dispatch(
                getAuditTrailsList({
                    ...(startTime ? { startTime: Date.parse(startTime) } : {}),
                    ...(endTime ? { endTime: Date.parse(endTime) } : {}),
                    moduleId: Number(moduleParam),
                    ...(users.length > 0 ? { userId: users } : {}),
                    ...(operation.length > 0 ? { action: operation } : {}),
                    pageNumber: page,
                    pageSize: pageSize,
                    sortColumn,
                    sortOrder,
                    ...(moduleEntityParam.length > 0
                        ? { moduleVariableId: moduleEntityParam }
                        : {}),
                })
            );
        }
    }, [moduleParam, moduleEntityParam]);

    const onOk = (value: any): void => {
        value[0] && setStartTime(dayjs(value[0], dateFormat.format));
        value[1] && setEndTime(dayjs(value[1], dateFormat.format));
    };
    const resetFilter = (): void => {
        // TODO- IF in future we need to set default date range for 24hrs
        // setStartTime(dayjs().subtract(24, 'hour'));
        // setEndTime(dayjs());
        setStartTime(null);
        setEndTime(null);
        setUsers([]);
        setModule(null);
        setModuleEntity([]);
        setOperation(null);
        setReset(!reset);
       dispatch(
            getAuditTrailsList({
                ...(startTime ? { startTime: Date.parse(startTime) } : {}),
                ...(endTime ? { endTime: Date.parse(endTime) } : {}),
                pageNumber: page,
                pageSize: pageSize,
                sortColumn,
                sortOrder,
            })
        );
    };
    const onApply = (): void => {
        dispatch(
            getAuditTrailsList({
                ...(startTime ? { startTime: Date.parse(startTime) } : {}),
                ...(endTime ? { endTime: Date.parse(endTime) } : {}),
                moduleId: module,
                ...(users?.length > 0 ? { userId: users } : {}),
                ...(operation?.length > 0 ? { action: operation } : {}),
                pageNumber: PAGE,
                pageSize: PAGE_SIZE,
                moduleVariableId: moduleEntity,
                sortColumn,
                sortOrder,
            })
        );
    };
    const filter = (inputValue: any, path: any): any =>
        path?.some((option: any): any =>
            (option?.label as string)
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase())
        );
    const operationList = [
        {
            value: 1,
            label: t('auditTrails.create'),
        },
        {
            value: 2,
            label: t('auditTrails.edit'),
        },
        {
            value: 3,
            label: t('auditTrails.delete'),
        },
    ];

    const handleOperation = (value: any): any => {
        setOperation(value?.flat());
    };
    return (
        <>
            <div className="auditTrailWrapper">
                <Row className="auditTrailWrapper__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t('auditTrails.auditTrails')}
                        />
                    </Col>
                </Row>
                <Row className="auditTrailWrapper__filterWrapper">
                    <Col span={8}>
                        <RangePicker
                            allowClear={false}
                            showTime
                            onOk={onOk}
                            value={[startTime, endTime]}
                        />
                    </Col>
                    <Col span={4} className="userCascader">
                        <Cascader
                            key={`${reset}`}
                            multiple
                            maxTagCount="responsive"
                            options={userList}
                            onChange={(value: any) => {
                                setUsers(value?.flat());
                            }}
                            placeholder={t('auditTrails.users')}
                            showSearch={{ filter }}
                            showCheckedStrategy={SHOW_CHILD}
                            onSearch={(value) => {}}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            allowClear
                            placeholder={t('auditTrails.module')}
                            options={moduleList?.map((item: any) => {
                                return {
                                    value: item?.id,
                                    label: toTitleCase(item?.name),
                                };
                            })}
                            value={module}
                            onChange={(value) => {
                                setModule(value);
                                value &&
                                    dispatch(
                                        getModulesConfigurationList(value)
                                    );
                            }}
                        />
                    </Col>
                    <Col span={4}>
                        <Cascader
                            key={`${reset}`}
                            multiple
                            disabled={!module}
                            maxTagCount="responsive"
                            options={moduleConfigurationList?.map(
                                (item: any) => {
                                    return {
                                        value: item?.moduleId,
                                        label: item?.entityVariableName,
                                    };
                                }
                            )}
                            onChange={(value: any) => {
                                setModuleEntity(value?.flat());
                            }}
                            placeholder={t('auditTrails.moduleEntity')}
                            showSearch={{ filter }}
                            showCheckedStrategy={SHOW_CHILD}
                            onSearch={(value) => {}}
                        />
                    </Col>
                    <Col span={4}>
                        <Cascader
                            key={`${reset}`}
                            maxTagCount="responsive"
                            multiple
                            options={operationList}
                            placeholder={t('auditTrails.operationType')}
                            // value={operation}
                            onChange={handleOperation}
                        />
                    </Col>
                    <Col span={2}>
                        <CustomButton
                            type="Apply"
                            disabled={false}
                            // TODO- IF in future we need to set default date range mandatory
                            // disabled={!startTime || !endTime}
                            handleClick={onApply}
                        />
                    </Col>
                    <Col span={1}>
                        <span
                            className="customHeader__child-resetButton"
                            onClick={resetFilter}
                        >
                            <ResetIcon />
                        </span>
                    </Col>
                </Row>
                <Row className={'Container__tabswrapper fw-500 fs-16'}>
                    <AuditTable
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortOrder={setSortOrder}
                        auditList={auditList?.pageResponse?.records}
                        payload={{
                            startTime:startTime? Date.parse(startTime) : null,
                            endTime: endTime ? Date.parse(endTime):null,
                            module: module,
                            userId: users,
                            action: operation,
                            pageNumber: PAGE,
                            pageSize: PAGE_SIZE,
                        }}
                        pagable={
                            auditList?.pageResponse?.totalRecords > PAGE_SIZE
                        }
                    />
                </Row>
                {auditList?.pageResponse?.totalRecords > PAGE_SIZE ? (
                    <CustomPagination
                        totalRecords={auditList?.pageResponse?.totalRecords}
                        setPage={handlePage}
                        page={page}
                        setPageSize={handlePageSize}
                        pageSize={pageSize}
                    />
                ) : (
                    ''
                )}
            </div>
        </>
    );
};
export default AuditTrails;

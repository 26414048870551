import { Card, Col, Divider, Input, Popover, Row, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { ReactComponent as FilterIcon } from 'assets/icons/filterIcon.svg';
import LDAPServiceListing from './ldapServiceListing';
import { useDispatch, useSelector } from 'react-redux';
import {
    LDAPConfiguratorState,
    LDAPSortEnum,
} from 'types/enums/ldapConfiguratorEnum';
import {
    getLdapServiceList,
    setLdapState,
} from 'redux/actions/LdapConfigurator/ldapConfiguratorActions';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { EMPTY } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

const LDAPScreen: React.FC = (): any => {
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();

    const [searchState, setSearchState] = useState<any>(EMPTY.string);
    const [ldapSearchTimeout, setLdapSearchTimeout] = useState<any>(undefined);
    const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
    const [selectedFilter, setSelectedFilter] = useState<string>(EMPTY.string);

    const ldapServiceList = useSelector(
        (state: any) => state.ldapConfigurator?.ldapServiceList
    );
    const ldapServiceLoader = useSelector(
        (state: any) => state.ldapConfigurator?.ldapServiceLoader
    );
    const ldapCountList = useSelector(
        (state: any) => state.ldapConfigurator?.ldapCountList
    );
    const ldapUsersListLoader = useSelector(
        (state: any) => state.ldapConfigurator?.ldapUsersListLoader
    );

    const handleFilterClick = (filterOptions: any, filterName: string): any => {
        dispatch(getLdapServiceList(filterOptions));
        setSelectedFilter(filterName);
        setPopoverVisible(false);
    };

    const content = (
        <div className="filterPopover">
            <p
                className={`cursor-pointer ${
                    selectedFilter === LDAPSortEnum.recentlyCreated
                        ? 'selected-filter'
                        : EMPTY.string
                }`}
                onClick={() =>
                    handleFilterClick(
                        { recentlyCreated: true, search: searchState },
                        LDAPSortEnum.recentlyCreated
                    )
                }
            >
                {t('ldapConfigurator.SortRecentlycreated')}
            </p>
            <p
                className={`cursor-pointer ${
                    selectedFilter === LDAPSortEnum.lastModified
                        ? 'selected-filter'
                        : EMPTY.string
                }`}
                onClick={() =>
                    handleFilterClick(
                        { lastModified: true, search: searchState },
                        LDAPSortEnum.lastModified
                    )
                }
            >
                {t('ldapConfigurator.SortLastModified')}
            </p>
            <p
                className={`cursor-pointer ${
                    selectedFilter === LDAPSortEnum.oldToNew
                        ? 'selected-filter'
                        : EMPTY.string
                }`}
                onClick={() =>
                    handleFilterClick(
                        { recentlyCreated: false, search: searchState },
                        LDAPSortEnum.oldToNew
                    )
                }
            >
                {t('ldapConfigurator.SortOldToNewlyCreated')}
            </p>
            <Divider />
            <p
                className={`cursor-pointer ${
                    selectedFilter === LDAPSortEnum.connectedOnly
                        ? 'selected-filter'
                        : EMPTY.string
                }`}
                onClick={() =>
                    handleFilterClick(
                        { isConnected: true, search: searchState },
                        LDAPSortEnum.connectedOnly
                    )
                }
            >
                {t('ldapConfigurator.ShowConnectedOnly')}
            </p>
            <p
                className={`cursor-pointer ${
                    selectedFilter === LDAPSortEnum.disconnectedOnly
                        ? 'selected-filter'
                        : EMPTY.string
                }`}
                onClick={() =>
                    handleFilterClick(
                        { isConnected: false, search: searchState },
                        LDAPSortEnum.disconnectedOnly
                    )
                }
            >
                {t('ldapConfigurator.ShowDisconnectedOnly')}
            </p>
        </div>
    );
    return (
        <>
            <div className="uomConfigurator__content">
                <Card bordered={false} className="uomConfigurator__card">
                    {ldapUsersListLoader && (
                        <div className="overlay">
                            <Spin size="large" />
                        </div>
                    )}
                    <div
                        className={`uomConfigurator__data ${
                            ldapUsersListLoader ? 'dimmed' : 'listPage'
                        }`}
                    >
                        {ldapCountList.totalConnectedServer +
                            ldapCountList.totalDisconnectedServer >
                        0 ? (
                            <>
                                <div className="uomConfigurator__rowHeader">
                                    <div className="display-flex gap">
                                        <Input
                                            allowClear
                                            className="uomConfigurator__search"
                                            placeholder={t(
                                                'ldapConfigurator.searchPlaceHolder'
                                            )}
                                            prefix={<SearchOutlined />}
                                            value={searchState} // TODO: search functionality will implement here
                                            onChange={(e) => {
                                                setSearchState(e.target.value);
                                                if (ldapSearchTimeout) {
                                                    clearTimeout(
                                                        ldapSearchTimeout
                                                    );
                                                }
                                                // Debouncing for search implemented
                                                setLdapSearchTimeout(
                                                    setTimeout(() => {
                                                        dispatch(
                                                            getLdapServiceList({
                                                                search: e.target
                                                                    .value,
                                                            })
                                                        );
                                                    }, 1000)
                                                );
                                            }}
                                        />
                                        <Popover
                                            content={content}
                                            placement="bottom"
                                            trigger="click"
                                            visible={popoverVisible}
                                            onVisibleChange={(visible) => {
                                                setPopoverVisible(visible);
                                            }}
                                        >
                                            <div className="ldapConfigurator__filter cursor-pointer">
                                                <FilterIcon />
                                            </div>
                                        </Popover>
                                    </div>
                                    <div className="ldapConfigurator__button">
                                        <CustomButton
                                            type={t(
                                                'ldapConfigurator.createLdapBtn'
                                            )}
                                            disabled={false}
                                            handleClick={() => {
                                                dispatch(
                                                    setLdapState(
                                                        LDAPConfiguratorState.addLdapConfigurator
                                                    )
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                                {ldapServiceLoader ? (
                                    <div className="view__loader">
                                        <Spin />
                                    </div>
                                ) : ldapServiceList.length > 0 ? (
                                    <Row className={'ldapServiceListing__List'}>
                                        <Col span={24}>
                                            <LDAPServiceListing />
                                        </Col>
                                    </Row>
                                ) : (
                                    <div className="uomConfigurator__tableEmpty">
                                        {searchState !== EMPTY.string && (
                                            <EmptyDataComponent textValue="No Results, Try a different search item" />
                                        )}
                                    </div>
                                )}
                                {/* {} */}
                            </>
                        ) : (
                            <div className="uomConfigurator__tableEmpty">
                                <EmptyDataComponent
                                    textValue={EMPTY.string}
                                    buttonType={{
                                        name: t(
                                            'ldapConfigurator.createLdapBtn'
                                        ),
                                        disable: false,
                                    }}
                                    bulkImport={false}
                                    buttonClickHandler={() => {
                                        dispatch(
                                            setLdapState(
                                                LDAPConfiguratorState.addLdapConfigurator
                                            )
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </Card>
            </div>
        </>
    );
};

export default LDAPScreen;

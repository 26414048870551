import ldapConfiguratorInitialState from "../ldapConfiguratorInitialState";
import * as types from "redux/types/ldapConfiguratorTypes";

export default function ldapConfiguratorReducer(
  state = ldapConfiguratorInitialState.ldapConfigurator,
  action: any
): any {
  switch (action.type) {
    case types.SET_LDAP_CONFIGURATOR_STATE:
      state = {
        ...state,
        ldapConfiguratorState: action.payload,
        ldapServiceCreated: false,
        ldapTestSuccess: {},
      };
      return state;
    case types.GET_LDAP_SERVICE_LIST:
      state = {
        ...state,
        ldapServiceLoader: true,
      };
      return state;
    case types.GET_LDAP_SERVICE_LIST_SUCCESS:
      state = {
        ...state,
        ldapServiceList: action.response.data,
        ldapServiceLoader: false,
      };
      return state;
    case types.GET_LDAP_SERVICE_LIST_FAILURE:
      state = {
        ...state,
        ldapServiceLoader: false,
      };
      return state;
    case types.GET_LDAP_USERS_LIST:
      state = {
        ...state,
        ldapUsersListLoader: true,
      };
      return state;
    case types.GET_LDAP_USERS_LIST_SUCCESS:
      state = {
        ...state,
        ldapUsersList: action.response.data,
        ldapUsersListLoader: false,
      };
      return state;
    case types.GET_LDAP_USERS_LIST_FAILURE:
      state = {
        ...state,
        ldapUsersList: action.response.data,
        ldapUsersListLoader: false,
      };
      return state;
    case types.GET_LDAP_TYPE_OF_IDENTITY_LIST_SUCCESS:
      state = {
        ...state,
        ldapTypeOfIdentityList: action.response.data,
      };
      return state;
    case types.TEST_LDAP_CONFIGURATOR:
      state = {
        ...state,
        ldapTestSuccess: {},
      };
      return state;
    case types.TEST_LDAP_CONFIGURATOR_SUCCESS:
      state = {
        ...state,
        ldapTestSuccess: action.response.data,
      };
      return state;
    case types.TEST_LDAP_CONFIGURATOR_FAILURE:
      state = {
        ...state,
        ldapTestSuccess: {},
      };
      return state;
    case types.CREATE_LDAP_CONFIGURATOR_SETTINGS:
      state = {
        ...state,
        ldapServiceCreated: false,
      };
      return state;
    case types.CREATE_LDAP_CONFIGURATOR_SETTINGS_SUCCESS:
      state = {
        ...state,
        ldapServiceCreated: true,
      };
      return state;
    case types.CREATE_LDAP_CONFIGURATOR_SETTINGS_FAILURE:
      state = {
        ...state,
        ldapServiceCreated: false,
      };
      return state;
    case types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID:
      state = {
        ...state,
        getLdapServiceConfiguratorDetailsById: {},
      };
      return state;
    case types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_SUCCESS:
      state = {
        ...state,
        getLdapServiceConfiguratorDetailsById: action.response.data,
      };
      return state;
    case types.GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_FAILURE:
      state = {
        ...state,
        getLdapServiceConfiguratorDetailsById: {},
      };
      return state;
    case types.GET_LDAP_COUNT:
      state = {
        ...state,
        ldapCountList: [],
      };
      return state;
    case types.GET_LDAP_COUNT_SUCCESS:
      state = {
        ...state,
        ldapCountList: action.response.data,
      };
      return state;
    case types.GET_LDAP_COUNT_FAILURE:
      state = {
        ...state,
        ldapCountList: [],
      };
      return state;
    case types.DELETE_LDAP_SERVICE:
      state = {
        ...state,
        activateDeactivateStatus: false,
      };
      return state;
    case types.DELETE_LDAP_SERVICE_SUCCESS:
      state = {
        ...state,
        activateDeactivateStatus: true,
      };
      return state;
    case types.DELETE_LDAP_SERVICE_FAILURE:
      state = {
        ...state,
        activateDeactivateStatus: false,
      };
      return state;
    case types.CLEAR_LDAP_SERVICE_STATUS:
      state = {
        ...state,
        activateDeactivateStatus: false,
      };
      return state;
    default:
      return state;
  }
}

// import { EMPTY } from "types/enums";
import initialState from "../initialStates";
import * as types from "redux/types/qrCodeTypes";

export default function QRCodeGenerationReducer(
  state = initialState.qrCodeGeneration,
  action: any
): any {
  switch (action.type) {   
    case types.GET_QRCODELOGS_LIST:
      state = {
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: true,
        },
      };
      return state;
    case types.GET_QRCODELOGS_LIST_SUCCESS:
      state = {
       
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: false,
          qrCodeLogsListTableList: action.response.data
        },
      };
      return state;
    case types.GET_QRCODELOGS_LIST_FAILURE:
      state = {
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: false,
        },
      };
      return state;
      case types.GET_QRCODELOGSLIST_BYID:
      state = {
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: true,
        },
      };
      return state;
    case types.GET_QRCODELOGSLIST_BYID_SUCCESS:
      state = {
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: false,
          qrCodeLogsListById:action.response.data,
        },
      };
      return state;
    case types.GET_QRCODELOGSLIST_BYID_FAILURE:
      state = {
        ...state,
        qrCodeLogs: {
          ...state.qrCodeLogs,
          qrCodeTableLoader: false,
        },
      };
      return state;

      case types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED:
        state = {
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
  
          },
        };
        return state;
      case types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED_SUCCESS:
        state = {
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
            qrCodeLogsListWithoutPagination:action.response.data,
          },
        };
        return state;
      case types.GET_QRCODELOGSLIST_WITHOUT_PAGINATED_FAILURE:
        state = {
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
          },
        };
        return state;
        case types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED:
        state = {
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
  
          },
        };
        return state;
      case types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_SUCCESS:
   
        state = {   
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
            qrCodeConfigListWithoutPagination:action?.response?.data,
          },
        };
        return state;
      case types.GET_QR_CONFIG_LIST_WITHOUT_PAGINATED_FAILURE:
        state = {
          ...state,
          qrCodeLogs: {
            ...state.qrCodeLogs,
          },
        };
        return state;
    case types.SET_CONGIF_STATE:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigState: action?.payload,
        },
      };
      return state;
    case types.GET_IMAGE_TYPE_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrImageType: action?.response?.data?.data,
        },
      };
      return state;
    case types.GET_QR_DATATYPE_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrDatatype: action?.response?.data?.data,
        },
      };
      return state;
    case types.CREATE_QR_CONFIG_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigCreated: true,
        },
      };
      return state;
    case types.GET_QR_CONFIG_LIST:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigListLoading: true,
        },
      };
      return state;
    case types.GET_QR_CONFIG_LIST_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigListData: action?.response?.data,
          qrConfigListLoading: false,
        },
      };
      return state;
    case types.GET_QR_CONFIG_LIST_FAILURE:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigListLoading: false,
        },
      };
      return state;
    case types.GENERATE_QR_CODE_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrCodeGenerated: true,
        },
      };
      return state;
    case types.RESET_CONGIF_STATES:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigCreated: false,
          qrCodeGenerated: false,
          qrConfigStatusUpdated: false,
        },
      };
      return state;
    case types.UPDATE_QR_CONFIG_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigUpdated: true,
        },
      };
      return state;
    case types.GET_QR_CONFIG_DETAILS_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigDetails: action?.response?.data,
        },
      };
      return state;
    case types.SET_QR_CONFIG_STATUS_SUCCESS:
      state = {
        ...state,
        qrCodeConfig: {
          ...state.qrCodeConfig,
          qrConfigStatusUpdated: true,
        },
      };
      return state;
    default:
      return state;
  }
}

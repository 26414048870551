import './index.scss';
import {
    MoreContentEnum,
} from 'types/enums/businessIntegratorEnum';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as ActivateIcon } from 'assets/icons/blaActivateIcon.svg';
import { ReactComponent as DeactiveIcon } from 'assets/icons/blaDeactivateIcon.svg';
import { getQRConfigDetails, setQRConfigState, setQRConfigStatus } from 'redux/actions/QRCodeGenerationActions/qrCodeConfig';
import { QRConfigState } from 'types/enums/qrCodeGeneration';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { useEffect, useState } from 'react';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { cancelHandle } from 'utils/modalFunction';
import { RESET_CONGIF_STATES } from 'redux/types/qrCodeTypes';

const MoreContent: React.FC<{
    record: any;
    setPopoverVisible: Function;
    setDrawerOpen: Function;
    drawerOpen: any;
}> = ({ record, setDrawerOpen }) => {
    const dispatch = useDispatch();
    const [deactivetModalOpen, setDeactivateModalOpen] = useState(false);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);

    const values = [
        {
            title: MoreContentEnum.edit,
            disable: false,
        },
        {
            title: record?.status === "active" ? MoreContentEnum.deactivate : MoreContentEnum.activate,
            disable: false,
        },
        {
            title: MoreContentEnum.generate,
            disable: false,
        },
    ];

    // To hide generate functionality in system generated case
    if (record?.generationType === "System Generated") {
        values.splice(2, 1);
    }
    // To hide edit functionality in inactive case
    if (record?.status === "inactive") {
        values.splice(2, 1);
        values.splice(0, 1);
    }
    const qrConfigStatusUpdated = useSelector(
        (state: any) => state?.qrCodeGeneration?.qrCodeConfig?.qrConfigStatusUpdated
    );

    useEffect(() => {
        if (qrConfigStatusUpdated) {
            setActiveSuccessModalOpen(true);
        }
    }, [qrConfigStatusUpdated]);

    const onOkHandler = (): any => {
        dispatch({ type : RESET_CONGIF_STATES})
        setActiveSuccessModalOpen(false);
    };

    const handleClick = (viewType: any): any => {
        if (viewType === MoreContentEnum.edit) {
            dispatch(setQRConfigState(QRConfigState.edit));
            dispatch(getQRConfigDetails(record?._id));
        } 
        else if (viewType === MoreContentEnum.generate) {
            setDrawerOpen(true);
        }
        else if (viewType === MoreContentEnum.deactivate || viewType === MoreContentEnum.activate) {
            setDeactivateModalOpen(true);
        }
    };

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values?.map((item: any) => {
                            return (
                                <>
                                    <li
                                        key={item.title}
                                        className={`moreContent__items  ${
                                            item.disable ? 'display-none' : null
                                        }`}
                                        onClick={() => {
                                            handleClick(item.title);
                                        }}
                                    >
                                        <span className="moreContent__icon">
                                            {item.icon}
                                        </span>
                                        <span className="moreContent__option">
                                            {item.title}
                                        </span>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {deactivetModalOpen && (
                <ConfirmationModal
                    icon={record?.status === "active" ? <DeactiveIcon /> : <ActivateIcon />}
                    open={deactivetModalOpen}
                    onOk={() => {
                        dispatch(setQRConfigStatus(record?._id));
                        setDeactivateModalOpen(false);
                    }}
                    onCancel={() => {
                        setDeactivateModalOpen(false);
                    }}
                    text={record?.status === "active" ? "Are you sure you want to deactivate the QR Config?" : "Are you sure you want to activate the QR Config?"}
                />
            )}
            <SuccessfulModal
                open={activeSuccessModalOpen}
                onOk={() => onOkHandler()}
                onCancel={() => {
                    cancelHandle(
                        activeSuccessModalOpen,
                        setActiveSuccessModalOpen
                    );
                    dispatch({ type : RESET_CONGIF_STATES});
                }
                }
                text={record?.status === "active" ? "Deactivated Successfully" : "Activated Successfully"}
            />
        </>
    );
};

export default MoreContent;

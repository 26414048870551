import { Col, Popover, Row, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { type ColumnsType } from 'antd/es/table';
import { EMPTY } from 'types/enums';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as InactiveDotIcon } from 'assets/icons/inactiveDot.svg';
import CustomPagination from 'components/common/CustomPagination';

import {
    qrName,
    qrTableColumnSort,
    sortOrderQrCode,
} from 'types/enums/qrCodeGeneration';

import PrintQRCode from './PrintQRCode';
import moment from 'moment';

const QRCodeLogsTable: React.FC<any> = ({
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
}): any => {
    const [tableData, setTableData] = useState<any>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedQRCodeData, setSelectedQRCodeData] = useState<any>(null);

    const qrCodeLogsListTableList = useSelector(
        (state: any) =>
            state.qrCodeGeneration?.qrCodeLogs?.qrCodeLogsListTableList
    );
    // will use this later
    // const qrCodeLogsListById = useSelector(
    //     (state: any) =>
    //         state.qrCodeGeneration?.qrCodeLogs?.qrCodeLogsListById
    // );

    const qrTableLoader = useSelector(
        (state: any) => state.qrCodeGeneration?.qrCodeLogs?.qrCodeTableLoader
    );

    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };
    const tableDataMapper = (): [] => {
        const temp: any = [];
        qrCodeLogsListTableList?.data?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.qrCode,
            });
        });
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [qrCodeLogsListTableList]);

    const onSorting = (columnName: any, sortOrder: any): any => {
        setPage(PAGE);
        setSortColumn(columnName);
        setSortOrder(sortOrder);
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>QR Code</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.qrCode,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.qrCode,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'qrCode',
            key: 'qrCode',
            className: 'column__name',
            width: '110px',
            render: (_: any, data: any) => {
                return (
                    <>
                        <div className="nameColumn">
                            <div className="nameStatus">
                                {data.status ? (
                                    <>
                                        <ActiveDotIcon />
                                    </>
                                ) : (
                                    <InactiveDotIcon />
                                )}
                                <span className="fs-14 fw-500">
                                    {data.uniqueData?.length <
                                    qrName.qrNameLength ? (
                                        <>
                                            <span>{data?.uniqueData}</span>
                                        </>
                                    ) : (
                                        <>
                                            <Popover
                                                overlayClassName="customOverlay"
                                                content={
                                                    <div className="blaName">
                                                        {data?.uniqueData}
                                                    </div>
                                                }
                                                visible={
                                                    isPopoverVisibles[data?.key]
                                                }
                                                onVisibleChange={
                                                    handlePopoverVisibleChanges
                                                }
                                                placement="topLeft"
                                            >
                                                {data?.uniqueData?.length >
                                                qrName.qrNameLength
                                                    ? `${data?.uniqueData?.slice(
                                                          0,
                                                          qrName.qrNameLength
                                                      )}...`
                                                    : data?.uniqueData}
                                            </Popover>
                                        </>
                                    )}
                                </span>
                            </div>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Config Name</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.configName,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.configName,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: '100px',
            dataIndex: 'configName',
            key: 'configName',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        <span className="fs-14 fw-500 name">
                            {data?.metadataConfig?.name?.length <
                            qrName.qrCodeConfigLength ? (
                                <>
                                    <span> {data?.metadataConfig?.name}</span>
                                </>
                            ) : (
                                <>
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {data?.metadataConfig?.name}
                                            </div>
                                        }
                                        visible={isPopoverVisibles[data?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {data?.metadataConfig?.name?.length >
                                        qrName.qrCodeConfigLength
                                            ? `${data?.metadataConfig?.name?.slice(
                                                  0,
                                                  qrName.qrCodeConfigLength
                                              )}...`
                                            : data?.metadataConfig?.name}
                                    </Popover>
                                </>
                            )}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Type</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.type,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.type,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'type',
            key: 'type',
            width: '80px',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data.type}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>QR Code Text</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.qrCodetext,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.qrCodetext,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'qrCodeText',
            key: 'qrCodeText',
            className: 'column__properties',
            width: '210px',

            render: (_: any, data: any) => {
                return (
                    <>
                        <div className="attributeTableWrapper__subItems">
                            <span className="fs-14 fw-500 name">
                                {data.qrCodeContent?.length <
                                qrName.qrCodeText ? (
                                    <>
                                        <span>{data?.qrCodeContent}</span>
                                    </>
                                ) : (
                                    <>
                                        <Popover
                                            overlayClassName="customOverlay"
                                            content={
                                                <div className="blaName">
                                                    {data?.qrCodeContent}
                                                </div>
                                            }
                                            visible={
                                                isPopoverVisibles[data?.key]
                                            }
                                            onVisibleChange={
                                                handlePopoverVisibleChanges
                                            }
                                            placement="topLeft"
                                        >
                                            {data?.qrCodeContent?.length >
                                            qrName.qrCodeText
                                                ? `${data?.qrCodeContent?.slice(
                                                      0,
                                                      qrName.qrCodeText
                                                  )}...`
                                                : data?.qrCodeContent}
                                        </Popover>
                                    </>
                                )}
                            </span>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Generate Time</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.generateTime,
                                    sortOrderQrCode.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    qrTableColumnSort.generateTime,
                                    sortOrderQrCode.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'generateTime',
            key: 'generateTime',
            width: '100px',
            className: 'column__uom',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {moment(data?.createdAt).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Re-print</div>
                </div>
            ),
            dataIndex: 'rePrint',
            key: 'rePrint',
            width: '67px',
            className: 'column__rePrint',
            render: (_: any, data: any) => {
                return (
                    <div
                        className="timeCapsuleTableWrapper__subItems"
                        onClick={() => {
                            setSelectedQRCodeData(data);
                            setDrawerOpen(true);
                        }}
                    >
                        Show
                    </div>
                );
            },
        },
    ];

    return (
        <>
            {qrTableLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row
                        className={
                            qrCodeLogsListTableList?.metadata?.totalCount > 50
                                ? 'attributeWrapper__attributeListPagination'
                                : 'attributeWrapper__attributeList'
                        }
                    >
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                loading={qrTableLoader}
                                scroll={{
                                    y:
                                        qrCodeLogsListTableList?.metadata
                                            ?.totalCount > 50
                                            ? 'calc(100vh - 390px)'
                                            : 'calc(100vh - 360px)',
                                    x: '200px',
                                }}
                            />
                        </Col>
                    </Row>
                    {qrCodeLogsListTableList?.metadata?.totalCount > 50 ? (
                        <CustomPagination
                            totalRecords={
                                qrCodeLogsListTableList?.metadata?.totalCount
                            }
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    ) : (
                        EMPTY.string
                    )}
                    <PrintQRCode
                        open={drawerOpen}
                        qrCodeData={selectedQRCodeData}
                        onClose={() => {
                            setDrawerOpen(false);
                        }}
                    />
                </>
            )}
        </>
    );
};

export default QRCodeLogsTable;

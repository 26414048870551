import Table, { type ColumnsType } from 'antd/es/table';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { ROLETYPE, kpiDetailsTable, sortOrder } from 'types/enums';
import { useTranslation } from 'react-i18next';

const Kpi: React.FC<any> = ({ setSortColumn, setSortOrder }) => {
    const { t } = useTranslation('translation');

    const allKpiDetails = useSelector(
        (state: any) =>
            state?.configure?.kpi?.allKpiDetails?.pageResponse?.records
    );

    const [tableData, setTableData] = useState<any>([]);
    const tableDataMapper = (): [] => {
        const temp: any = [];
        allKpiDetails?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item?.id,
            });
        });
        return temp;
    };

    useEffect(() => {
        setTableData(tableDataMapper());
    }, [tableData]);
    const editRoleState = useSelector(
        (state: any) => state.userManagement.roles.editRoleState
    );
    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.kpisName')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(kpiDetailsTable?.KPI_NAME);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(kpiDetailsTable?.KPI_NAME);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__nameData">
                        {record?.name}
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.description')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(kpiDetailsTable?.KPI_DESCRIPTION);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(kpiDetailsTable?.KPI_DESCRIPTION);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            className: 'column__description',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {record?.description}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.type')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(kpiDetailsTable?.KPI_TYPE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(kpiDetailsTable?.KPI_TYPE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'type',
            key: 'type',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {record?.kpiType?.name}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.nodelLevel')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(kpiDetailsTable?.NODE_LEVEL);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(kpiDetailsTable?.NODE_LEVEL);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'nodeLevel',
            dataIndex: 'nodeLevel',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.nodeType?.name}</div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('roles.valueType')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.ascending);
                                setSortColumn(kpiDetailsTable?.DATA_TYPE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortOrder?.descending);
                                setSortColumn(kpiDetailsTable?.DATA_TYPE);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'valueType',
            dataIndex: 'valueType',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.valueType?.name}</div>
                </>
            ),
        },
    ];
    return (
        <>
            <div
                style={
                    editRoleState === ROLETYPE?.viewRole
                        ? { pointerEvents: 'none', opacity: 0.5 }
                        : {}
                }
            >
                <Table
                    columns={columns}
                    dataSource={tableData}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 500px)' }}
                />
            </div>
        </>
    );
};
export default Kpi;

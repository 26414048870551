import * as types from 'redux/types/alertTypes';

export const setAlertState = (payload: string | null): any => ({
    type: types.SET_ALERT_STATE,
    payload: payload,
});

export const getAlertList = (payload: any): any => ({
    type: types.GET_ALERT_LIST,
    payload: payload,
});

export const getAlertOperators = (): any => ({
    type: types.GET_OPERATOR_LIST,
});

export const getAlertOperatorById = (payload: any): any => ({
    type: types.GET_OPERATOR_BY_ID,
    payload: payload,
});

export const getAlertById = (payload: any): any => ({
    type: types.GET_ALERT_DETAILS,
    payload: payload,
});

export const getKpiListByAsset = (payload: any): any => ({
    type: types.GET_KPI_LIST_BY_ASSET,
    payload: payload,
});

export const getDurationList = (payload?: any): any => ({
    type: types.GET_DURATION_LIST,
    payload: payload,
});

export const getCalculationCycleList = (payload?: any): any => ({
    type: types.GET_CALCULATION_CYCLE_LIST,
    payload: payload,
});

export const getNotificationChannelList = (payload?: any): any => ({
    type: types.GET_NOTIFICATION_CHANNEL_LIST,
    payload: payload,
});
export const getConsequenceList = (): any => ({
    type: types.GET_CONSEQUENCE_LIST,
});

export const createAlert = (payload?: any): any => ({
    type: types.CREATE_ALERT,
    payload: payload,
});
export const updateAlertStatus = (payload: any): any => ({
    type: types.UPDATE_ALERT_STATUS,
    payload: payload,
});

export const updateAlert = (payload?: any): any => ({
    type: types.UPDATE_ALERT,
    payload: payload,
});

export const getAlertLevelList = (payload?: any): any => ({
    type: types.GET_ALERT_LEVEL_LIST,
    payload: payload,
});

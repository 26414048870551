import React, { useEffect, useState } from 'react';
import './index.scss';
import { Card, Col, Row, Spin, Dropdown, Menu, Button, Input } from 'antd';
import CustomButton from 'components/common/CustomButton';
import CustomPagination from 'components/common/CustomPagination';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as CSVIcon } from 'assets/icons/csvIcon.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excelIcon.svg';
import {
    getGroupsList,
    getAllGroups,
} from 'redux/actions/UserManagementActions/groupsAction';
import SideDrawer from 'components/common/SideDrawer';
import GroupDrawer from 'components/common/SideDrawer/GroupDrawer';
import GroupTable from './GroupTable';
import { useParams } from 'react-router-dom';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { exportGroups } from 'redux/actions/BulkUploadActions/bulkUploadActions';
import {
    EMPTY,
    File_TO_DOWNLOAD,
    PERMISSIONS,
    resourceName,
} from 'types/enums';
import { getObjectByResourceName, hasPermission } from 'utils/rbacFunction';
import PermissionComponent from 'components/common/PermissionComponent';
import { useTranslation } from 'react-i18next';
import { EMPTY_TREE_OBJECT } from 'redux/types/assetModellingTypes';
import { CLOSE_GROUP_DRAWER } from 'redux/types/userManagementTypes';

const Groups: React.FC = () => {
    const { t } = useTranslation('translation');
    const [openSideDrawer, setOpenSideDrawer] = useState(false);
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [userPermissionDetails, setUserPermissionDetails] = useState({});

    const [payload, setPayload] = useState({
        page,
        pageSize,
    });
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const groupListLoading = useSelector(
        (state: any) => state.userManagement?.groups?.groupListLoading
    );
    const dispatch = useDispatch();
    const { currentTab } = useParams();
    useEffect(() => {
        setSelectedValues([]);
        setSearch('');
    }, [currentTab]);

    const groupDataCreated = useSelector(
        (state: any) => state.userManagement.groups.isCreated
    );

    const groupDataEdited = useSelector(
        (state: any) => state.userManagement.groups.isEdited
    );

    useEffect(() => {
        dispatch(getAllGroups());
    }, [groupDataCreated, groupDataEdited]);

    const groupData = useSelector(
        (state: any) => state.userManagement.groups.groupsList
    );
    const loggedInUserPermissionData = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserScreenPermissionList
    );

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);
    const [groupSearchTimeout, setGroupSearchTimeout] =
        useState<any>(undefined);
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    useEffect(() => {
        search === EMPTY?.string &&
            dispatch(
                getGroupsList({
                    ...payload,
                    sortColumn: sortColumn,
                    sortOrder: sortOrder,
                })
            );
    }, [
        payload,
        selectedValues,
        groupDataCreated,
        groupDataEdited,
        sortOrder,
        sortColumn,
    ]);

    const exportItems = [
        {
            label: (
                <label
                    onClick={() => {
                        dispatch(
                            exportGroups({
                                templateType: File_TO_DOWNLOAD.CSV,
                            })
                        );
                    }}
                >
                    {t('groups.exportCSV')}
                </label>
            ),
            key: 'exportCSV',
            icon: <CSVIcon />,
        },
        {
            label: (
                <label
                    onClick={() => {
                        dispatch(
                            exportGroups({
                                templateType: File_TO_DOWNLOAD.EXCEL,
                            })
                        );
                    }}
                >
                    {t('groups.exportExcel')}
                </label>
            ),
            key: 'exportExcel',
            icon: <ExcelIcon />,
        },
    ];

    useEffect(() => {
        setUserPermissionDetails(
            getObjectByResourceName(
                resourceName.groups,
                loggedInUserPermissionData
            )
        );
    }, [loggedInUserPermissionData]);
    const groupCountDetails = useSelector(
        (state: any) => state.userManagement?.users?.countDetails
    );
    return (
        <>
            <>
                <div className="groupsWrapper">
                    <Card bordered={false}>
                        <PermissionComponent screenName={resourceName.groups}>
                            {groupCountDetails?.activeCount +
                                groupCountDetails?.inActiveCount >
                            0 ? (
                                <>
                                    <Row className="usersWrapper-head">
                                        <Col span={16}>
                                            <Input
                                                allowClear
                                                className="usersWrapper__search"
                                                placeholder={t(
                                                    'groups.searchGroupName'
                                                )}
                                                prefix={<SearchOutlined />}
                                                value={search}
                                                onChange={(e) => {
                                                    setPage(PAGE);
                                                    setSearch(e.target.value);
                                                    if (groupSearchTimeout) {
                                                        clearTimeout(
                                                            groupSearchTimeout
                                                        );
                                                    }
                                                    setGroupSearchTimeout(
                                                        setTimeout(() => {
                                                            dispatch(
                                                                getGroupsList({
                                                                    page: PAGE,
                                                                    pageSize:
                                                                        PAGE_SIZE,

                                                                    search: e
                                                                        .target
                                                                        .value,
                                                                    sortColumn:
                                                                        sortColumn,
                                                                    sortOrder:
                                                                        sortOrder,
                                                                })
                                                            );
                                                        }, 1000)
                                                    );
                                                }}
                                            />
                                        </Col>

                                        <Col span={5}>
                                            <div className="export-option">
                                                <Dropdown
                                                    overlay={
                                                        <Menu>
                                                            {exportItems.map(
                                                                (item) => (
                                                                    <Menu.Item
                                                                        key={
                                                                            item.key
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </Menu.Item>
                                                                )
                                                            )}
                                                        </Menu>
                                                    }
                                                >
                                                    <Button>
                                                        {t('groups.export')}
                                                        <DownOutlined />
                                                    </Button>
                                                </Dropdown>
                                                <CustomButton
                                                    type={t(
                                                        'groups.createGroup'
                                                    )}
                                                    disabled={
                                                        hasPermission(
                                                            userPermissionDetails,
                                                            PERMISSIONS.write
                                                        )
                                                            ? false
                                                            : !loggedInUserDetails.admin
                                                    }
                                                    handleClick={() => {
                                                        setOpenSideDrawer(true);
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {groupListLoading ? (
                                        <div className="view__loader">
                                            <Spin />
                                        </div>
                                    ) : (
                                        <>
                                            <Row className="groupTableWrapper">
                                                <Col span={24}>
                                                    <GroupTable
                                                        data={
                                                            groupData?.records
                                                        }
                                                        payload={payload}
                                                        setSortOrder={
                                                            setSortOrder
                                                        }
                                                        setSortColumn={
                                                            setSortColumn
                                                        }
                                                        setPage={setPage}
                                                    />
                                                </Col>
                                            </Row>
                                            {groupData?.totalRecords >
                                            PAGE_SIZE ? (
                                                <>
                                                    <CustomPagination
                                                        totalRecords={
                                                            groupData?.totalRecords
                                                        }
                                                        page={page}
                                                        setPage={setPage}
                                                        setPageSize={
                                                            setPageSize
                                                        }
                                                        pageSize={pageSize}
                                                    />
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="groupsWrapper__noData">
                                    <EmptyDataComponent
                                        textValue={t(
                                            'groups.noGroupsCreatedText'
                                        )}
                                        buttonType={{
                                            name: t('groups.createGroup'),
                                            disable: false,
                                        }}
                                        loading={groupListLoading}
                                        buttonClickHandler={() => {
                                            setOpenSideDrawer(true);
                                        }}
                                    />
                                </div>
                            )}
                        </PermissionComponent>
                    </Card>
                </div>
            </>

            {openSideDrawer && (
                <SideDrawer
                    title={t('groups.createGroup')}
                    Open={openSideDrawer}
                    onClose={() => {
                        setOpenSideDrawer(false);
                        dispatch({type:EMPTY_TREE_OBJECT});
                        dispatch({ type: CLOSE_GROUP_DRAWER });

                    }}
                >
                    <GroupDrawer
                        onClose={() => {
                            setOpenSideDrawer(false);
                            dispatch({type:EMPTY_TREE_OBJECT});
                            dispatch({ type: CLOSE_GROUP_DRAWER });

                        }}
                        paginationPayload={payload}
                    />
                </SideDrawer>
            )}
        </>
    );
};

export default Groups;

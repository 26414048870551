export const GET_AUDIT_TRAILS = 'GET_AUDIT_TRAILS';
export const GET_AUDIT_TRAILS_SUCCESS = 'GET_AUDIT_TRAILS_SUCCESS';
export const GET_AUDIT_TRAILS_FAILURE = 'GET_AUDIT_TRAILS_FAILURE';

export const SET_AUDIT_LIST_EMPTY = 'SET_AUDIT_LIST_EMPTY';

export const GET_MODULES = 'GET_MODULES';
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAILURE = 'GET_MODULES_FAILURE';

export const GET_MODULES_CONFIGURATION = 'GET_MODULES_CONFIGURATION';
export const GET_MODULES_CONFIGURATION_SUCCESS =
    'GET_MODULES_CONFIGURATION_SUCCESS';
export const GET_MODULES_CONFIGURATION_FAILURE =
    'GET_MODULES_CONFIGURATION_FAILURE';

import {
    ATTRIBUTETYPE,
    ROLETYPE,
    dateFormat,
    EMPTY,
    ATTRIBUTETYPEIMPL,
    TABLETYPE,
    TIMECAPSULETYPE,
    KPITYPE,
    KPIIMPLEMENTATION,
    EquipmentState,
} from 'types/enums';
import dayjs from 'dayjs';
import { DateRangeFilter, ShiftTiming } from 'types/enums/kpiEnum';
import { checkTimeBeforeShiftEndTime } from 'utils/commonFunction';
import { formulaCreatorScreenType } from 'types/enums/FormulaCreatorEnum';
import {
    ConnectionsState,
    MappingState,
    MonitoringState,
    OperationState,
} from 'types/enums/businessIntegratorEnum';
import { QRConfigState } from 'types/enums/qrCodeGeneration';

const initialState: any = {
    root: {
        showLoader: false,
        sideNavMenuList: [],
        bulkUploadLoader: false,
        allowedMenuList: [],
    },
    userManagement: {
        users: {
            loggedInUserDetails: {},
            loggedInUserScreenPermissionList: [],
            usersList: [],
            userListLoading: false,
            userDetails: {},
            userPreferences: {},
            editUser: false,
            isUserEdited: false,
            editPreferences: false,
            countDetails: {},
            allUsersList: [],
            reportingRolesList: [],
            reportingDashbaordList: [],
            dashboardEmbeddedUuid: '',
            isUserCreated: false,
            loggedInUserDashboardBuilderDetails: {},
            dashboardBuilderDetails: {},
            themeLoader: false,
        },
        groups: {
            allGroupsData: [],
            groupsList: {},
            groupListLoading: false,
            isCreated: false,
            isCreatedResponse: {},
            isEdited: false,
            isDeactivated: false,
            dataByGroupId: {},
            resourceType: [],
            resourceTypeDataById: [],
            resourceSubItemsResponse: null,
            viewGroupDrawer:false
        },

        roles: {
            editRoleState: ROLETYPE.view,
            roleData: {},
            rolesList: {},
            rolesListLoading: false,
            roleDetails: {},
            createRoleState: false,
            allRolesList: [],
            updateRole: false,
            resourceTypeSubItemsList:[] as any,
            resourceTypeDetails: [],
            resourcePermissionListPayload: [] as any,
            usersRoleListPayload: [] as any,
            checkedItemsScreen:[],
            checkedItemsKpi:[],
            checkedItemsAsset:[],
            selectedResourceType:null
        },
    },
    configure: {
        attributes: {
            attributeState: ATTRIBUTETYPE.display,
            attributesList: [],
            attributesListLoading: false,
            attributesDetailLoading: false,
            attributeDetails: {},
            industryList: [],
            categoryList: [],
            valueTypeList: [],
            dataReferenceList: [],
            propertyList: [],
            uomList: [],
            uomClassList: [],
            uomMetricList: [],
            createUomClass: false,
            createCategory: false,
            createIndustry: false,
            createUom: false,
            createAttribute: false,
            updateAttribute: false,
            statusUpdateAttribute: false,
            attributesFieldsValues: {
                name: '',
                properties: null,
                category: null,
                uom: null,
                valueType: null,
                dataReference: null,
                displayDigit: '2',
                industry: [],
                description: '',
            },
            uomTableList: [],
            uomListLoading: false,
            isStatusUpdateUom: false,
            uomDetailsById: {},
            uomDetailsByIdLoading: false,
            isUomUpdated: false,
        },
        timeCapsule: {
            timeCapsuleState: TIMECAPSULETYPE.display,
            timeCapsuleList: [],
            timeCapsuleListLoading: false,
            createTimeCapsule: false,
            timeCapsuleDetails: {},
            timeCapsuleDetailLoading: false,
            timeCapsuleUpdatedName: '',
            timeCapsuleUpdatedDescription: '',
            statusUpdateTimeCapsule: false,
            updateTimeCapsule: false,
            assignTimeCapsule: false,
        },
        kpi: {
            kpiState: KPITYPE.display,
            kpiFieldsValues: {
                name: '',
                type: null,
                nodeLevel: null,
                uom: null,
                valueType: null,
                aggregationType: null,
                displayDigit: '2',
                targetType: null,
                description: '',
            },
            kpiTypeList: [],
            targetTypeList: [],
            aggregationTypeList: [],
            nodeLevelList: [],
            createKpi: false,
            kpiList: [],
            kpiListLoading: false,
            statusUpdateKpi: false,
            kpiDetailLoading: false,
            kpiDetails: {},
            updateKpi: false,
            allKpiDetails:{}
        },
        attributesFieldsValues: {
            name: '',
            properties: null,
            category: null,
            uom: null,
            valueType: null,
            dataReference: null,
            displayDigit: '2',
            industry: [],
            description: '',
        },
        table: {
            tableState: TABLETYPE.display,
            tableList: [],
            tableListLoading: false,
            createTable: false,
            tableDetails: {},
            tableDetailLoading: false,
            tableUpdatedName: '',
            tableUpdatedDescription: '',
            statusUpdateTable: false,
            updateTable: false,
            deleteTableRow: false,
            feildTypeList: [],
            typeList:[],
        },
    },
    kpi: {
        kpiImplementation: {
            kpiList: [],
            kpiData: {},
            kpiListLoading: false,
            nodeLevelList: [],
            KpiDetailsData: {},
            kpiDetailLoading: false,
            KpiImplementationState: KPIIMPLEMENTATION.display,
            kpiCalculationCycleList: [],
            KpiValidateData: [],
            kpiValidated: false,
        },
    },
    login: {
        loginParameters: '',
        isLoggedIn: Boolean(localStorage.getItem('authToken')),
        refreshToken: '',
        rememberMe: false,
        otpData: '',
        otpVerify: '',
        passwordUpdated: false,
        logout: '',
        isEmailValid: true,
        isPasswordValid: true,
        changePassword: false,
        passwordResetLinkSent: false,
        passwordResetLinkValidated: false,
        setNewpasswordLoading: false,
        userEmail: EMPTY?.string,
        recoveryToken: EMPTY?.string,
        recoveryLinkSentLoading: false,
        mfaOtpSentMessage: false,
        isMfaOtpVerify: false,
        resendMfaOtp: false,
    },
    deviceManagement: {
        blas: {
            addBlaState: 'view',
            blaId: null,
            deviceId: null,
            blasList: [],
            allBlasList: [],
            blasListLoading: false,
            deviceInBlaLoader: false,
            blaDetails: {},
            isDeactivated: false,
            createBlaState: false,
            isEdited: false,
            devicesInBlaList: [],
            deviceIsRemoved: false,
            blaStatusChanged: false,
            selectedDevice: null,
            lastCreatedBla: null,
            sidebarNavigate: false,
        },
        devices: {
            addDeviceState: false,
            deviceDetails: {},
            deviceList: [],
            isEdited: false,
            allDevicesList: [],
            selectedBla: [],
            deviceStart: false,
            deviceStop: false,
            devicesListLoading: false,
            deviceStatusChanged: false,
            deviceStatusResponse: false,
            allDevicesListByBla: [],
            lastAddedDevice: null,
            isCloned: false,
            dataTypes: [],
        },
        tags: {
            createTagState: false,
            tagsList: [],
            allTagsList: [],
            tagListByDeviceId: [],
            tagProperties: [],
            tagsListLoading: false,
            dataTypes: [],
            isEdited: false,
            allTagsByDeviceId: [],
            tagStatusChanged: false,
            tagStatusResponse: false,
            tagDetails: {},
            uploadedTags: false,
            methodList: [],
            updatedTagData: {},
        },
    },
    formulaCreator: {
        focusedIndex: 0,
        expressionArray: [],
        selectedAsset: '',
        loader: false,
        formulaData: { frequencyInMillis: 1 },
        responseObject: { data: '', loader: false },
        viewMeta: {
            view: false,
            modelId: '',
            assetId: '',
            type: formulaCreatorScreenType.ATRRIBUTE,
            title: '',
            ruleId: '',
        },
        attributesList: {
            data: [],
            loader: false,
        },

        tagsList: {
            data: [],
            loader: false,
        },
        KPIsList: {
            data: [],
            loader: false,
        },
    },
    nocilDashboard: {
        filterValues: {
            startTime: checkTimeBeforeShiftEndTime(7)
                ? dayjs()
                      .subtract(1, 'days')
                      .format(
                          `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                      )
                : dayjs().format(
                      `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                  ),
            endTime: dayjs().format(`${dateFormat.format}`),
            dateRange: DateRangeFilter?.Today,
        },
        utilization: {
            loader: false,
            data: {},
        },
        dataVisualization: {
            modelList: [],
            nodeList: [],
            streamTagList: [],
            aggregatedChartData: [],
            aggregatedChartDataLoading: false,
            dataTableList: {
                loader: false,
                data: [],
            },
            kpiDataLoader: false,
            kpiList: [],
            kpiTrendsData: {},
            frequency: [],
        },
        currentValues: {
            loader: false,
            data: [],
        },
        kpiMetaValues: {
            loader: false,
            data: [],
        },
        aggregateValues: {
            loader: false,
            data: [],
        },
        powerConsumptionValues: {
            loader: false,
            data: [],
        },
        economiserAndAphvalues: {
            loader: false,
            data: [],
        },
        avgOxygenValues: {
            loader: false,
            data: [],
        },
        fuelRatioValues: {
            loader: false,
            data: [],
        },
        chooseParamValues: {
            loader: false,
            data: [],
        },
        steamRateData: {
            loader: false,
            data: {},
        },
        feedWaterTrend: {
            loader: false,
            data: {},
        },
        steamTempTrend: { loader: false, data: [] },
        steamPressure: { loader: false, data: [] },
        parameters: [],
        parameterTrendData: { loader: false, data: [] },
        coalIntakeProd: { loader: false, data: [] },
        reductionValues: {
            loader: false,
            data: [],
        },
        kpiTrend: {},
        kpiTrendParameter: {
            view: false,
            kpiId: '',
            heading: '',
            subheading: '',
            unit: {},
        },
        steamFlowRate: { loader: false, data: [] },
        isAssumptionAndFormulaOpen: false,
    },
    manualEntry: {
        allManualEntryParams: {
            loader: false,
            data: [],
        },
        manualEntryValueParams: {
            loader: false,
            data: [],
        },
        setManualEntryValueParams: {
            loader: false,
            data: [],
        },
    },
    operatorEntry: {
        allOperatorEntryParams: {
            loader: false,
            data: [],
        },
        setOperatorEntryValueParams: {
            loader: false,
            data: [],
        },
    },
    dataVisualization: {
        modelList: [],
        nodeList: [],
        streamTagList: [],
        aggregatedChartData: [],
        aggregatedChartDataLoading: false,
        dataTableList: {
            loader: false,
            data: [],
        },
        kpiDataLoader: false,
        kpiList: [],
        kpiTrendsData: {},
        frequency: [],
    },
    calendarConfigurator: {
        timeZonesList: [],
        dayConfigDataAdded: false,
        shiftConfigDataAdded: false,
        assetDetailsAdded: false,
        dayConfigDataByAssetId: [],
        shiftConfigDataByAssetId: [],
        assetDetails: [],
        monthsList: [],
        weekDaysList: [],
        updateShiftDataForApi: [],
        updateDaytDataForApi: [],
        updateModalState: [],
        isSwitchChecked: false,
        isConfigChange: false,
        isDefaultConfigType: false,
        loadingDay: false,
        loadingShift: false,
    },
    dataExplorer: {
        dashboardReporting: {
            reportingList: [],
            createDashboardUrl: false,
            updateDashboardUrl: false,
            deleteDashboardUrl: false,
        },
    },
    implementation: {
        attribute: {
            selectedAsset: {},
            attributeStateImpl: ATTRIBUTETYPEIMPL.add,
            attributesListLoading: false,
            attributesDetailLoading: false,
            assignedAttributeList: [],
            UnassignedAttributeList: [],
            isAssign: false,
            isValidateSuccess: false,
            attributeValue: {},
            editAttribute: false,
            tagsList: [],
            tagsListLoading: false,
            isAttributeRemoved: false,
            isValidateEditSuccess: false,
        },
        timeCapsule: {
            assignedTimeCapsuleList: [],
            assignedTimeCapsuleListLoading: false,
            unassignedTimeCapsuleList: [],
            unassignedTimeCapsuleListLoading: false,
            assignTimeCapsuleToAsset: false,
            isTimeCapsuleRemovedFromAsset: false,
            tagsList: [],
            attributesList: [],
            addEditTimeCapsuleValidation: false,
            validatedTimeCapsuleDetails: {},
            conditions: [],
            viewDetailsLoader: false,
        },
        table: {
            assignedTableList: [],
            assignedTotalCount: null,
            validatedCount: null,
            notValidatedCount: null,
            UnassignedTableList: [],
            unassignedTotalCount: null,
            tableListLoading: false,
            tableAssigned: false,
            tableValuesLoading: false,
            tableColumnsList: [],
            tableValuesList: {},
            tableValidated: false,
            tableUpdated: false,
        },
    },
    reasonCode: {
        downTimesList: [],
        downTimesListLoading: false,
        reasonCode: [],
        reasonFactor: [],
        reasonCategory: [],
        createReasonCode: false,
        nodeDetailsLoading: false,
        nodeDetails: {},
    },
    assetModelling: {
        modelOverview: {
            selectedAsset: {},
            modelList: [],
            modelListLoading: false,
            displayTagsCount: false,
            modelAdded: false,
            modelDetails: {},
            modelDeleted: false,
            modelDetailsLoading: false,
            modelDetailsEdited: false,
            openSubModelTabs: false,
            tagListByDevice: {},
            tagListByDeviceLoader: false,
            mappedTagsList: {},
            tagsMapped: false,
            mappedTagsListLoading: false,
            dataTypeList: [],
            unitsList: [],
            tagFactorUpdated: false,
            getTreeByModelId: {},
            openAddNodeDrawer: false,
            openDeleteNodeModal: false,
            nodeDetailsById: {},
            isNodeDeleted: false,
            nodeData: {},
            isNodeAdded: false,
            nodeDetailsLoading: false,
            displayTreeLoader: false,
            horizontalView: false,
            verticalView: false,
            unMapTagNodes: false,
            editNodeDetails: false,
            isNodeDetailsEdited: false,
        },
        equipment: {
            eqipmentState: EquipmentState?.display,
            equipmentClassData: [],
            equipmentClassDetails: {},
            equipmentClassTableList: [],
            equipmentClassUpdate: [],
            equipmentClassClone: [],
            equipmentClassById: [],
            equipmentClassDelete: [],
            configurationDataLoading: false,
        },
        nodeType: {
            nodeData: [],
            nodeTypeListDetails: [],
            nodeTypeUpdateForm: [],
            nodeTypeById: [],
            nodeTypeClone: [],
            nodeTypeDelete: [],
            nodeDataLoading: false,
        },
    },
    alert: {
        alertState: null,
        alertList: [],
        alertListLoading: false,
        operatorsList: [],
        operatorsListById: {},
        alertDetails: {},
        kpiListByAsset: [],
        durationList: [],
        calculationCycleList: [],
        notificationChannelList: [],
        consequenceList: [],
        alertCreated: false,
        alertUpdated: false,
        statusUpdateAlert: false,
        alertLevel: [],
    },
    auditTrails: {
        auditTrailListLoading: false,
        auditList: [],
        moduleList: [],
        moduleConfigurationList: [],
    },
    businessIntegrator: {
        connections: {
            connectionsState: ConnectionsState.tableView,
            connectionsLoader: false,
            adapterList: [],
            allFileFormatsList: [],
            allDirectionsList: [],
            allAuthMethodList: [],
            isConnectionCreated: false,
            connectionTableLoader: false,
            connectionsList: [],
            connectionByIdLoader: false,
            connectionById: {},
            isConnectionUpdated: EMPTY.string,
            isConnectionUpdatedCode: EMPTY.string,
            isConnectionUpdatedMessage: EMPTY.string,
            isConnectionStreamingOn: EMPTY.string,
            isConnectionStreamingCode: EMPTY.string,
            isConnectionStreamingMessage: EMPTY.string,
            isConnectionStreamingDeactivate: EMPTY.string,
        },
        monitoring: {
            labelsList: [],
            monitoringListData: {},
            monitoringTableLoader: true,
            monitoringState: MonitoringState.tableView,
            createdByList: [],
            allMonitoringList: [],
            monitoringById: {},
            reprocessSuccess: false,
        },
        mapping: {
            mappingState: MappingState.tableView,
            mappingTableLoader: false,
            allMappingList: [],
            labelsList: [],
            mappingTableList: {},
            jsonLoader: false,
            jsonData: [],
            isMappingExecuted: false,
            isTestExecutuedSuccess: null,
            isMappingExecutedData: [],
            braboTableList: [],
            columnDetails: [],
            mappingFields: [],
            isMappingCreated: false,
            mappingByIdLoader: false,
            mappingById: {},
            isMappingDeleted: false,
            isMappingActivated: false,
            isMappingUpdated: false,
        },
        operation: {
            operationState: OperationState.tableView,
            isOperationCreated: false,
            operationDetails: {},
            isOperationDeleted: false,
            isStatusChanged: false,
            isOperationUpdated: false,
            operationTableLoader: false,
            operationTableList: {},
            allOperationList: [],
            daysList: [],
            connectionValidated: false,
        },
    },
    qrCodeGeneration: {
      qrCodeLogs: {
        connectionsState: ConnectionsState.tableView,
        qrCodeTableLoader: false,
        qrCodeLogsListTableList: [],
        qrCodeLogsListById:{},
        qrCodeLogsListWithoutPagination:[],
        qrCodeConfigListWithoutPagination:[]
      },
      qrCodeConfig: {
        qrConfigState: QRConfigState.list,
        qrDatatype: [],
        qrImageType: [],
        qrConfigCreated: false,
        qrConfigListData: {},
        qrConfigListLoading: false,
        qrCodeGenerated: false,
        qrConfigUpdated: false,
        qrConfigDetails: {},
        qrConfigStatusUpdated: false,
      },
    },
};

export default initialState;
import { Card, Cascader, Col, Row, Select } from 'antd';
import CustomButton from 'components/common/CustomButton';
import { CloseOutlined } from '@ant-design/icons';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import { useDispatch, useSelector } from 'react-redux';
import MappingTable from './mappingTable';
import { useEffect, useState } from 'react';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import './index.scss';
import {
    getAllMapping,
    getLabelsList,
    getMappingList,
    setMappingState,
} from 'redux/actions/BusinessIntegratorActions/mappingActions';
import {
    getAdapterList,
    getAllDirectionsList,
    getAllFileFormats,
    getConnectionsList,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { useParams } from 'react-router-dom';
import { MappingState, TabName } from 'types/enums/businessIntegratorEnum';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { cancelHandle, modalShow } from 'utils/modalFunction';

const Mapping: React.FC = () => {
    const { SHOW_CHILD } = Cascader;
    const dispatch = useDispatch();
    const { currentTab } = useParams();
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [connectionIds, setConnectionIds] = useState([]);
    const [mappingIds, setMappingIds] = useState([]);
    const [labelIds, setLabelIds] = useState([]);
    const [reset, setReset] = useState(false);
    const [status, setStatus] = useState<any>(null);
    const [adapterType, setAdapterType] = useState<any>(null);
    const [direction, setDirection] = useState<any>(null);
    const [fileType, setFileType] = useState<any>(null);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);
    const { t } = useTranslation('translation');

    const mappingTableLoader = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingTableLoader
    );
    const mappingTableList = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingTableList
    );
    const mappingDropdownList = useSelector(
        (state: any) => state.businessIntegrator.mapping.allMappingList
    );
    const connectionsTableList = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.connectionsList
                ?.paginatedResponse?.records
    );
    const allFileFormatsList = useSelector(
        (state: any) => state.businessIntegrator.connections.allFileFormatsList
    );
    const labelsList = useSelector(
        (state: any) => state.businessIntegrator.mapping.labelsList?.records
    );
    const adapterList = useSelector(
        (state: any) => state.businessIntegrator.connections.adapterList
    );
    const allDirectionsList = useSelector(
        (state: any) => state.businessIntegrator.connections.allDirectionsList
    );
    const isMappingDeleted = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingDeleted
    );
    const isMappingActivated = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingActivated
    );
    useEffect(() => {
        isMappingDeleted && setActiveSuccessModalOpen(true);
    }, [isMappingDeleted]);
    useEffect(() => {
        dispatch(getAllMapping({ pageNum: -1 }));
        dispatch(getConnectionsList({ pageNum: -1 }));
        dispatch(getAdapterList());
        dispatch(getAllDirectionsList());
        dispatch(getAllFileFormats());
        dispatch(getLabelsList({ pageNum: -1, labelId: 2 })); // To get the labels list for mapping send labelId as 2
    }, []);
    useEffect(() => {
        if (
            currentTab !== TabName.mapping ||
            isMappingDeleted ||
            isMappingActivated
        ) {
            setMappingIds([]);
            setAdvanceFilter(false);
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
        }
    }, [currentTab, isMappingDeleted, isMappingActivated]);
    useEffect(() => {
        !advanceFilter &&
            dispatch(
                getMappingList({
                    mappingIds: mappingIds?.toString(),
                    pageNum: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
    }, [mappingIds]);
    useEffect(() => {
        if (!advanceFilter) {
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
            setFileType(null);
            setConnectionIds([]);
            setLabelIds([]);
            setAdapterType(null);
            setDirection(null);
            setStatus(null);
            setReset(!reset);
            dispatch(
                getMappingList({
                    mappingIds: mappingIds?.toString(),
                    pageNum: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
        }
    }, [advanceFilter]);
    useEffect(() => {
        advanceFilter
            ? dispatch(
                  getMappingList({
                      pageNum: page,
                      pageSize: pageSize,
                      sortOrder: sortOrder,
                      sortColumn: sortColumn,
                      mappingIds: mappingIds.toString(),
                      connectionIds: connectionIds.toString(),
                      adapterType: adapterType,
                      fileType: fileType,
                      direction: direction,
                      status: status,
                      labelIds: labelIds.toString(),
                  })
              )
            : dispatch(
                  getMappingList({
                      pageNum: page,
                      pageSize: pageSize,
                      sortOrder: sortOrder,
                      sortColumn: sortColumn,
                  })
              );
    }, [pageSize, page, sortOrder, sortColumn]);
    const filter = (inputValue: any, path: any): any =>
        path?.some((option: any): any =>
            (option?.label as string)
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase())
        );
    const onApply = (): void => {
        dispatch(
            getMappingList({
                pageNum: PAGE,
                pageSize: PAGE_SIZE,
                mappingIds: mappingIds.toString(),
                connectionIds: connectionIds.toString(),
                adapterType: adapterType,
                fileType: fileType,
                direction: direction,
                status: status,
                labelIds: labelIds.toString(),
            })
        );
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
        setSortOrder(null);
        setSortColumn(null);
    };
    const onOkHandler = (): any => {
        modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
    };
    const resetFilter = (): void => {
        if (
            adapterType ||
            connectionIds?.length ||
            labelIds?.length ||
            mappingIds?.length ||
            direction ||
            status ||
            fileType ||
            status
        ) {
            dispatch(
                getMappingList({
                    pageNum: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
        }
        setConnectionIds([]);
        setMappingIds([]);
        setLabelIds([]);
        setAdapterType(null);
        setFileType(null);
        setStatus(null);
        setDirection(null);
        setReset(!reset);
        setPage(PAGE);
        setPageSize(pageSize);
        setSortOrder(null);
        setSortColumn(null);
    };
    const statusList = [
        {
            value: 1,
            label: 'Active',
        },
        {
            value: 2,
            label: 'Inactive',
        },
        {
            value: 0,
            label: 'All',
        },
    ];
    return (
        <>
            <div className="attributeWrapper mappingWrapper">
                <Card bordered={false}>
                    {mappingTableList?.activeMappingCount +
                        mappingTableList?.inActiveMappingCount >
                        0 || mappingTableLoader ? (
                        <>
                            {advanceFilter ? (
                                <>
                                    <Row className="attributeWrapper__filterHeader">
                                        <Col span={5}>
                                            <Cascader
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={mappingDropdownList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.mappingName,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setMappingIds(
                                                        value?.flat()
                                                    );
                                                }}
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.mappingId')}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={mappingIds?.map(
                                                    (item: any) => {
                                                        return [item];
                                                    }
                                                )}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Cascader
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={connectionsTableList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.name,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setConnectionIds(
                                                        value?.flat()
                                                    );
                                                }}
                                                placeholder={t('BusinessIntegrator.mapping.mappingForm.connectionName')}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={connectionIds?.map(
                                                    (item: any) => {
                                                        return [item];
                                                    }
                                                )}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Select
                                                placeholder={
                                                    t('BusinessIntegrator.mapping.mappingDetails.selectAdapterType')
                                                }
                                                options={adapterList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.name,
                                                        };
                                                    }
                                                )}
                                                value={adapterType}
                                                onChange={(value) => {
                                                    setAdapterType(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Cascader
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={labelsList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.name,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setLabelIds(value?.flat());
                                                }}
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.selectLabel')}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={labelIds?.map(
                                                    (item: any) => {
                                                        return [item];
                                                    }
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            className="mappingWrapper__button"
                                            span={3}
                                        >
                                            <CloseOutlined
                                                onClick={() => {
                                                    setAdvanceFilter(false);
                                                    setReset(!reset);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="attributeWrapper__filterAdvHeader">
                                        <Col span={5}>
                                            <Select
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.selectFileType')}
                                                options={allFileFormatsList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.name,
                                                        };
                                                    }
                                                )}
                                                value={fileType}
                                                onChange={(value) => {
                                                    setFileType(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Select
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.selectDirection')}
                                                options={allDirectionsList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.name,
                                                        };
                                                    }
                                                )}
                                                value={direction}
                                                onChange={(value) => {
                                                    setDirection(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={5}>
                                            <Select
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.selectStatus')}
                                                options={statusList}
                                                value={status}
                                                onChange={(value) => {
                                                    setStatus(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <CustomButton
                                                type="Apply"
                                                disabled={false}
                                                handleClick={onApply}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <span
                                                className="customHeader__child-resetButton"
                                                onClick={resetFilter}
                                            >
                                                <ResetIcon />
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row className="attributeWrapper__filterHeader">
                                        <Col span={5}>
                                            <Cascader
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={mappingDropdownList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.mappingName,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setMappingIds(
                                                        value?.flat()
                                                    );
                                                }}
                                                placeholder={t('BusinessIntegrator.mapping.mappingDetails.mappingId')}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={mappingIds?.map(
                                                    (item: any) => {
                                                        return [item];
                                                    }
                                                )}
                                            />
                                        </Col>
                                        <Col
                                            className="mappingWrapper__textButton"
                                            span={5}
                                        >
                                            <span
                                                onClick={() => {
                                                    setAdvanceFilter(true);
                                                }}
                                            >
                                                {t('BusinessIntegrator.mapping.mappingDetails.advanceFilter')}
                                            </span>
                                        </Col>
                                        <Col
                                            span={13}
                                            className="mappingWrapper__addButton"
                                        >
                                            <div>
                                                <CustomButton
                                                    type={t('BusinessIntegrator.mapping.newMappingButton')}
                                                    disabled={false}
                                                    handleClick={() => {
                                                        dispatch(
                                                            setMappingState(
                                                                MappingState.addConnections
                                                            )
                                                        );
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            <MappingTable
                                setPage={setPage}
                                page={page}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                            />
                        </>
                    ) : (
                        <>
                            <div className="mappingWrapper__noData">
                                <EmptyDataComponent
                                    textValue={
                                        t('BusinessIntegrator.mapping.emptyComponent.text')
                                    }
                                    buttonType={{
                                        name: 'New Mapping',
                                        disable: false,
                                    }}
                                    loading={mappingTableLoader}
                                    buttonClickHandler={() => {
                                        dispatch(
                                            setMappingState(
                                                MappingState.addConnections
                                            )
                                        );
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Card>
            </div>
            <SuccessfulModal
                open={activeSuccessModalOpen}
                onOk={() => onOkHandler()}
                onCancel={() => {
                    cancelHandle(
                        activeSuccessModalOpen,
                        setActiveSuccessModalOpen
                    );
                }}
                text={t('BusinessIntegrator.mapping.deleteSuccessful')}
            />
        </>
    );
};

export default Mapping;

import { Card, Switch } from 'antd';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import { ReactComponent as ConnectedIcon } from 'assets/icons/connectedIconsvg.svg';
import { ReactComponent as DisconnectedIcon } from 'assets/icons/disconnectedIcon.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    clearLdapServiceStatus,
    deleteLdapService,
    getLdapServiceConfiguratorDetailsById,
    getLdapUsers,
    setLdapState,
} from 'redux/actions/LdapConfigurator/ldapConfiguratorActions';
import { LDAPConfiguratorState } from 'types/enums/ldapConfiguratorEnum';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { useEffect, useState } from 'react';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';

const LDAPServiceListing: React.FC = (): any => {
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();

    const [
        activateDeactivateConfirmationModal,
        setActivateDeactivateConfirmationModal,
    ] = useState(false);
    const [activateDeactivateSuccessModal, setActivateDeactivateSuccessModal] =
        useState(false);
    const [activateDeactivateState, setActivateDeactivateState] = useState<{
        checked: boolean;
        item: any;
    } | null>(null);

    const ldapServiceList = useSelector(
        (state: any) => state.ldapConfigurator?.ldapServiceList
    );

    const activateDeactivateStatus = useSelector(
        (state: any) => state.ldapConfigurator?.activateDeactivateStatus
    );

    const onActivateDeactivateLdapServicehandler = (
        checked: any,
        item: any
    ): any => {
        setActivateDeactivateState({ checked, item });
        modalShow(
            activateDeactivateConfirmationModal,
            setActivateDeactivateConfirmationModal
        );
    };
    useEffect(() => {
        if (activateDeactivateStatus === true) {
            modalShow(
                activateDeactivateSuccessModal,
                setActivateDeactivateSuccessModal
            );
            dispatch(clearLdapServiceStatus());
        }
    }, [activateDeactivateStatus]);

    return (
        <>
            <div className="ldapServiceListing">
                {ldapServiceList?.map((item: any) => {
                    return (
                        <>
                            <Card className="ldapService__card">
                                <div className="ldapService__cardContent">
                                    <div className="ldapService__cardDetails">
                                        <div
                                            className="ldapService__details"
                                            onClick={() => {
                                                dispatch(
                                                    getLdapServiceConfiguratorDetailsById(
                                                        item.id
                                                    )
                                                );
                                                dispatch(
                                                    setLdapState(
                                                        LDAPConfiguratorState.ldapConfiguratorView
                                                    )
                                                );
                                            }}
                                        >
                                            <span className="ldapService__serviceName fw-600 fs-16">
                                                {item.serviceName}
                                            </span>
                                            <span className="ldapService__serviceType fw-400">
                                                {item.typeOfIdentity}
                                            </span>
                                        </div>
                                        <div className="ldapActionIcon">
                                            <ResetIcon
                                                onClick={() => {
                                                    dispatch(
                                                        getLdapUsers(item.id)
                                                    );
                                                }}
                                            />
                                            <Switch
                                                size="small"
                                                key={item.id}
                                                onClick={(checked: boolean) => {
                                                    onActivateDeactivateLdapServicehandler(
                                                        checked,
                                                        item
                                                    );
                                                }}
                                                value={item?.isConnected}
                                            />
                                        </div>
                                    </div>
                                    <div className="connectionIcon">
                                        {item?.isConnected ? (
                                            <>
                                                <ConnectedIcon />
                                                {t(
                                                    'ldapConfigurator.connected'
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <DisconnectedIcon />
                                                <div>
                                                    {t(
                                                        'ldapConfigurator.disconnected'
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </Card>
                        </>
                    );
                })}
            </div>
            <ConfirmationModal
                open={activateDeactivateConfirmationModal}
                onCancel={() => {
                    cancelHandle(
                        activateDeactivateConfirmationModal,
                        setActivateDeactivateConfirmationModal
                    );
                }}
                onOk={() => {
                    dispatch(
                        deleteLdapService({
                            id: activateDeactivateState?.item.id,
                            isActivated: activateDeactivateState?.checked,
                        })
                    );
                    cancelHandle(
                        activateDeactivateConfirmationModal,
                        setActivateDeactivateConfirmationModal
                    );
                }}
                icon={<ConfirmationIcon />}
                text={
                    activateDeactivateState?.checked
                        ? 'Are you Sure You want to Activate ?'
                        : 'All the users related to this Service will deactivate. Are you sure you want to deactivate ?'
                }
            />
            <SuccessfulModal
                open={activateDeactivateSuccessModal}
                onOk={() => {
                    cancelHandle(
                        activateDeactivateSuccessModal,
                        setActivateDeactivateSuccessModal
                    );
                }}
                onCancel={() => {
                    cancelHandle(
                        activateDeactivateSuccessModal,
                        setActivateDeactivateSuccessModal
                    );
                }}
                text={
                    activateDeactivateState?.checked
                        ? 'Ldap Service Activated Successfully!'
                        : 'Ldap Service Deactivated Successfully!'
                }
            />
        </>
    );
};

export default LDAPServiceListing;

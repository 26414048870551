import React from 'react';
import './index.scss';
import { Row, Col, Drawer, Button, Input, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { getUrlForService } from 'utils/commonFunction';

const PrintQRCode: React.FC<any> = ({ open, onClose, qrCodeData }) => {
    // Function to trigger image download
    const downloadImage = async (
        imageUrl: string,
        filename: string
    ): Promise<any> => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = filename; // specify the download filename
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            message.error('Error downloading image');
        }
    };

    // Function to handle Print/Download
    const onPrint = (imagePath: string): any => {
        const imageUrl = getUrlForService('qrCode') + imagePath;
        const fileExtension = imageUrl.split('.').pop(); // Extracts file extension (png, svg, etc.)
        // Trigger download
        downloadImage(imageUrl, `QRCode.${fileExtension}`);
    };

    return (
        <>
            <Drawer
                className="editBlaDrawer printQR"
                placement="right"
                getContainer={false}
                size="large"
                closable={false}
                open={open}
                onClose={onClose}
            >
                <Row>
                    <Col span={22}>
                        <Row>
                            <Col
                                span={24}
                                className="printqr__heading fw-500 fs-18"
                            >
                                Generated QR Code
                            </Col>
                            <Col
                                span={24}
                                className="printqr__subHeading fw-400 fs-14"
                            >
                                <img
                                    src={
                                        getUrlForService('qrCode') +
                                        qrCodeData?.imagePath
                                    }
                                    alt="QR Code"
                                />
                                <label className="fs-16 fw-500">
                                    QR Code Display text
                                </label>
                                <Input
                                    value={qrCodeData?.qrCodeContent}
                                    readOnly
                                    disabled
                                />

                                <div
                                    className="print-btn"
                                    style={{ marginTop: 16 }}
                                >
                                    <Button
                                        onClick={() =>
                                            onPrint(qrCodeData?.imagePath)
                                        }
                                    >
                                        Download
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={1}>
                        <Button
                            className="editBlaDrawer__icon"
                            type="text"
                            icon={<CloseOutlined />}
                            onClick={onClose}
                        ></Button>
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};

export default PrintQRCode;

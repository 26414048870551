import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import { rootSaga } from "redux/saga/index";
import sideNavReducer from "./reducers/sidenavReducer";
import loginReducer from "./reducers/AuthReducers/authReducer";
import userManagementReducer from "./reducers/UserManagementReducers/userManagementReducers";
import deviceManagementReducer from "./reducers/DeviceManagementReducers/deviceManagementReducers";
import nocilDashboardReducer from "./reducers/NocilDashboardReducers/nocilDashboardReducers";
import dataVisualizationReducer from "./reducers/DataVisualizationReducers";
import manualEntryReducer from "./reducers/ManualEntryReducers/manualEntryReducer";
import calendarConfiguratorReducer from "./reducers/CalendarConfiguratorReducers";
import operatorEntryReducer from "./reducers/OperatorEntryReducers/operatorEntryReducer";
import dashboardReportingReducers from "./reducers/DataExplorerReducers/DashboardReportingReducers";
import configureReducer from "./reducers/ConfigureReducers/configureReducer";
import formulaCreatorReducers from "./reducers/FormulaCreatorReducers/formulaCreatorReducers";
import kpiReducer from "./reducers/KpisReducers/kpiReducers";
import implementationReducer from "./reducers/ImplementationReducers/ImplementationReducers";
import reasonCodeReducer from "./reducers/ReasonCodeReducers/reasonCodeReducer";
import alertReducer from "./reducers/AlertReducers";
import notificationConfigurationReducer from "./reducers/NotificationReducers/notificationConfigurationReducers";
import assetModellingReducer from "./reducers/AssetModellingReducers/assetModellingReducer";
import businessIntegratorReducer from "./reducers/BusinessIntegratorReducers/businessIntegratorReducers";
import QRCodeGenerationReducer from "./reducers/QRCodeGenerationReducers/qrCodeReducers";
import ldapConfiguratorReducer from './reducers/LdapConfiguratorReducers/ldapConfiguratorReducers';
import commonReducer from './reducers/CommonReducers/commonReducers';
import auditTrailsReducer from './reducers/AuditTrailsReducers/auditTrailsReducer';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
    reducer: {
        root: sideNavReducer,
        login: loginReducer,
        userManagement: userManagementReducer,
        deviceManagement: deviceManagementReducer,
        nocilDashboard: nocilDashboardReducer,
        dataVisualization: dataVisualizationReducer,
        manualEntry: manualEntryReducer,
        calendarConfigurator: calendarConfiguratorReducer,
        operatorEntry: operatorEntryReducer,
        dataExplorer: dashboardReportingReducers,
        configure: configureReducer,
        formulaCreator: formulaCreatorReducers,
        kpi: kpiReducer,
        implementation: implementationReducer,
        reasonCode: reasonCodeReducer,
        alert: alertReducer,
        assetModelling: assetModellingReducer,
        notificationConfiguration: notificationConfigurationReducer,
        businessIntegrator: businessIntegratorReducer,
        ldapConfigurator: ldapConfiguratorReducer,
        commonState: commonReducer,
        qrCodeGeneration: QRCodeGenerationReducer,
        auditTrails: auditTrailsReducer,
    },
    middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

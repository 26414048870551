import { baseUrlSetter } from "utils/commonFunction";
import Api from "..";
import { message } from "antd";

export const getDatatypeListService= async (
  payload: any
): Promise<any> => {
  baseUrlSetter("qr");
  try {
    const response = Api.get(`/metadata-config/data-types`);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getImageTypeListService= async (
    payload: any
  ): Promise<any> => {
    baseUrlSetter("qr");
    try {
      const response = Api.get(`/metadata-config/image-types`);
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };

export const createQRConfigService = async (payload: any): Promise<any> => {
    baseUrlSetter('qr');
    const params = payload;
    try {
        const response = Api.post(`/metadata-config`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getQRConfigListServices= async (
    payload: any
  ): Promise<any> => {
    baseUrlSetter("qr");
    const params = payload;
    try {
      const response = Api.get(`/metadata-config`, {
        params: params,
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
};

export const generateQRCodeService = async (payload: any): Promise<any> => {
    baseUrlSetter('qr');
    const params = payload;
    try {
        const response = Api.post(`/metadata-config/generate-qr`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getQRConfigDetailsService = async (payload: any): Promise<any> => {
    baseUrlSetter('qr');
    const params = payload;
    try {
        const response = Api.get(`/metadata-config/${params}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const updateQRConfigService = async (payload: any): Promise<any> => {
    baseUrlSetter('qr');
    const params = payload;
    try {
        const response = Api.put(`/metadata-config/${params?.id}`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const setQRConfigStatusService = async (payload: any): Promise<any> => {
    baseUrlSetter('qr');
    const params = payload;
    try {
        const response = Api.get(`/metadata-config/status-toggle/${params}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

import {
    Button,
    Dropdown,
    Input,
    Menu,
    Radio,
    Select,
    Spin,
    Table,
    TreeSelect,
} from 'antd';
import './index.scss';
import type { ColumnsType } from 'antd/es/table';
import MultiAxisTimeSeriesChart from 'components/common/charts/MultiAxisTimeSeriesChart';
import { useEffect, useRef, useState } from 'react';
import {
    getAggregatedChartData,
    getNodeList,
    getStreamTagList,
    getModelList,
    clearDataVisualizationValues,
    getAssetCSVData,
    getDataTableList,
    clearDataTableList,
    getKpiData,
    getKpiTrends,
    getExportAggregrateCsvData,
    getFrequency,
} from 'redux/actions/DataExplorer/DataVisualizationActions';
import { useDispatch, useSelector } from 'react-redux';
import { checkTimeBeforeShiftEndTime, mapTree } from 'utils/commonFunction';
import CustomHeader from 'components/common/CustomHeader';
import { PAGE, PAGE_SIZE, maxRowSelectionCount } from 'utils/constants';
import { changeDateFilter } from 'redux/actions/NocilDashboardActions/nocilDashboardAction';
import { DateRangeFilter, ShiftTiming } from 'types/enums/kpiEnum';
import {
    EMPTY,
    TagOriginId,
    TemplateTypeAsset,
    dateFormat,
    sortingOrder,
    tagOriginId,
} from 'types/enums';
import dayjs from 'dayjs';
import DataTable from './DataTable';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import KpiTable from './KpiTable';
import TagTable from './TagTable';
import { ReactComponent as CloseIcon } from 'assets/icons/closeIcon.svg';
import { ReactComponent as KpiBadge } from '../../../../src/assets/icons/kpiBadge.svg';
import { ReactComponent as TagBadge } from '../../../../src/assets/icons/tagBadge.svg';
import { ReactComponent as Expand } from '../../../../src/assets/icons/ExpandAlt.svg';
import { useTranslation } from 'react-i18next';
import {
    EMPTY_KPIS_PLOTTING,
    EMPTY_TAGS_PLOTTING,
} from 'redux/actions/actionTypes';
import base64 from 'base-64';
import utf8 from 'utf8';

const DataVisualization: React.FC = () => {
    const { t } = useTranslation('translation');
    const [count, setCount] = useState(1);
    const columns: ColumnsType<DataType> = [
        {
            title: t('dataVisualization.tagKpiName'),
            dataIndex: 'tagName',
            render: (_: any, record: any) => (
                <>
                    {record?.type === 'kpi' ? <KpiBadge /> : <TagBadge />}
                    <span style={{ marginLeft: '8px' }}>{record?.tagName}</span>
                </>
            ),
        },
        {
            title: t('dataVisualization.assetName'),
            dataIndex: 'assetName',
            render: (_: any, record: any) => <>{record?.parentNode?.name}</>,
        },
        {
            title: t('dataVisualization.unit'),
            dataIndex: 'unit',
            width: '100px',
            render: (_: any, record: any) => <>{record?.dataType?.unit}</>,
        },
        {
            title: t('dataVisualization.aggregatedValue'),
            dataIndex: 'aggregatedValue',
            render: (_: any, record: any) => <>{record?.aggregatedValue}</>,
        },
        {
            title: (
                <Button
                    type="link"
                    danger
                    onClick={() => {
                        handleDeleteAllSelected();
                        // Handle header button click if necessary
                    }}
                >
                    Clear All
                </Button>
            ),
            dataIndex: 'actions',
            width: '100px',
            render: (_: any, record: any) => (
                <Button
                    type="link"
                    danger
                    onClick={() => {
                        handleDelete(record);
                    }}
                >
                    <CloseIcon className="selecedTagCloseIcon" />
                </Button>
            ),
        },
    ];

    // Will use later on
    // const location = useLocation();
    // const { pathname } = location;
    // const isTrendingPage = pathname === '/data-visualization/trending';

    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    // const [dataTablePageSize, setDataTablePageSize] = useState(PAGE_SIZE);

    const [tagUUID, setTagUUID] = useState<string>(EMPTY?.string);
    const [kpiUUID, setKpiUUID] = useState<string>(EMPTY?.string);
    const [kpiID, setKpiId] = useState<string>(EMPTY?.string);
    const handlePage = (page: any): any => {
        setPage(page);
    };

    const handlePageSize = (current: any): any => {
        setPageSize(current);
    };

    const streamTagsList: any = useSelector(
        (state: any) => state.dataVisualization.streamTagList
    );
    const nodeList: any = useSelector(
        (state: any) => state.dataVisualization.nodeList
    );

    const modelList: any = useSelector(
        (state: any) => state.dataVisualization.modelList
    );

    const frequencyList: any = useSelector(
        (state: any) => state.dataVisualization.frequency
    );

    const [frequencyState, setFrequencyState] = useState(0);

    const startDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.startTime
    );
    const endDateRedux = useSelector(
        (state: any) => state?.nocilDashboard?.filterValues?.endTime
    );
    const [selectedRowsKeys, setSelectedRowsKeys] = useState<any>([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedDevice, setSelectedDevice] = useState<any>();
    // const [selectedOption, setSelectedOption] = useState('kpis');
    const [selectedTagRows, setSelectedTagRows] = useState<any>([]);
    const [tagTableData, setTagTableData] = useState(streamTagsList);
    const [selectedKpiRows, setSelectedKpiRows] = useState<any>([]);
    const dispatch = useDispatch();
    const [uuids, setUuids] = useState([]);

    useEffect(() => {
        const newUuids = selectedTagRows.map((row: any) => row.uuid);
        setUuids(newUuids);
    }, [selectedTagRows]);

    useEffect(() => {
        dispatch(
            changeDateFilter({
                startTime: checkTimeBeforeShiftEndTime(7)
                    ? dayjs()
                          .subtract(1, 'days')
                          .format(
                              `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                          )
                    : dayjs().format(
                          `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                      ),
                endTime: dayjs().format(`${dateFormat.format}`),
                dateRange: DateRangeFilter?.Today,
            })
        );
        dispatch(getModelList({ page: 1, pageSize: 0 }));
        dispatch(clearDataVisualizationValues());

        return () =>
            dispatch(
                changeDateFilter({
                    startTime: checkTimeBeforeShiftEndTime(7)
                        ? dayjs()
                              .subtract(1, 'days')
                              .format(
                                  `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                              )
                        : dayjs().format(
                              `${dateFormat.formatWithoutTime} ${ShiftTiming.shiftAStartTime}`
                          ),
                    endTime: dayjs().format(`${dateFormat.format}`),
                    dateRange: DateRangeFilter?.Today,
                })
            );
    }, []);

    const handleDelete = (record: any): void => {
        // Filter out the deleted row from selectedRows
        const updatedSelectedRows = selectedTagRows?.filter(
            (row: any) => row?.key !== record?.key
        );
        setSelectedTagRows(updatedSelectedRows);

        // Update selectedRowsKeys, removing the deleted key
        const updatedSelectedRowsKeys = selectedRowsKeys?.filter(
            (key: any) => key !== record?.key
        );
        setSelectedRowsKeys(updatedSelectedRowsKeys);

        // Update tagTableData to remove the deleted row
        const updatedTagTableData = tagTableData?.filter(
            (row: any) => row?.key !== record?.key
        );
        setTagTableData(updatedTagTableData);

        // Filter out the deleted row from the info array
        const updatedInfo = info.filter((item: any) => item.key !== record.key);
        setInfo(updatedInfo);

        // Check if the deleted ID exists in selectedKpiRows and remove it
        const updatedSelectedKpiRows = selectedKpiRows?.filter(
            (row: any) => row.key !== record.key
        );
        setSelectedKpiRows(updatedSelectedKpiRows);

        // Check if the deleted ID exists in selectedTagRows and remove it
        const updatedSelectedTagRows = selectedTagRows?.filter(
            (row: any) => row.key !== record.key
        );
        setSelectedTagRows(updatedSelectedTagRows);
    };

    const handleDeleteAllSelected = (): void => {
        // Filter out the selected rows from tagTableData and info
        const updatedTagTableData = tagTableData?.filter(
            (row: any) =>
                !selectedTagRows?.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );
        const updatedInfo = info?.filter(
            (item: any) =>
                !selectedTagRows?.some(
                    (selectedRow: any) => selectedRow.key === item.key
                )
        );

        // Filter out the selected rows from selectedRowsKeys
        const updatedSelectedRowsKeys = selectedRowsKeys?.filter(
            (key: any) =>
                !selectedTagRows.some(
                    (selectedRow: any) => selectedRow.key === key
                )
        );

        // Filter out the selected KPI rows
        const updatedSelectedKpiRows = selectedKpiRows?.filter(
            (row: any) =>
                !selectedKpiRows.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );

        // Filter out the selected Tag rows
        const updatedSelectedTagRows = selectedTagRows?.filter(
            (row: any) =>
                !selectedTagRows.some(
                    (selectedRow: any) => selectedRow.key === row.key
                )
        );

        // Update the state with the filtered arrays
        setTagTableData(updatedTagTableData);
        setInfo(updatedInfo);
        setSelectedRowsKeys(updatedSelectedRowsKeys);
        setSelectedKpiRows(updatedSelectedKpiRows);
        setSelectedTagRows(updatedSelectedTagRows);
    };

    const selectHandler = (value: any): any => {
        setSelectedDevice(value);
    };

    const aggregatedChartData = useSelector(
        (state: any) => state.dataVisualization.aggregatedChartData
    );
    const aggregatedChartDataLoading = useSelector(
        (state: any) => state.dataVisualization.aggregatedChartDataLoading
    );
    const dateRange = useSelector(
        (state: any) => state.nocilDashboard.filterValues
    );
    const dataTableList = useSelector(
        (state: any) => state?.dataVisualization?.dataTableList?.data
    );
    const loading = useSelector(
        (state: any) => state?.dataVisualization?.dataTableList?.loader
    );
    const kpiData = useSelector(
        (state: any) => state?.dataVisualization?.kpiList
    );
    const kpiTrendsData = useSelector(
        (state: any) =>
            state?.dataVisualization?.kpiTrendsData?.kpiReadingResponses
    );

    const timezone = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone;
    const applyHandler = (): any => {
        if (count === 1) {
            if (selectedTagRows?.length) {
                let tagUUIDString: string = EMPTY?.string;
                selectedTagRows?.map((selectedTagValues: any) => {
                    if (tagUUIDString === EMPTY?.string) {
                        tagUUIDString = String(selectedTagValues?.uuid);
                    } else {
                        tagUUIDString += ',' + String(selectedTagValues?.uuid);
                    }
                    setTagUUID(tagUUIDString);
                });
                dispatch(
                    getAggregatedChartData({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        tagUuidList: tagUUIDString,
                        tagOriginId: tagOriginId?.PLC,
                        timezone,
                        pageSize: pageSize,
                        pageNumber: page,
                        frequency: frequencyState ?? 0,
                    })
                );
            }
            if (selectedKpiRows?.length) {
                let kpiIdString: string = EMPTY?.string;
                selectedKpiRows?.map((selectedKpiValues: any) => {
                    if (kpiIdString === EMPTY?.string) {
                        kpiIdString = String(selectedKpiValues?.ruleId);
                    } else {
                        kpiIdString += ',' + String(selectedKpiValues?.ruleId);
                    }
                    setKpiId(kpiIdString);
                });
                const transformedArray = selectedKpiRows.map((kpi: any) => ({
                    assetId: kpi.assetId,
                    kpiId: kpi.id,
                }));
                const jsonString = JSON.stringify(transformedArray);
                const bytes = utf8.encode(jsonString);
                let base64EncodedStr = base64.encode(bytes);
                // Replace function is used to replace a string into empty string (=+$ is a regular expression that matches one or more = characters at the end of the string)
                base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
                const urlEncodedStr = encodeURIComponent(base64EncodedStr);
                setKpiUUID(urlEncodedStr);
                dispatch(
                    getKpiTrends({
                        startTime: new Date(dateRange?.startTime)?.getTime(),
                        endTime: new Date(dateRange?.endTime)?.getTime(),
                        list: urlEncodedStr,
                        frequency: frequencyState ?? 0,
                    })
                );
            } else {
                setChartSeriesData([]);
                setYAxisSeriesData([]);
                dispatch(clearDataTableList());
            }
        } else {
            let tagUUIDString: string = EMPTY?.string;
            let urlEncodedStr: string = EMPTY?.string;
            if (selectedTagRows?.length) {
                selectedTagRows?.map((selectedTagValues: any) => {
                    if (tagUUIDString === EMPTY?.string) {
                        tagUUIDString = String(selectedTagValues?.uuid);
                    } else {
                        tagUUIDString += ',' + String(selectedTagValues?.uuid);
                    }
                    setTagUUID(tagUUIDString);
                });
            }
            if (selectedKpiRows?.length) {
                let kpiIdString: string = EMPTY?.string;
                selectedKpiRows?.map((selectedKpiValues: any) => {
                    if (kpiIdString === EMPTY?.string) {
                        kpiIdString = String(selectedKpiValues?.id);
                    } else {
                        kpiIdString += ',' + String(selectedKpiValues?.id);
                    }
                    setKpiId(kpiIdString);
                });
                const transformedArray = selectedKpiRows.map((kpi: any) => ({
                    assetId: kpi.assetId,
                    kpiId: kpi.id,
                }));
                const jsonString = JSON.stringify(transformedArray);
                const bytes = utf8.encode(jsonString);
                let base64EncodedStr = base64.encode(bytes);
                // Replace function is used to replace a string into empty string (=+$ is a regular expression that matches one or more = characters at the end of the string)
                base64EncodedStr = base64EncodedStr.replace(/=+$/, '');
                urlEncodedStr = encodeURIComponent(base64EncodedStr);
                setKpiUUID(urlEncodedStr);
            } else {
                dispatch(clearDataTableList());
            }
            dispatch(
                getDataTableList({
                    requestStartTime: new Date(dateRange?.startTime)?.getTime(),
                    requestEndTime: new Date(dateRange?.endTime)?.getTime(),
                    tagUuidList: tagUUIDString ?? '',
                    kpiPayload: urlEncodedStr ?? '',
                    tagOriginId: tagOriginId?.PLC,
                    timezone,
                    pageSize: pageSize,
                    pageNumber: page,
                    sortOrder: sortingOrder.descending,
                    frequency: frequencyState ?? 0,
                })
            );
        }
    };

    useEffect(() => {
        applyHandler();
    }, [
        selectedTagRows,
        pageSize,
        page,
        selectedKpiRows,
        frequencyState,
        count,
    ]);

    const [chartSeriesData, setChartSeriesData] = useState<any>([]);
    const [yAxisSeriesData, setYAxisSeriesData] = useState<any>([]);

    const [chartKpiSeriesData, setChartKpiSeriesData] = useState<any>([]);
    const [yAxisKpiSeriesData, setYAxisKpiSeriesData] = useState<any>([]);
    const UnitsMapper: any = {};
    const unitMapperArray: any = [];
    const [updatedSelectedTagRows, setUpdatedSelectedTagRows] = useState<any>(
        []
    );
    const getYAxisCount = (type: any): any => {
        UnitsMapper[type] = unitMapperArray?.length;
        unitMapperArray?.push(type);

        return unitMapperArray?.length - 1;
    };

    useEffect(() => {
        const multiLineGraphDataSeries: any = [];
        const yAxis: any = [];
        aggregatedChartData?.streams?.map((tagValue: any, index: number) => {
            const dataArray: any = [];
            const updatedRow = updatedSelectedTagRows.find(
                (row: any) => row.uuid === tagValue.uuid
            );

            if (updatedRow) {
                tagValue.tagName = updatedRow?.tagName; // Add tagName to tagValue
            }
            if (!UnitsMapper[tagValue.unit])
                if (UnitsMapper[tagValue.unit] !== 0)
                    if (tagValue?.readings?.length) {
                        yAxis.push({
                            title: {
                                text: tagValue.unit,
                            },
                            opposite: !!unitMapperArray?.length,
                            showEmpty: false,
                        });
                    }
            const sortedTimestamps = tagValue?.readings?.sort(
                (a: any, b: any) => a?.timestamp - b?.timestamp
            );
            if (sortedTimestamps?.length) {
                sortedTimestamps?.map((tagTimestampData: any) => {
                    dataArray?.push([
                        tagTimestampData.timestamp,
                        tagTimestampData.value,
                    ]);
                });
            }
            if (sortedTimestamps?.length) {
                multiLineGraphDataSeries?.push({
                    name: tagValue?.tagName,
                    data: dataArray,
                    yAxis:
                        UnitsMapper[tagValue.unit] ||
                        UnitsMapper[tagValue.unit] === 0
                            ? UnitsMapper[tagValue.unit]
                            : getYAxisCount(tagValue.unit),
                });
            }
        });
        setChartSeriesData(multiLineGraphDataSeries);
        setYAxisSeriesData(yAxis);
    }, [aggregatedChartData, updatedSelectedTagRows]);
    const [updatedKpiData, setUpdatedKpiData] = useState<any>([]);
    useEffect(() => {
        if (selectedKpiRows && kpiTrendsData) {
            const updatedData = kpiTrendsData.map((data: any) => {
                const matchingKpi = selectedKpiRows.find(
                    (kpi: any) => kpi.ruleId === data.kpiId
                );
                if (matchingKpi) {
                    return {
                        ...data,
                        dataType: { unit: matchingKpi.unit.abbreviation },
                        tagName: matchingKpi?.name,
                        tagDesc: matchingKpi?.description,
                        type: 'kpi',
                        key: data?.kpiId,
                        parentNode: { name: matchingKpi?.assetName },
                    };
                }

                return data;
            });
            setUpdatedKpiData(updatedData);
        }
    }, [selectedKpiRows, kpiTrendsData]);
    useEffect(() => {
        const multiLineGraphDataSeries: any = [];
        const yAxis: any = [];

        updatedKpiData?.forEach((kpiData: any, index: any) => {
            const dataArray: any = [];
            const updatedRow = selectedKpiRows.find(
                (row: any) => row.ruleId === kpiData.ruleId
            );

            if (updatedRow) {
                kpiData.name = updatedRow?.name;
            }

            if (kpiData?.readings?.length > 0) {
                yAxis.push({
                    title: {
                        text: kpiData?.dataType?.unit,
                    },
                    opposite: !!unitMapperArray?.length,
                    showEmpty: false,
                });
            }

            kpiData?.readings?.forEach((reading: any) => {
                dataArray?.push([reading?.time, reading?.value]);
            });

            if (dataArray?.length > 0) {
                multiLineGraphDataSeries?.push({
                    name: kpiData?.tagName,
                    data: dataArray,
                    yAxis: index,
                });
            }
        });
        setChartKpiSeriesData(multiLineGraphDataSeries);
        setYAxisKpiSeriesData(yAxis);
    }, [updatedKpiData, selectedKpiRows]);
    const [chartData, setChartData] = useState<any>([]);
    const [yAxisData, setYAxisData] = useState<any>([]);
    useEffect(() => {
        if (chartKpiSeriesData && chartSeriesData) {
            setChartData([...chartKpiSeriesData, ...chartSeriesData]);
        }
        if (yAxisKpiSeriesData && yAxisSeriesData) {
            setYAxisData([...yAxisKpiSeriesData, ...yAxisSeriesData]);
        }
    }, [
        chartKpiSeriesData,
        chartSeriesData,
        yAxisKpiSeriesData,
        yAxisSeriesData,
    ]);
    const modelListOptions = (): any => {
        const result: any = [];
        modelList
            ?.filter((modelObject: any) => modelObject?.isSelectable)
            ?.map((modelObject: any) => {
                result.push({
                    label: modelObject?.name,
                    value: modelObject?.rootNode?.id,
                });
            });
        return result;
    };

    useEffect(() => {
        selectedModel && dispatch(getNodeList(selectedModel));
    }, [selectedModel]);

    const modelSelectHandler = (value: any): any => {
        setSelectedModel(value);
    };
    // Will Use later
    // const optionHandler = (value: any): void => {
    //     setSelectedOption(value);
    // };
    const chartRef: any = useRef(null);

    useEffect(() => {
        aggregatedChartDataLoading
            ? chartRef.current?.chart?.showLoading()
            : chartRef.current?.chart?.hideLoading();
    }, [aggregatedChartDataLoading]);

    useEffect(() => {
        setSelectedTagRows([]);
        setSelectedRowsKeys([]);
        setSelectedDevice(null);
        setSelectedModel(null);
        dispatch(clearDataTableList());
        dispatch(getFrequency());
    }, []);
    useEffect(() => {
        setSearch(null);
        setSearchKpi(null);
        setSelectedDevice(null);
        setSelectedKpiRows([]);
        setSelectedTagRows([]);
    }, [selectedModel]);

    useEffect(() => {
        setSearch(null);
        setSearchKpi(null);
    }, [selectedDevice]);
    const getCSVData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.csv, // 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getPDFData = (): any => {
        dispatch(
            getAssetCSVData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getCSVAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.csv, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const getPDFAggData = (): any => {
        dispatch(
            getExportAggregrateCsvData({
                startTime: dayjs(startDateRedux).valueOf(),
                endTime: dayjs(endDateRedux).valueOf(),
                tagUuidList: uuids,
                kpiPayload: kpiUUID,
                templateType: TemplateTypeAsset.pdf, // TemplateType = 2 is for csv, 3 for pdf,
                tagOriginId: TagOriginId.PLC, // this denotes tag category wherr PLC = 0, USER = 1, KPI = 2
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                frequency: frequencyState ?? 0,
            })
        );
    };
    const menu = (
        <Menu onClick={({ key }): any => {}}>
            <Menu.Item key="csv" onClick={getCSVData}>
                {t('dataVisualization.exportCSV')}
            </Menu.Item>
            <Menu.Item key="pdf" onClick={getPDFData}>
                {t('dataVisualization.exportPDF')}
            </Menu.Item>
            <Menu.Item key="csvAgg" onClick={getCSVAggData}>
                {t('dataVisualization.exportAggregatedCSV')}
            </Menu.Item>
            <Menu.Item key="pdfAgg" onClick={getPDFAggData}>
                {t('dataVisualization.exportAggregatedPDF')}
            </Menu.Item>
        </Menu>
    );
    // Will Use later
    // const options = [
    //     {
    //         id: '1',
    //         value: 'kpis',
    //         label: 'KPIs',
    //     },
    //     {
    //         id: '2',
    //         value: 'tags',
    //         label: 'Tags',
    //     },
    // ];
    // const { Option } = Select;
    const [search, setSearch] = useState<string | null>(null);
    const [searchKpi, setSearchKpi] = useState<string | null>(null);
    const [tagSearchTimeout, setTagSearchTimeout] = useState<any>(undefined);
    const [kpiSearchTimeout, setKpiSearchTimeout] = useState<any>(undefined);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [kpiSortOrder, setkpiSortOrder] = useState<number | null>(null);
    const [kpiSortColumn, setKpiSortColumn] = useState<number | null>(null);
    const [maxRange, setMaxRange] = useState(false);
    useEffect(() => {
        if (
            selectedTagRows.length + selectedKpiRows.length >=
            maxRowSelectionCount
        ) {
            setMaxRange(true);
        } else {
            setMaxRange(false);
        }
    }, [selectedTagRows, selectedKpiRows]);
    useEffect(() => {
        selectedModel &&
            selectedDevice &&
            dispatch(
                getStreamTagList({
                    parentNodeId: selectedDevice,
                    search: search ?? EMPTY.string,
                })
            );
        selectedModel &&
            selectedDevice &&
            dispatch(
                getKpiData({
                    assetId: selectedDevice,
                    search: searchKpi ?? EMPTY.string,
                })
            );
    }, [selectedDevice, selectedModel]);
    // Will Use later
    // }, [selectedOption, selectedDevice, selectedModel]);
    useEffect(() => {
        selectedDevice &&
            dispatch(
                getStreamTagList({
                    parentNodeId: selectedDevice,
                    search: search ?? EMPTY.string,
                    sortOrder: sortOrder,
                    sortColumn: sortColumn,
                })
            );
    }, [sortColumn, sortOrder, selectedDevice]);
    useEffect(() => {
        selectedDevice &&
            dispatch(
                getKpiData({
                    assetId: selectedDevice,
                    search: searchKpi ?? EMPTY.string,
                    sortOrder: kpiSortOrder,
                    sortColumn: kpiSortColumn,
                })
            );
    }, [kpiSortColumn, kpiSortOrder, selectedDevice]);

    useEffect(() => {
        if (selectedTagRows && aggregatedChartData) {
            const updatedRows = selectedTagRows?.map((row: any) => {
                const matchingStream = aggregatedChartData?.streams?.find(
                    (stream: any) => stream?.uuid === row?.uuid
                );
                if (matchingStream) {
                    return {
                        ...row,
                        aggregatedValue: matchingStream?.aggregatedValue,
                    };
                }
                return row;
            });
            setUpdatedSelectedTagRows(updatedRows);
        }
    }, [selectedTagRows, aggregatedChartData]);
    const [transformedKpiRows, setTransformedKpiRows] = useState([]);
    useEffect(() => {
        if (selectedKpiRows && selectedKpiRows.length > 0) {
            const newArray = selectedKpiRows.map((kpi: any) => ({
                tagName: kpi.name,
                parentNode: { name: kpi.assetName },
                dataType: { unit: kpi.unit?.abbreviation },
                aggregatedValue: null,
                type: 'kpi',
                key: kpi?.ruleId,
            }));
            setTransformedKpiRows(newArray);
        }
    }, [selectedKpiRows]);
    const [info, setInfo] = useState<any>([]);

    useEffect(() => {
        if (updatedKpiData && updatedSelectedTagRows && transformedKpiRows) {
            const mergedArray = updatedKpiData.concat(
                ...updatedSelectedTagRows,
                ...transformedKpiRows
            );
            setInfo(mergedArray);
        }
    }, [updatedKpiData, updatedSelectedTagRows, transformedKpiRows]);

    useEffect(() => {
        if (!selectedTagRows?.length) {
            dispatch({ type: EMPTY_TAGS_PLOTTING });
        }
    }, [selectedTagRows]);

    useEffect(() => {
        if (!selectedKpiRows?.length) {
            setUpdatedKpiData([]);
            setTransformedKpiRows([]);

            dispatch({ type: EMPTY_KPIS_PLOTTING });
        }
    }, [selectedKpiRows]);
    const [showExpandedGraph, setShowExpandedGraph] = useState(false);
    useEffect(() => {
        const filteredTransformedKpiRows = transformedKpiRows?.filter(
            (row: any) =>
                !updatedKpiData.some(
                    (kpiData: any) => kpiData.kpiId === row.key
                )
        );
        setTransformedKpiRows(filteredTransformedKpiRows);
    }, [updatedKpiData]);
    const [modifiedTree, setModifiedTree] = useState({});
    useEffect(() => {
        const newTree = mapTree(nodeList.node);
        const filteredTree = filterVisibleNodes(newTree);
        setModifiedTree(filteredTree);
    }, [nodeList]);
    const filterVisibleNodes = (tree: any): any => {
        if (!tree.visible) {
            return null;
        }
        const newNode = {
            title: tree.title,
            key: tree.key,
            value: tree.value,
            list: tree.list,
            disabled: tree.disabled,
            visible: tree.visible,
            children: Array?.isArray(tree?.children)
                ? tree?.children
                      ?.map(filterVisibleNodes)
                      ?.filter((child: any) => child !== null)
                : [],
        };

        return newNode;
    };
    return (
        <div>
            <CustomHeader
                heading={t('dataVisualization.heading')}
                currentTimePicker={true}
                customDateTimePicker={true}
                applyClickHandler={applyHandler}
            />
            <div
                className={`data-visualization-container ${
                    count === 1 ? 'trending-container' : 'other-container'
                }`}
            >
                <div className={showExpandedGraph ? 'leftCollaspe' : 'left'}>
                    <div className="device-tag-selector-header child">
                        <div className="deviceTagsHeading">
                            {t('dataVisualization.tagsKpis')}
                        </div>
                    </div>
                    <Select
                        className="child"
                        placeholder={t(
                            'dataVisualization.selectModelPlaceholder'
                        )}
                        style={{ width: '100%' }}
                        size="small"
                        options={modelListOptions()}
                        onSelect={modelSelectHandler}
                        value={selectedModel}
                    />
                    <TreeSelect
                        className="child"
                        placeholder={t(
                            'dataVisualization.selectNodePlaceholder'
                        )}
                        value={selectedDevice || undefined}
                        style={{ width: '100%' }}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        treeData={modifiedTree ? [modifiedTree] : []} 
                        size="small"
                        onSelect={selectHandler}
                    />
                    <>
                        <Input
                            allowClear
                            suffix={<SearchOutlined />}
                            className="search-tag"
                            placeholder={t(
                                'dataVisualization.searchKpisPlaceholder'
                            )}
                            size="small"
                            value={searchKpi ?? EMPTY?.string}
                            onChange={(e) => {
                                setSearchKpi(e.target.value);
                                if (kpiSearchTimeout) {
                                    clearTimeout(kpiSearchTimeout);
                                }
                                setKpiSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getKpiData({
                                                assetId: selectedDevice,
                                                search: e.target.value,
                                            })
                                        );
                                    }, 1000)
                                );
                            }}
                        />
                        <div className="table">
                            <KpiTable
                                className="child"
                                data={kpiData}
                                selectedDevice={selectedDevice}
                                searchKpi={searchKpi}
                                setSortOrder={setkpiSortOrder}
                                setSortColumn={setKpiSortColumn}
                                setSelectedKpiRows={setSelectedKpiRows}
                                selectedKpiRows={selectedKpiRows}
                                maxRange={maxRange}
                            />
                        </div>
                    </>
                    <>
                        <Input
                            allowClear
                            suffix={<SearchOutlined />}
                            className="search-tag"
                            placeholder={t(
                                'dataVisualization.searchTagsPlaceholder'
                            )}
                            size="small"
                            value={search ?? EMPTY?.string}
                            onChange={(e) => {
                                setSearch(e.target.value);
                                if (tagSearchTimeout) {
                                    clearTimeout(tagSearchTimeout);
                                }
                                setTagSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getStreamTagList({
                                                sortOrder: sortOrder,
                                                sortColumn: sortColumn,
                                                parentNodeId: selectedDevice,
                                                search: e.target.value,
                                            })
                                        );
                                    }, 1000)
                                );
                            }}
                        />
                        <div className="table">
                            <TagTable
                                data={streamTagsList}
                                selectedDevice={selectedDevice}
                                setSelectedTagRows={setSelectedTagRows}
                                selectedTagRows={selectedTagRows}
                                search={search}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                                maxRange={maxRange}
                            />
                        </div>
                    </>
                    {/* )} */}
                </div>
                <div className={showExpandedGraph ? 'rightFull' : 'right'}>
                    <div className="data-visualization-container__header">
                        <div className="data-visualization-container__header__title">
                            <Radio.Group
                                value={count}
                                onChange={(e: any) => {
                                    setCount(e.target.value);
                                }}
                            >
                                <Radio.Button value={1}>
                                    {t('dataVisualization.trends')}
                                </Radio.Button>
                                <Radio.Button value={2}>
                                    {t('dataVisualization.table')}
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                        <div className="expandExportOption">
                            <Select
                                className="expandFrequency"
                                showSearch
                                allowClear
                                placeholder="Select"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label as string)
                                        .toLowerCase()
                                        .includes(input.toLowerCase())
                                }
                                options={frequencyList?.map(
                                    (item: {
                                        id: string;
                                        samplingTime: string;
                                    }) => ({
                                        value: item?.id,
                                        label: item?.samplingTime,
                                        key: item?.id,
                                    })
                                )}
                                value={frequencyState}
                                onChange={(e) => {
                                    if (e === undefined || e === null) {
                                        setFrequencyState(0);
                                    } else {
                                        setFrequencyState(e);
                                    }
                                }}
                                onClear={() => {
                                    setFrequencyState(0);
                                }}
                            />

                            <Dropdown
                                overlay={menu}
                                disabled={
                                    !selectedTagRows?.length &&
                                    !selectedKpiRows?.length
                                }
                                trigger={['click']}
                            >
                                <Button>
                                    <DownloadOutlined className="export-name" />
                                    <span className="export-name">
                                        {t('dataVisualization.export')}
                                    </span>
                                </Button>
                            </Dropdown>
                            {showExpandedGraph ? (
                                <div
                                    onClick={() => {
                                        setShowExpandedGraph(false);
                                    }}
                                    className="closeIcon"
                                >
                                    <CloseIcon />
                                </div>
                            ) : (
                                <Expand
                                    className={`${
                                        selectedTagRows?.length ||
                                        selectedKpiRows?.length
                                            ? 'expandIcon'
                                            : 'disableExpandBtn'
                                    }`}
                                    onClick={() => {
                                        (selectedTagRows?.length ||
                                            selectedKpiRows?.length) &&
                                            setShowExpandedGraph(true);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    {count === 1 ? (
                        <div>
                            <div className="data-visualization-container__multiAxisChart">
                                {selectedModel && selectedDevice ? (
                                    aggregatedChartDataLoading ? (
                                        <div className="view__loader">
                                            <Spin />
                                        </div>
                                    ) : (
                                        <MultiAxisTimeSeriesChart
                                            key={`${dateRange?.dateRange}-${frequencyState}`}
                                            chartSeriesData={
                                                chartData ||
                                                chartSeriesData ||
                                                chartKpiSeriesData
                                            }
                                            yAxisSeriesData={
                                                yAxisData ||
                                                yAxisSeriesData ||
                                                yAxisKpiSeriesData
                                            }
                                            chartRef={chartRef}
                                            dateRange={dateRange}
                                        />
                                    )
                                ) : (
                                    ''
                                )}
                            </div>

                            <Table
                                columns={columns}
                                dataSource={
                                    info ||
                                    updatedSelectedTagRows ||
                                    updatedKpiData ||
                                    transformedKpiRows
                                }
                                pagination={false}
                                size="small"
                                locale={{
                                    emptyText: t(
                                        'dataVisualization.noResultText'
                                    ),
                                }}
                                scroll={{ y: 'calc(100vh - 700px)' }}
                            />
                        </div>
                    ) : (
                        <DataTable
                            dataSource={dataTableList}
                            selectedTagRows={selectedTagRows}
                            selectedKpiRows={selectedKpiRows}
                            page={page}
                            setPage={handlePage}
                            setPageSize={handlePageSize}
                            pageSize={pageSize}
                            loading={loading}
                            startTime={new Date(
                                dateRange?.startTime
                            )?.getTime()}
                            endTime={new Date(dateRange?.endTime)?.getTime()}
                            tagUuidList={tagUUID ?? kpiID}
                            tagOriginId={tagOriginId?.PLC}
                            timezone={timezone}
                            frequencyState={frequencyState}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DataVisualization;

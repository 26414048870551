import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Cascader, Col, DatePicker, Input, Row, Select } from 'antd';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import {
    getQRCodeLogsList,
    getQRCodeLogsListWithoutPaginated,
    getQRConfigListWithoutPaginated,
} from 'redux/actions/QRCodeGenerationActions/qrCodeLogs';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import QRCodeLogsTable from './QRCodeLogsTable';
import { useParams } from 'react-router-dom';

const QRCodeLogs: React.FC = (): any => {
    const dispatch = useDispatch();
    const { RangePicker } = DatePicker;
    const { SHOW_CHILD } = Cascader;
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const [configName, setConfigName] = useState([]);
    const [advanceFilter, setAdvanceFilter] = useState(false);
    const [type, setType] = useState<any>([]);
    const [qrCodeText, setqrCodeText] = useState<any>([]);
    const [reset, setReset] = useState(false);
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [attributeSearchTimeout, setAttributeSearchTimeout] =
    useState<any>(undefined);
    const [searchState, setSearchState] = useState('');

    useEffect(() => {
        dispatch(getQRCodeLogsListWithoutPaginated({}));
        dispatch(getQRConfigListWithoutPaginated({}));
    }, []);

    const { currentTab } = useParams();

    useEffect(() => {
        if (
            currentTab === "logs"
        ) {
            dispatch(
                getQRCodeLogsList({
                    page: PAGE,
                    limit: PAGE_SIZE,
                })
            );
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
            setType(null);
            setqrCodeText([]);
            setConfigName([]);
            setReset(!reset);
            setAdvanceFilter(false);
            setStartTime(null);
            setEndTime(null);
            setSearchState('');
        }
    }, [currentTab]);

    const qrCodeLogsListWithoutPagination = useSelector(
        (state: any) =>
            state.qrCodeGeneration?.qrCodeLogs?.qrCodeLogsListWithoutPagination
    );

    const qrCodeConfigListWithoutPagination = useSelector(
        (state: any) =>
            state.qrCodeGeneration?.qrCodeLogs
                ?.qrCodeConfigListWithoutPagination
    );

    useEffect(() => {
        advanceFilter
            ? dispatch(
                  getQRCodeLogsList({
                      page: page,
                      limit: pageSize,
                      sortOrder: sortOrder,
                      sort: sortColumn,
                      "metadataConfig.name": configName?.toString() || null,
                      type: type?.toString() || null,
                      qrCodeContent: qrCodeText?.toString() || null,
                      "createdAt.gte": startTime || null,
                      "createdAt.lte": endTime || null,
                  })
              )
            : dispatch(
                  getQRCodeLogsList({
                      page: page,
                      limit: pageSize,
                      sortOrder: sortOrder,
                      sort: sortColumn,
                  })
              );
    }, [pageSize, page, sortOrder, sortColumn]);

    const filter = (inputValue: any, path: any): any =>
        path?.some((option: any): any =>
            (option?.label as string)
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase())
    );

    const onOk = (value: any): void => {
        value[0] && setStartTime(value[0]?.toISOString()); // Note- used 0th index to get the start time in Rangepicker
        value[1] && setEndTime(value[1]?.toISOString()); // Note- used 1st index to get the end time in Rangepicker
    };

    const onApply = (): void => {
        dispatch(
            getQRCodeLogsList({
                page: PAGE,
                limit: PAGE_SIZE,
                "metadataConfig.name": configName?.toString() || null,
                type: type?.toString() || null,
                qrCodeContent: qrCodeText?.toString() || null,
                "createdAt.gte": startTime || null,
                "createdAt.lte": endTime || null,
            })
        );
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
        setSortOrder(null);
        setSortColumn(null);
    };


    const resetFilter = (): void => {
        if (
            type ||
            configName?.length ||
            qrCodeText?.length ||
            startTime ||
            endTime
        ) {
            dispatch(
                getQRCodeLogsList({
                    page: PAGE,
                    limit: PAGE_SIZE,
                })
            );
        }
        setqrCodeText([]);
        setConfigName([]);
        setType(null);
        setReset(!reset);
        setPage(PAGE);
        setPageSize(pageSize);
        setSortOrder(null);
        setSortColumn(null);
        setStartTime(null); 
        setEndTime(null); // Reset the date
    };


    return (
        <>
            <div className="attributeWrapper qrCodeLogsWrapper">
                <Card bordered={false}>
                    <>
                        <>
                                {advanceFilter ? (
                                    <>
                                        <Row className="attributeWrapper__filterHeader">
                                            <Col span={5}>
                                                <Cascader
                                                    key={`${reset}`}
                                                    multiple
                                                    maxTagCount="responsive"
                                                    options={qrCodeConfigListWithoutPagination?.data?.metadataConfigs?.map(
                                                        (item: any) => {
                                                            return {
                                                                value: item?.name,
                                                                label: item.name,
                                                            };
                                                        }
                                                    )}
                                                    onChange={(value: any) => {
                                                        setConfigName(
                                                            value?.flat()
                                                        );
                                                    }}
                                                    placeholder={'Config Name'}
                                                    showSearch={{ filter }}
                                                    showCheckedStrategy={SHOW_CHILD}
                                                    onSearch={(value) => {}}
                                                    value={configName?.map(
                                                        (item: any) => {
                                                            return [item];
                                                        }
                                                    )}
                                                />
                                            </Col>
                                            <Col span={5}>
                                                <Select
                                                    placeholder="Type"
                                                    options={[
                                                        {
                                                            value: "Manual",
                                                            label: "Manual"
                                                        },
                                                        {
                                                            value: "System Generated",
                                                            label: "System Generated"
                                                        }
                                                    ]}
                                                    value={type}
                                                    onChange={(value: any) => {
                                                        setType(value);
                                                    }}
                                                />
                                            </Col>
                                            <Col span={5}>
                                                <Cascader
                                                    multiple
                                                    maxTagCount="responsive"
                                                    placeholder={'QR Code text'}
                                                    showCheckedStrategy={SHOW_CHILD}
                                                    showSearch={{ filter }}
                                                    options={qrCodeLogsListWithoutPagination?.data?.map(
                                                        (item: any) => {
                                                            return {
                                                                value: item?.qrCodeContent,
                                                                label: item.qrCodeContent,
                                                            };
                                                        }
                                                    )}
                                                    value={qrCodeText?.map(
                                                        (item: any) => {
                                                            return [item];
                                                        }
                                                    )}
                                                    onChange={(value: any) => {
                                                        setqrCodeText(
                                                            value?.flat()
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                className="mappingWrapper__button"
                                                span={8}
                                            >
                                                <CloseOutlined
                                                    onClick={() => {
                                                        setAdvanceFilter(false);
                                                        setReset(!reset);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="attributeWrapper__filterAdvHeader">
                                            <Col span={10}>
                                                <RangePicker
                                                    key={`${reset}`}
                                                    allowClear={false}
                                                    showTime
                                                    onOk={onOk}
                                                    placeholder={['Start Timestamp', 'End Timestamp']}
                                                />  
                                            </Col>
                                            <Col span={2}>
                                                <CustomButton
                                                    type="Apply"
                                                    disabled={false}
                                                    handleClick={onApply}
                                                />
                                            </Col>
                                            <Col span={1}>
                                                <span
                                                    className="customHeader__child-resetButton"
                                                    onClick={resetFilter}
                                                >
                                                    <ResetIcon />
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                ) : (
                                    <>
                                        <Row className="attributeWrapper__filterHeader">
                                            <Col span={5}>
                                                <Input
                                                    allowClear
                                                    className="qrCodeConfigWrapper__search"
                                                    placeholder={"Search Name"}
                                                    prefix={<SearchOutlined />}
                                                    value={searchState}
                                                    onChange={(e) => {
                                                        setSearchState(e.target.value);
                                                        if (attributeSearchTimeout) {
                                                            clearTimeout(attributeSearchTimeout);
                                                        }
                                                        // Debouncing for search implemented
                                                        setAttributeSearchTimeout(
                                                            setTimeout(() => {
                                                                dispatch(
                                                                    getQRCodeLogsList({
                                                                        page: PAGE,
                                                                        limit: PAGE_SIZE,
                                                                        uniqueData: e?.target?.value + "*"
                                                                    })
                                                                );
                                                            }, 1000)
                                                        );
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                className="mappingWrapper__textButton"
                                                span={5}
                                            >
                                                <span
                                                    onClick={() => {
                                                        setAdvanceFilter(true);
                                                        setSearchState('');
                                                    }}
                                                >
                                                    Advance Filter
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        <QRCodeLogsTable
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            setSortOrder={setSortOrder}
                            setSortColumn={setSortColumn}
                        />
                    </>
                </Card>
            </div>
        </>
    );
};

export default QRCodeLogs;

import { all, call, put, takeLatest } from "redux-saga/effects";
import { getDaysListServices } from "redux/services/BusinessIntegratorServices/operationServices";
import * as types from "redux/types/commonTypes";

export function* getDaysListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const daysList = yield call(getDaysListServices);
    yield put({
      type: types.GET_WEEKDAYS_LIST_SUCCESS,
      response: daysList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_WEEKDAYS_LIST_FAILURE,
    });
  }
}

export function* commonSaga(): any {
  yield all([takeLatest(types.GET_WEEKDAYS_LIST, getDaysListSaga)]);
}

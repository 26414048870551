export const SET_LDAP_CONFIGURATOR_STATE = "SET_LDAP_CONFIGURATOR_STATE";

export const GET_LDAP_SERVICE_LIST = "GET_LDAP_SERVICE_LIST";
export const GET_LDAP_SERVICE_LIST_SUCCESS = "GET_LDAP_SERVICE_LIST_SUCCESS";
export const GET_LDAP_SERVICE_LIST_FAILURE = "GET_LDAP_SERVICE_LIST_FAILURE";

export const CREATE_LDAP_CONFIGURATOR_SETTINGS =
  "CREATE_LDAP_CONFIGURATOR_SETTINGS";
export const CREATE_LDAP_CONFIGURATOR_SETTINGS_SUCCESS =
  "CREATE_LDAP_CONFIGURATOR_SETTINGS_SUCCESS";
export const CREATE_LDAP_CONFIGURATOR_SETTINGS_FAILURE =
  "CREATE_LDAP_CONFIGURATOR_SETTINGS_FAILURE";

export const GET_LDAP_USERS_LIST = "GET_LDAP_USERS_LIST";
export const GET_LDAP_USERS_LIST_SUCCESS = "GET_LDAP_USERS_LIST_SUCCESS";
export const GET_LDAP_USERS_LIST_FAILURE = "GET_LDAP_USERS_LIST_FAILURE";

export const GET_LDAP_TYPE_OF_IDENTITY_LIST = "GET_LDAP_TYPE_OF_IDENTITY_LIST";
export const GET_LDAP_TYPE_OF_IDENTITY_LIST_SUCCESS =
  "GET_LDAP_TYPE_OF_IDENTITY_LIST_SUCCESS";
export const GET_LDAP_TYPE_OF_IDENTITY_LIST_FAILURE =
  "GET_LDAP_TYPE_OF_IDENTITY_LIST_FAILURE";

export const TEST_LDAP_CONFIGURATOR = "TEST_LDAP_CONFIGURATOR";
export const TEST_LDAP_CONFIGURATOR_SUCCESS = "TEST_LDAP_CONFIGURATOR_SUCCESS";
export const TEST_LDAP_CONFIGURATOR_FAILURE = "TEST_LDAP_CONFIGURATOR_FAILURE";

export const GET_LDAP_CONFIGURATOR_DETAILS_BY_ID =
  "GET_LDAP_CONFIGURATOR_DETAILS_BY_ID";
export const GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_SUCCESS =
  "GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_SUCCESS";
export const GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_FAILURE =
  "GET_LDAP_CONFIGURATOR_DETAILS_BY_ID_FAILURE";

export const GET_LDAP_COUNT = "GET_LDAP_COUNT";
export const GET_LDAP_COUNT_SUCCESS = "GET_LDAP_COUNT_SUCCESS";
export const GET_LDAP_COUNT_FAILURE = "GET_LDAP_COUNT_FAILURE";

export const DELETE_LDAP_SERVICE = "DELETE_LDAP_SERVICE";
export const DELETE_LDAP_SERVICE_SUCCESS = "DELETE_LDAP_SERVICE_SUCCESS";
export const DELETE_LDAP_SERVICE_FAILURE = "DELETE_LDAP_SERVICE_FAILURE";

export const CLEAR_LDAP_SERVICE_STATUS = "CLEAR_LDAP_SERVICE_STATUS";

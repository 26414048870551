import * as types from "redux/types/commonTypes";
import commonInitialState from "../commonInitialState";

export default function commonReducer(
  state = commonInitialState.commonState,
  action: any
): any {
  switch (action.type) {
    case types.GET_WEEKDAYS_LIST_SUCCESS:
      state = {
        ...state,
        daysList: action.response.data,
      };
      return state;
    default:
      return state;
  }
}
